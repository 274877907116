import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import AuthLayout from "../../Layout/AuthLayout/AuthLayout";
import {
  FormContainer,
  LogoImage,
  ForgotPasswordLink,
  SignupText,
  HeaderTypography,
  CustomInput,
  FormFieldsWrapper,
  AccountText,
  PasswordInputWrapper,
  LogoImgContainer,
} from "./LoginStyles";
import {
  AuthButton,
  ErrorMsg,
  PasswordToggleIcon,
} from "../../../Common/commonStyles";
import { ignithoLogo } from "../../../Constants/Constant";
import { API } from "../../../global";
import { useAuth } from "../AuthContext/AuthContext";
import { useToast } from "../../../utils/ToastContext";

const schema = yup.object({
  email: yup
    .string()
    .email("Please enter a valid email.")
    .required("Email is a required field.")
    .matches(/^[^\s#[\]()]*\.com$/, "Please enter a valid email."),
  password: yup.string().required("Password is a required field."),
});

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = (data) => {
    setLoading(true);
    const Obj = {
      email: data.email.toLowerCase(),
      password: data.password,
    };
    // API Integration

    fetch(`${API}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        if (response.success) {
          const userData = response.result;
          localStorage.setItem("token", userData.token);
          localStorage.setItem("sas_url", userData.sas_token);
          localStorage.setItem("userId", userData.user_id);
          localStorage.setItem("role", userData.user_role_key);
          localStorage.setItem("first_name", userData.firstName);
          localStorage.setItem("last_name", userData.lastName);
          login(userData.token);
          navigate("/ig-ai/onboard-ai-agent");
        } else {
          showToast("error", `${response.error.msg}`);
        }
      })
      .catch((err) => {
        setLoading(false);
        showToast("error", "Error occurred please try again");
      });
  };

  return (
    <AuthLayout>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <LogoImgContainer>
          <LogoImage src={ignithoLogo} alt="logo" />
        </LogoImgContainer>
        <HeaderTypography>Let's Get Started</HeaderTypography>
        <FormFieldsWrapper>
          <CustomInput
            placeholder="Enter Email Address"
            {...register("email")}
          />
          <ErrorMsg variant="p">{errors?.email?.message}</ErrorMsg>

          <PasswordInputWrapper>
            <CustomInput
              type={showPassword ? "text" : "password"}
              placeholder="Enter Password"
              {...register("password")}
              onCopy={(e) => e.preventDefault()}
              onPaste={(e) => e.preventDefault()}
              onCut={(e) => e.preventDefault()}
            />
            <PasswordToggleIcon onClick={togglePasswordVisibility}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </PasswordToggleIcon>
          </PasswordInputWrapper>
          <ErrorMsg>{errors?.password?.message}</ErrorMsg>

          <ForgotPasswordLink onClick={() => navigate("/forgot-password")}>
            Forgot Password?
          </ForgotPasswordLink>

          <AuthButton
            sx={{
              textTransform: "capitalize",
              bgcolor: "#6249EF",
              color: "white",

              "&:hover": {
                bgcolor: "#4E3BBC",
              },
            }}
            type="submit"
            disabled={!isValid}
            loading={loading}
            loadingIndicator="Loading…"
            variant="contained"
          >
            <span>Login</span>
          </AuthButton>
        </FormFieldsWrapper>
        <AccountText>
          Don't have an account?{" "}
          <SignupText>
            <Link to="/sign-up">Sign up</Link>
          </SignupText>
        </AccountText>
      </FormContainer>
    </AuthLayout>
  );
};

export default Login;
