/*
 * File: DestinationContainer.jsx
 */

import React, { useEffect, useState } from "react";
import { AccountBtn, CustomBox } from "../../ProfilePage/ProfilePageStyles";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { useForm } from "react-hook-form";
import { API } from "../../../global";
import { useToast } from "../../../utils/ToastContext";

const DestinationContainer = (props) => {
  const [destinationType, setDestinationType] = useState("");
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();

  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      destinationType: "DB",
    },
  });

  useEffect(() => {
    const destinationData = props.destinationData?.destination[0];
    setValue("hostName", destinationData?.host);
    setValue("portAddress", destinationData?.port);
    setValue("userName", destinationData?.user_name);
    setValue("password", destinationData?.password);
    setValue("dataBaseName", destinationData?.database_name);
    setValue(
      "destinationType",
      props?.destinationData?.destinationType
        ? props?.destinationData?.destinationType
        : "DB"
    );
    setDestinationType(
      props?.destinationData?.destinationType
        ? props?.destinationData?.destinationType
        : "DB"
    );

    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    const destinationData = {
      destinationType: data.destinationType,
      agentId: localStorage.getItem("agentId"),
      destinationDetails: {
        host: data.hostName,
        port: data.portAddress,
        user_name: data.userName,
        password: data.password,
        database_name: data.dataBaseName,
      },
    };
    fetch(`${API}/agent/add-destination-details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(destinationData),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        showToast("success", "Destination details updated successfully");
      });
  };

  return (
    <CustomBox>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <Button onClick={handleClickOpen}>click</Button> */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography sx={{ fontWeight: "bold" }}>Destination</Typography>
              <FormControl sx={{ mt: 1 }} fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Destination Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={destinationType}
                  label="Destination Type"
                  {...register("destinationType")}
                  defaultValue="DB"
                  sx={{ maxWidth: "100%" }}
                >
                  <MenuItem value={"file"} disabled>
                    File
                  </MenuItem>
                  <MenuItem value={"DB"} disabled>
                    DB
                  </MenuItem>
                  <MenuItem value={"API"} disabled>
                    API
                  </MenuItem>
                </Select>
              </FormControl>
              {watch("destinationType") === "DB" && (
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": { m: 2, width: "300px" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-basic"
                    label="Host Name"
                    variant="outlined"
                    size="small"
                    autocomplete="off"
                    {...register("hostName")}
                  />
                  <TextField
                    id="standard-basic"
                    label="Port Address"
                    variant="outlined"
                    size="small"
                    autocomplete="off"
                    {...register("portAddress")}
                  />
                  <TextField
                    id="standard-basic"
                    label="Database Name"
                    variant="outlined"
                    size="small"
                    autocomplete="off"
                    {...register("dataBaseName")}
                  />
                  <TextField
                    id="standard-basic"
                    label="User Name"
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    {...register("userName")}
                  />
                  <TextField
                    id="standard-basic"
                    label="Password"
                    variant="outlined"
                    size="small"
                    type="password"
                    autoComplete="off"
                    {...register("password")}
                  />
                </Box>
              )}
            </Box>
            <AccountBtn
              type="submit"
              loading={loading}
              loadingIndicator="Loading…"
              variant="outlined"
              sx={{
                border: "1px solid blue",
                color: "blue",
                "&:hover": {
                  bgcolor: "blue",
                },
                marginLeft: "15px",
              }}
            >
              Update
            </AccountBtn>
          </form>
        </Box>
      </Box>
    </CustomBox>
  );
};

export default DestinationContainer;
