import { styled, Box, Typography } from "@mui/material";

const RootBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: 'column',
  height: '100vh',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    overflow: 'hidden',
  },
}));

const LeftSideBox = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flex: 3,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: '100%',
  },
}));

const BackgroundImage = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  zIndex: -1,
});

const TextBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  textAlign: 'center',
  padding: '24px',
  height: '100%',
});

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  marginBottom: '1.5rem',
  textAlign: 'center',
  fontSize: '2.5rem',
  [theme.breakpoints.up('xl')]: {
    fontSize: '4rem'
  },
}));

const SubtitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: '400',
  fontSize: '1.1rem',
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.5rem'
  },
}));

const FormBox = styled(Box)(({ theme }) => ({
  flex: 1.7,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px',
}));

export {
  RootBox,
  LeftSideBox,
  TextBox,
  FormBox,
  TitleTypography,
  SubtitleTypography,
  BackgroundImage
};
