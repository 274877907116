import React from "react";
import {
  OutterContainer,
  StyledFlexBox,
  StyledTextArea,
  TextContainer,
  TitleContainer,
} from "../../Common/commonStyles";
import { IconButton } from "@mui/material";
import { expandIcon } from "../../Constants/Constant";

const Instruction = (props) => {
  const { createAgent } = props;

  return (
    <OutterContainer>
      <StyledFlexBox>
        <TitleContainer>Instruction</TitleContainer>
        <IconButton sx={{ padding: "0px" }}>
          <img src={expandIcon} alt="expandIcon" width="15px" />
        </IconButton>
      </StyledFlexBox>
      <TextContainer>
        Enter the role and responsibilities of this agent. You may provide
        examples of how it should handle various types of tasks.
      </TextContainer>
      <StyledTextArea
        rows={6}
        placeholder="Enter Instruction"
        {...createAgent("instruction")}
      />
    </OutterContainer>
  );
};

export default Instruction;
