import React, { useState } from "react";

import {
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControl,
  Link,
} from "@mui/material";

import { CustomTextField } from "../../components/DataQualityCheckAgentChat/DataQualityCheckStyles";
import {
  BasicFlexBox,
  ErrorMsg,
  OutterContainer,
  TextContainer,
  TitleContainer,
} from "../../Common/commonStyles";
import {
  CloseIcon,
  FileDetails,
  FileUploadContainer,
  UploadedFile
} from "../../components/CreateAIAgent/CreateAIAgentStyles";
import { StyledRadioBtn } from "./AddAiAgentStyle";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import FileInput from "../FileInput/FileInput";
import FileUploadedCard from "../FileUploadedCard/FileUploadedCard";
import { bin, deleteIcon, download } from "../../Constants/Constant";
import { useToast } from "../../utils/ToastContext";
import { greyCloseIcon } from "../../Constants/Constant";
import ConfirmModal from "../Modals/ConfirmModal/ConfirmModal";

const OptionAI = [
  { label: "GPT 3.5 (Open AI)" },
  { label: "GPT 3.5 16k (Open AI)" },
  { label: "GPT 4 (Open AI)" },
  { label: "GPT 4 32k 0613 (OpenAI)" },
  { label: "GPT 4 0613 (OpenAI)" },
];

const CustomLabel = ({ text }) => {
  return <span style={{ fontSize: "12px" }}>{text}</span>;
};

const LanguageModel = (props) => {
  const {
    setValue,
    createAgent,
    watch,
    errors,
    clearErrors,
    modelFileUploaded,
    setModelFileUploaded,
    selectedModel,
    setSelectedModel,
    setModelFileInput,
    agentCreate
  } = props;
  const { showToast } = useToast();

  const [fileDetails, setFileDetails] = useState({});
  const modelFileUrl = watch("modelFile");
  const modelType = watch("modelType");
  const [openDialog, setOpenDialog] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [confirmDialogContent, setConfirmDialogContent] = useState({
    title: "",
    message: ""
  });

  const allowedFileTypes = [
    "application/octet-stream"
  ];

  const handleSelectModel = (option) => {
    setSelectedModel(option);
    setValue("modelName", option.label, { shouldValidate: true });
  }

  const handleFileChange = async (file) => {
    if (file) {
      if (!allowedFileTypes.includes(file.type)) {
        showToast("error", "Invalid file type. Please upload a .bin file.");
      } else if (file.size > 104857600) {
        showToast("error", "File size exceeds 100 MB. Please upload a smaller file.");
      } else {
        const fileType = file.type;
        const fileSize = (file.size / 1024).toFixed(2);
        const fileName = file.name;

        setFileDetails({
          fileType: fileType,
          fileName: fileName,
          fileSize: fileSize
        });
        setModelFileUploaded(true);
        setModelFileInput(file);
        clearErrors("modelFile");
      }
    } else {
      console.error("No file selected");
      showToast("error", "No file selected");
    }
  };

  const resetFileUpload = () => {
    setModelFileUploaded(false);
    setModelFileInput(null);
    setFileDetails({});
  }

  const handleSelectModelInput = (e) => {
    clearErrors("modelType");
    if (e.target.value === "Select Model") {
      clearErrors("modelFile");
      clearErrors("customModelUrl");
      setModelFileUploaded(false);
      setValue("modelType", e.target.value, { shouldValidate: true });
      setValue("modelFile", "", { shouldValidate: true });
      setValue("customModelUrl", "", { shouldValidate: true });
    }
    if (e.target.value === "Upload Model") {
      clearErrors("modelName");
      clearErrors("customModelUrl");
      setSelectedModel("");
      setValue("modelType", e.target.value, { shouldValidate: true });
      setValue("modelName", "", { shouldValidate: true });
      setValue("customModelUrl", "", { shouldValidate: true });
    }
    if (e.target.value === "Custom Model URL") {
      clearErrors("modelName");
      clearErrors("modelFile");
      setSelectedModel("");
      setModelFileUploaded(false);
      setValue("modelType", e.target.value, { shouldValidate: true });
      setValue("modelName", "", { shouldValidate: true });
      setValue("modelFile", "", { shouldValidate: true });
    }
  }

  const handleRemoveCustomUrl = () => {
    setValue("customModelUrl", "", { shouldValidate: true });
  }

  const handleRemoveModelFile = () => {
    setModelFileUploaded(false);
    setValue("modelFile", "", { shouldValidate: true });
  }

  const handleOpenDialog = (type) => {
    const content = {
      title: "Are you sure you want to remove the file?",
      message: type === "removeFile"
        ? "This will delete the existing model file."
        : "This will remove the uploaded file."
    };
    setActionType(type);
    setConfirmDialogContent(content);
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleConfirmAction = () => {
    if (actionType === "resetFileUpload") {
      resetFileUpload();
    } else if (actionType === "removeFile") {
      handleRemoveModelFile();
    }
    handleCloseDialog();
  }

  return (
    <>
      <OutterContainer>
        <TitleContainer>Language Model</TitleContainer>
        <TextContainer>
          Each agent is powered by a Large Language Model (LLM) that manages
          reasoning and communication. By default, this is set to GPT-3.5 if no
          other model is specified.
        </TextContainer>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="options"
            name="radio-buttons-group"
            value={modelType}
            onChange={handleSelectModelInput}
          >
            <StyledRadioBtn
              value="Select Model"
              control={<Radio />}
              label={<CustomLabel text="Select Model" />}
              sx={{ paddingBottom: "5px" }}
            />
            <CustomDropdown
              options={OptionAI}
              value={selectedModel}
              onChange={handleSelectModel}
              placeholder="Select Model"
              width="300px"
              disabled={modelType !== "Select Model"}
            />

            <StyledRadioBtn
              value="Upload Model"
              control={<Radio />}
              label={<CustomLabel text="Upload Model" />}
            />
            {modelFileUploaded ? (
              <FileUploadContainer>
                <FileUploadedCard
                  fileUploaded={modelFileUploaded}
                  resetFileUpload={() => handleOpenDialog('resetFileUpload')}
                  fileDetails={fileDetails}
                  imgSrc={bin}
                />
              </FileUploadContainer>
            ) : modelFileUrl && !agentCreate ? (
              <FileDetails>
                <CloseIcon>
                  <TitleContainer
                  >
                    Uploaded File
                  </TitleContainer>
                  <img
                    src={greyCloseIcon}
                    alt="close"
                    onClick={() => handleOpenDialog('removeFile')}
                    style={{
                      cursor: "pointer"
                    }}
                  />
                </CloseIcon>
                <UploadedFile>
                  <BasicFlexBox
                    gap={2}
                  >
                    <img
                      src={bin}
                      alt="bin"
                      style={{
                        width: "30px"
                      }}
                    />
                    {modelFileUrl.split('/').pop().split('?')[0]}
                  </BasicFlexBox>
                  <Link href={modelFileUrl} download>
                    <img src={download} alt="download" />
                  </Link>
                </UploadedFile>
              </FileDetails>
            ) : (
              <FileInput
                handleFileChange={handleFileChange}
                maxFileSize="100MB"
                disabled={modelType !== "Upload Model"}
              />
            )}

            <StyledRadioBtn
              value="Custom Model URL"
              control={<Radio />}
              label={<CustomLabel text="Custom Model URL" />}
            />
            <CustomTextField
              fullWidth
              disabled={modelType !== "Custom Model URL"}
              variant="outlined"
              placeholder="Enter url"
              {...createAgent("customModelUrl")}
              sx={{
                "& .MuiOutlinedInput-input": {
                  fontSize: "12px",
                  "::placeholder": {
                    fontSize: "12px",
                  },
                },
                height: "30px",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleRemoveCustomUrl}
                      disabled={modelType !== "Custom Model URL"}
                    >
                      <img src={deleteIcon} alt="send" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </RadioGroup>
        </FormControl>
      </OutterContainer>
      {errors?.modelType &&
        <ErrorMsg variant="p">{errors.modelType.message}</ErrorMsg>
      }
      {errors?.modelName &&
        <ErrorMsg variant="p">{errors.modelName.message}</ErrorMsg>
      }
      {errors?.modelFile &&
        <ErrorMsg variant="p">{errors.modelFile.message}</ErrorMsg>
      }
      {errors?.customModelUrl &&
        <ErrorMsg variant="p">{errors.customModelUrl.message}</ErrorMsg>
      }
      <ConfirmModal
        open={openDialog}
        handleCancel={handleCloseDialog}
        handleConfirm={handleConfirmAction}
        title={confirmDialogContent.title}
        message={confirmDialogContent.message}
      />
    </>
  );
};

export default LanguageModel;
