import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Grid } from "@mui/material";

import { TypographyOnboardLabel, AgentsBox } from "./OnboardAiAgentsStyles";
import OnboardCards from "../../Reusable/Cards/OnboardCards";
import Header from "../../Reusable/CommonHeader/Header";

import { API } from "../../global";
import { LoaderContainer } from "../../Common/commonStyles";
import { getUserRole } from "../../utils/localStorageUtils";
import Loader from "../../Reusable/Loader/Loader";
import { useToast } from "../../utils/ToastContext";

const OnboardAiAgents = () => {
  const navigate = useNavigate();
  const [subscribedAgents, setSubscribedAgents] = useState([]);
  const [allAgents, setAllAgents] = useState([]);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();

  useEffect(() => {
    fetchAllAgents();
    // eslint-disable-next-line
  }, []);

  const fetchSubscribedAgents = (sasToken) => {
    fetch(`${API}/agent/list-subscribed-agents`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        if (response.success) {
          const list = response.result.subscribedList.map((data) => {
            return {
              userAgentId: data._id,
              id: data?.agentData?._id,
              title: data?.agentData?.agent_name,
              imgSrc: data?.agentData?.profile_image
                ? `${data?.agentData?.profile_image}?${sasToken}`
                : null,
              videoSrc: data?.agentData?.demo_video
                ? `${data?.agentData?.demo_video}?${sasToken}`
                : null,
              hoverClr: "#FF6E471A",
              mapperAPI: data?.agentData?.mapper_api,
              taskStatus: "Open",
            };
          });
          setSubscribedAgents(list);
        } else {
          showToast("error", `${response.error.msg}`);
        }
      })
      .catch(() => {
        setLoading(false);
        showToast("error", "Error occurred please try again");
      });
  };

  const fetchAllAgents = () => {
    setLoading(true);
    fetch(`${API}/agent/list-all-agents`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          const sasToken = response.result.sasToken;
          const list = response.result.agents.map((data) => {
            return {
              id: data?._id,
              title: data?.agent_name,
              imgSrc: data?.profile_image
                ? `${data?.profile_image}?${sasToken}`
                : null,
              videoSrc: data?.demo_video
                ? `${data?.demo_video}?${sasToken}`
                : null,
              taskStatus: "close",
              status: data?.agent_description,
              hoverClr: "#FF6E471A",
            };
          });
          setAllAgents(list);
          fetchSubscribedAgents(sasToken);
        } else {
          showToast("error", `${response.error.msg}`);
        }
      })
      .catch(() => {
        setLoading(false);
        showToast("error", "Error occurred please try again");
      });
  };

  const onEmploy = (data) => {
    navigate(`/ig-ai/employ/${data.title}/${data.id}`);
    localStorage.setItem("mapperAPI", data.mapperAPI);
  };

  const onboardAgent = () => {
    //future ref
  };

  const onSettings = (data) => {
    navigate(`/ig-ai/agent-settings/${data.id}`);
  };

  const createAiAgent = () => {
    navigate("/ig-ai/create-ai-agent");
  };

  const onAPISuccess = () => {
    fetchAllAgents();
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Header
        title="AI Agents"
        showCenterBtn={false}
        showSettingsBtn={false}
        showCloneBtn={true}
        buttonTitle="Create AI Agent"
        onButtonAction={createAiAgent}
        userRole={getUserRole()}
      />
      {loading ? (
        <LoaderContainer data-testid="loader-container">
          <Loader />
        </LoaderContainer>
      ) : (
        <Box
          sx={{
            maxWidth: {
              md: "1250px",
              xl: "1400px",
            },

            margin: "0 auto",
          }}
        >
          {subscribedAgents.length > 0 && (
            <AgentsBox>
              <TypographyOnboardLabel>
                Onboarded AI Agents
              </TypographyOnboardLabel>
              <Grid
                container
                gap={4}
                sx={{
                  columnGap: 3,
                  rowGap: 2,
                  justifyContent: {
                    xs: "center",
                    md: "center",
                    lg: "flex-start",
                  },
                }}
              >
                {subscribedAgents.map((data, index) => (
                  <OnboardCards
                    key={index}
                    data={data}
                    viewDetails={onSettings}
                    onAction={onEmploy}
                    onboard="true"
                    onAPISuccess={onAPISuccess}
                    type="onboard-card"
                  />
                ))}
              </Grid>
            </AgentsBox>
          )}
          <AgentsBox>
            <TypographyOnboardLabel>
              {allAgents.length !== 0 ? "All Agents" : ""}
            </TypographyOnboardLabel>
            <Grid
              container
              gap={4}
              sx={{
                columnGap: 3,
                rowGap: 2,
                justifyContent: {
                  xs: "center",
                  md: "center",
                  lg: "flex-start",
                },
              }}
            >
              {allAgents.map((data, index) => (
                <OnboardCards
                  key={index}
                  data={data}
                  viewDetails={onSettings}
                  onAction={onboardAgent}
                  onboard="true"
                  onAPISuccess={onAPISuccess}
                  type="onboard-card"
                />
              ))}
            </Grid>
          </AgentsBox>
        </Box>
      )}
    </Box>
  );
};

export default OnboardAiAgents;
