import React, { useEffect, useState } from "react";

import Countdown from "react-countdown";
import { toast } from "react-toastify";

import {
  HeadingText,
  LoginBtn,
  SubHeadingText,
  SuccessCardVerification,
} from "./RegisterStyles";
import { Box, CardContent } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../../global";
import AuthLayout from "../../Layout/AuthLayout/AuthLayout";
import { LogoImage, LogoImgContainer } from "../Login/LoginStyles";
import { ignithoLogo } from "../../../Constants/Constant";
import { useAuth } from "../AuthContext/AuthContext";
import Loader from "../../../Reusable/Loader/Loader";
import { LoaderContainer } from "../../../Common/commonStyles";

const Completionist = () => <span>Time's up!</span>;

const ErrorIcon = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        fill="none"
        stroke="#E53434"
        strokeWidth="5"
        cx="100"
        cy="100"
        r="90"
        strokeLinecap="round"
        transform="rotate(-90 100 100)"
        className="circle"
      />
      <line
        fill="none"
        stroke="#E53434"
        x1="60"
        y1="60"
        x2="140"
        y2="140"
        strokeWidth="5"
        strokeLinecap="round"
        className="cross-line"
      />
      <line
        fill="none"
        stroke="#E53434"
        x1="140"
        y1="60"
        x2="60"
        y2="140"
        strokeWidth="5"
        strokeLinecap="round"
        className="cross-line"
      />
    </svg>
  );
};

const SuccessIcon = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        fill="none"
        stroke="#68E534"
        strokeWidth="5"
        cx="100"
        cy="100"
        r="90"
        strokeLinecap="round"
        transform="rotate(-90 100 100)"
        className="circle"
      />
      <polyline
        fill="none"
        stroke="#68E534"
        points="44,114 86.5,142 152,69"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="tick"
      />
    </svg>
  );
};

const EmailVerification = () => {
  const { uuid, token } = useParams();
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [mainHeading, setMainHeading] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    handleVerification();
    // eslint-disable-next-line
  }, []);

  const countdownTime = 10000; // 60 seconds in milliseconds

  // Renderer callback with condition
  const renderer = ({ seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <span>
          <b>You will be redirected to dashboard within {seconds} seconds.</b>
        </span>
      );
    }
  };

  const handleVerification = () => {
    setLoader(true);

    fetch(`${API}/auth/verify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ verify_account_key: uuid }),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoader(false);
        if (response.success) {
          setError(false);
          setMainHeading("Congratulations!");
          const userData = response.result;
          localStorage.setItem("token", userData.token);
          localStorage.setItem("sas_url", userData.sas_token);
          localStorage.setItem("userId", userData.user_id);
          localStorage.setItem("role", userData.user_role_key);
          localStorage.setItem("first_name", userData.firstName);
          localStorage.setItem("last_name", userData.lastName);
          login(userData.token);
          setTimeout(() => {
            navigate("/ig-ai/onboard-ai-agent");
          }, 10000);
        } else {
          setError(true);

          setMainHeading(
            "Your account already verified or you are sending a expired verification key!"
          );
        }
      })
      .catch(() => {
        toast.error("Error occurred please try again");
      });
  };

  return (
    <AuthLayout>
      <SuccessCardVerification>
        {loader ? (
          <LoaderContainer>
            <Loader />
            {/* <Typography>Verifying...</Typography> */}
          </LoaderContainer>
        ) : (
          <CardContent>
            <LogoImgContainer>
              <LogoImage src={ignithoLogo} alt="logo" />
            </LogoImgContainer>
            <Box className="content">
              {error ? <ErrorIcon /> : <SuccessIcon />}
            </Box>
            <HeadingText variant="h3">{mainHeading}</HeadingText>
            {!error && (
              <>
                <SubHeadingText variant="p">
                  Your account has been successfully verified.
                </SubHeadingText>
                <LoginBtn>
                  <Countdown
                    date={Date.now() + countdownTime}
                    renderer={renderer}
                  />
                </LoginBtn>
              </>
            )}
          </CardContent>
        )}
      </SuccessCardVerification>
    </AuthLayout>
  );
};

export default EmailVerification;
