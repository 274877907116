import React, { useState } from "react";

import { Box, FormControl, InputLabel, Tab, Typography } from "@mui/material";

import {
  CustomMenuITem,
  CustomSelect,
  CustomTData,
  CustomTHeader,
  CustomTRow,
  CustomTable,
  CustomTableContainer,
  CustomTabs,
} from "./DataQualityCheckStyles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const PreviewTableData = (props) => {
  const tableData = props.tableData.tablePreview;

  const {
    sheetNames,
    sheetData,
    sheet,
    setSheet,
    fileType,
    csvHeader,
    csvColumnData,
    fileUploaded,
    progress
  } = tableData;

  const [value, setValue] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const currentItems = sheetData[sheet]?.slice(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSheet(sheetNames[newValue]);
  };

  const handleChangeDropDown = (event) => {
    setSelectedOption(event.target.value);
    setSheet(sheetNames[event.target.value]);
    setValue(event.target.value);
  };

  return (
    <Box>
      <Box
        sx={{
          marginLeft: "15px ",
          border: "1px solid  #DCDDDF",
          padding: "20px 10px 10px 10px",
          borderTop: "none",
          borderRadius: "0px 0px  5px 5px",
        }}
      >
        {!fileUploaded || progress < 22 ? (
          <Typography
            variant="p"
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: "14px",
              color: "#ABABAB",
            }}
          >
            There are no records to display
          </Typography>
        ) : (
          <>
            {fileType === "csv" ? (
              <CustomTableContainer>
                <CustomTable>
                  <thead>
                    <tr>
                      {csvHeader.map((h, i) => (
                        <CustomTHeader key={i}>{h}</CustomTHeader>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {csvColumnData.length === 0 ? (
                      <td>There are no records to display.</td>
                    ) : (
                      csvColumnData.map((row, i) => (
                        <CustomTRow>
                          {row.map((c, i) => (
                            <CustomTData key={i}>{c}</CustomTData>
                          ))}
                        </CustomTRow>
                      ))
                    )}
                  </tbody>
                </CustomTable>
              </CustomTableContainer>
            ) : (
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },

                    justifyContent: "space-between",
                    height: { xs: "50px", md: "30px" },
                  }}
                >
                  <CustomTabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="visible arrows tabs example"
                  >
                    {sheetNames?.map((sheetname, i) => (
                      <Tab
                        key={i}
                        sx={{
                          ...(value === i && {
                            bgcolor: "#DCD7FC", // Background color for active tab
                            fontWeight: "bold", // Bold font for active tab
                            color: "#6249EF", // Text color for active tab
                            borderRadius: "10px 10px 0 0", // Rounded corners
                            padding: "0px 0px", // Custom padding
                            border: "1px solid #6249EF",
                          }),
                          ...(value !== i && {
                            bgcolor: "#F9FAFB",
                            color: "#212529",
                            border: "1px solid #DDDEE0",
                            borderRadius: "10px 10px 0 0", // Rounded corners
                            borderBottom: {
                              xs: "1px solid #DDDEE0",
                              md: "none",
                            },

                            "&:hover": {
                              bgcolor: "#f5f5f5", // Background color on hover

                              color: "#000", // Text color on hover
                            },
                          }),
                        }}
                        label={sheetname}
                      />
                    ))}
                  </CustomTabs>
                  <FormControl variant="outlined">
                    <InputLabel
                      id="custom-select-label"
                      sx={{ margin: "-10px 0 0 -10px ", fontSize: "13px" }}
                    >
                      Sheets
                    </InputLabel>
                    <CustomSelect
                      labelId="dropdown-label"
                      value={selectedOption}
                      onChange={handleChangeDropDown}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            width: 80,
                          },
                        },
                        MenuListProps: {
                          style: {
                            paddingTop: 0,
                            paddingBottom: 0,
                          },
                        },
                        getContentAnchorEl: null, // Prevents default behavior of anchor positioning
                      }}
                    >
                      {sheetNames.map((sheet, i) => (
                        <CustomMenuITem value={i}>{sheet}</CustomMenuITem>
                      ))}
                    </CustomSelect>
                  </FormControl>
                </Box>

                {sheetNames?.map((s, i) => (
                  <TabPanel key={i} value={value} index={i}>
                    <CustomTableContainer
                      sx={{
                        margin: {
                          xs: "0px 0 0px 0px",
                          md: "-29px -25px 0 -23px",
                        },
                      }}
                    >
                      <CustomTable>
                        <thead>
                          <tr>
                            {sheetData[sheet][0].map((h, i) => (
                              <CustomTHeader key={i}>{h}</CustomTHeader>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {currentItems.length === 0 ? (
                            <td>There are no records to display.</td>
                          ) : (
                            currentItems.map((row, i) => (
                              <CustomTRow>
                                {row.map((c, i) => (
                                  <CustomTData key={i}>
                                    {c === null || c === undefined ? (
                                      "-"
                                    ) : typeof c === "object" ? (
                                      <span>
                                        {Object.keys(c).map((key, index) => (
                                          <p key={index}>
                                            {key}: {c[key]}
                                          </p>
                                        ))}
                                      </span>
                                    ) : (
                                      c
                                    )}
                                  </CustomTData>
                                ))}
                              </CustomTRow>
                            ))
                          )}
                        </tbody>
                      </CustomTable>
                    </CustomTableContainer>
                  </TabPanel>
                ))}
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default PreviewTableData;
