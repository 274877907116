/**
 * @file FileUploadComplete.jsx
 * @description This component renders a customizable file upload.
 * @author "Monika"
 * @date [test]
 */


import React, { useEffect, useState } from 'react';

import {
  Box,
  IconButton,
  LinearProgress,
  Typography,
  styled,
  linearProgressClasses,
} from '@mui/material';

import {
  ChooseFile,
  TextTitle,
  ProgressBox
} from '../../components/DataQualityCheckAgentChat/DataQualityCheckStyles';
import { StyledFlexBox } from "../../Common/commonStyles";
import { FileImg } from './FileUploadedCardStyles';
import {
  greyCloseIcon,
  tickGreenIcon,
} from '../../Constants/Constant';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const FileUploadedCard = (props) => {
  const { resetFileUpload, fileDetails, fileUploaded, imgSrc } = props;

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (fileUploaded) {
      setProgress(100);
    }
  }, [fileUploaded]);

  return (
    <ChooseFile
      sx={{
        padding: "5px",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <IconButton
          p={0}
          sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}
        >
          <FileImg src={imgSrc} alt="file" />
        </IconButton>
        <Box sx={{ width: "100%" }}>
          <StyledFlexBox>
            <TextTitle
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                display: { xs: "none", sm: "flex" },
              }}
            >
              Upload Completed
            </TextTitle>
            <IconButton
              onClick={() => resetFileUpload()}
              sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}
            >
              <img src={greyCloseIcon} alt="greyCloseIcon" />
            </IconButton>
          </StyledFlexBox>
          <Box sx={{ display: "flex" }}>
            <IconButton
              p={0}
              sx={{ display: { xs: "flex", sm: "none", md: "flex" } }}
            >
              <img src={tickGreenIcon} alt="tickGreenIcon" />
            </IconButton>
            <StyledFlexBox
              sx={{
                width: "100%",
                gap: "10px",
              }}
            >
              <Typography
                variant="span"
                sx={{ color: "#868A97", fontSize: "9px" }}
              >
                {fileDetails.fileName}
              </Typography>
              <Typography
                variant="span"
                sx={{
                  color: "#868A97",
                  fontSize: "9px",
                  display: { xs: "none", sm: "none", md: "flex" },
                }}
              >
                size:{fileDetails.fileSize}kb
              </Typography>
              <Typography
                variant="span"
                sx={{
                  color: "#868A97",
                  fontSize: "9px",
                  display: { xs: "none", sm: "none", md: "flex" },
                }}
              >
                {`${progress}%`}
              </Typography>
            </StyledFlexBox>
          </Box>
          <ProgressBox>
            <BorderLinearProgress
              variant="determinate"
              value={progress}
            />
          </ProgressBox>
        </Box>
      </Box>
    </ChooseFile>
  )
}

export default FileUploadedCard
