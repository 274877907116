import { LoadingButton } from "@mui/lab";
import { styled, Card, CardActions, Typography } from "@mui/material";

const SignupButton = styled(LoadingButton)(({ theme }) => ({
  width: "100%",
  textTransform: "capitalize",
  backgroundColor: theme.palette.bluebutton.main,
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: theme.palette.bluebutton.hover,
  },
  fontSize: "0.875rem",
  fontWeight: "normal",
  [theme.breakpoints.up("xl")]: {
    fontSize: "1rem",
  },

  "&:disabled": {
    backgroundColor: theme.palette.bluebutton.disable,
    color: "grey",
  },
}));

const LoginText = styled("span")(({ theme }) => ({
  color: theme.palette.bluebutton.main,
}));

const SuccessCard = styled(Card)(({ theme }) => ({
  textAlign: "center",
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",

  [theme.breakpoints.up("xs")]: {
    width: "60%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "70%",
  },
  [theme.breakpoints.up("md")]: {
    width: "80%",
  },
}));

const HeadingText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "25px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "30px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "35px",
  },
}));

const SubHeadingText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "13px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "15px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "20px",
  },
}));

const LoginBtn = styled(CardActions)({
  display: "flex",
  justifyContent: "center",
});

const SuccessCardVerification = styled(Card)(({ theme }) => ({
  textAlign: "center",
  display: "grid",
  justifyContent: "center",
  [theme.breakpoints.up("xs")]: {
    width: "60%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "70%",
  },
  [theme.breakpoints.up("md")]: {
    width: "100%",
  },
}));

export {
  SignupButton,
  LoginText,
  SuccessCard,
  LoginBtn,
  HeadingText,
  SubHeadingText,
  SuccessCardVerification,
};
