import React from 'react';
import { useRouteError } from 'react-router-dom';
import { Container, Typography, Box, Paper, Grid } from '@mui/material';

export default function ErrorPage() {
  const error = useRouteError();
  return (
    <Container component="main" maxWidth="xs">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: '100vh' }}
      >
        <Paper elevation={3} sx={{ padding: 4 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h1" component="h1" gutterBottom>
              Oops!
            </Typography>
            <Typography variant="body1" gutterBottom>
              Sorry, an unexpected error has occurred.
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <i>{error.statusText || error.message}</i>
            </Typography>
          </Box>
        </Paper>
      </Grid>
    </Container>
  );
}
