/**
 * @file FileUploadComplete.jsx
 * @description This component renders a customizable Loader.
 * @author "Pavithra"
 * @date [test]
 */


import React from "react";
import { PropagateLoader } from "react-spinners";

const Loader = () => {
  return (
      <PropagateLoader color="#6249EF" height={80} width={80} />
  );
};

export default Loader;
