import React from 'react';

import {
  RootBox,
  LeftSideBox,
  TextBox,
  FormBox,
  TitleTypography,
  SubtitleTypography,
  BackgroundImage
} from './AuthLayoutStyles';

import { loginBg } from '../../../Constants/Constant';


const AuthLayout = ({ children }) => {
  return (
    <RootBox>
      {/* Left Side Image */}
      <LeftSideBox>
        <BackgroundImage src={loginBg} alt="Background" />
        <TextBox>
          <TitleTypography>
            We Power Intelligent, <br />
            Real Time Businesses
          </TitleTypography>
          <SubtitleTypography>
            Effortlessly train and deploy your AI team. <br />
            Scale your business without increasing your workforce.
          </SubtitleTypography>
        </TextBox>
      </LeftSideBox>

      {/* Form */}
      <FormBox>
        {children}
      </FormBox>
    </RootBox>
  )
}

export default AuthLayout;
