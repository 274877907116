import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { StyledInnerBox } from "../../components/DataQualityCheckAgentChat/DataQualityCheckStyles";
import { gPink } from "../../Constants/Constant";
import { useNavigate, useParams } from "react-router-dom";
import AvatarBox from "./AvatarBox";
import { API } from "../../global";
import { toast } from "react-toastify";

const ChatBox = React.memo(() => {
  const navigate = useNavigate();
  const { agentName, agentId } = useParams();

  const chatScreen = true;
  const [messageList, setMessageList] = useState([
    {
      msg: `<b>Welcome to the ${agentName}!</b></br>`,
      type: "api",
    },
  ]);
  const [questionNum, setQuestionNum] = useState(0);

  const chatWindowRef = useRef(null);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messageList]);

  useEffect(() => {
    fetchQuestions(null);

    // eslint-disable-next-line
  }, []);

  const handleDestinationData = (destinationData) => {
    localStorage.setItem("destinationData", JSON.stringify(destinationData));
  };

  const fetchQuestions = (messageKey) => {
    setQuestionNum(questionNum + 1);
    fetch(`${API}/mapping_chat/data-mapping-chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ messageKey, agentId }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          const question = response.result.questions;
          const destinationData = response.result.destination_details;
          const obj = {
            msg: question.question,
            type: "api",
            options: question.options,
            destinationData: destinationData,
          };
          if (destinationData) {
            handleDestinationData(destinationData);
          }
          const dataList = [...messageList, obj];
          const cleanedArray = dataList.filter((item) => item !== "");
          setMessageList(cleanedArray);
        } else {
          toast.error(`${response.error.msg}`, {
            style: {
              // Adjust the width as needed
              width: "400px",
            },
          });
        }
      })
      .catch(() => {
        toast.error("Error occurred please try again");
      });
  };

  const handleFileChange = () => {
    navigate("/ig-ai/data-processing");
  };

  const selectOption = async (type) => {
    fetchQuestions(type);
  };

  const saveUserMessage = (message) => {
    const obj = {
      msg: message,
      type: "user",
    };
    const dataList = [...messageList, obj];
    const cleanedArray = dataList.filter((item) => item !== "");
    setMessageList(cleanedArray);
  };

  const setSelectedOptions = (type) => {
    // eslint-disable-next-line
    messageList.map((item) => {
      // eslint-disable-next-line
      item?.options?.map((opt) => {
        if (!opt.selected) {
          if (opt.text === type) {
            opt.selected = "selected";
          } else {
            opt.selected = "notSelected";
          }
        }
      });
    });
  };

  return (
    <>
      {chatScreen ? (
        <Box
          ref={chatWindowRef}
          sx={{
            height: "80%",
            overflowY: "auto",
          }}
        >
          {messageList.map((data) => (
            <AvatarBox
              key={data}
              data={data}
              handleFileChange={handleFileChange}
              selectOption={selectOption}
              saveUserMessage={saveUserMessage}
              setSelectedOptions={setSelectedOptions}
            />
          ))}
        </Box>
      ) : (
        <StyledInnerBox sx={{ height: "86%" }}>
          <IconButton>
            <img src={gPink} alt="g-icon" />
          </IconButton>
          <Typography variant="div">
            What Assistance Do You Need Today?
          </Typography>
        </StyledInnerBox>
      )}

      <Box
        sx={{
          height: { xs: "10%", md: "12%" },
          display: "flex",
          alignItems: "flex-end",
          padding: { xs: "0 10px", md: "10px" },
        }}
      >
        {/* commented for future use  */}

        {/* <CustomTextField
            fullWidth
            value={textMsg}
            onChange={handleInputChange}
            onKeyDown={handleInputOnKeyDown}
            variant="outlined"
            placeholder="Send a message"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <img src={pinIcon} alt="pinIcon" />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={showDataProcessing}>
                    <img src={send} alt="send" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          /> */}
      </Box>
    </>
  );
});

export default ChatBox;
