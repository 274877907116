/**
 * @file CustomDropdownStyle.js
 * @description This component renders a customizable  dropdown menu that allows users to select from a list of options. 
 *              It manages its own state for the selected option and supports external control via props.
 * @author "Pavithra"
 * @date [test]
 */


import { Button, styled } from "@mui/material";

const DropdownContainer = styled('div')(({ width }) => ({
  position: "relative",
  width: width,
  fontSize: '12px',
}));

const DropdownButton = styled(Button)({
  width: "100%",
  padding: "7.5px 12px",
  backgroundColor: "#F1F1F1",
  border: "1px solid #dcdcdc",
  textAlign: "left",
  cursor: "pointer",
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  borderRadius: '4px',
  color: "#868A97",
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: "12px",
  textTransform: "capitalize",
  fontWeight: "400", 
});

const DropdownList = styled('ul')({
  position: "absolute",
  width: "100%",
  backgroundColor: "#ffffff",
  border: "1px solid #dcdcdc",
  maxHeight: "150px",
  overflowY: "auto",
  marginTop: "4px",
  padding: "0",
  listStyle: "none",
  zIndex: "1000",
  color: "#868A97"
});

const DropdownListItem = styled('li')({
  padding: "10px",
  cursor: "pointer",
  "&: hover": {
    backgroundColor: "#f0f0f0"
  }
});

export {
  DropdownContainer,
  DropdownButton,
  DropdownList,
  DropdownListItem
}