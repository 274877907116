import { Box, Button, Grid, Select, styled, Typography } from "@mui/material";

// Do not change the common variable. If changes are needed, modify the in the specific style instead.
const commonBorder = '1px solid #DCDCDC'

const MainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  width: "100%",
  padding: "0 20px 20px 20px",
});

const TasksMainContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100%",
  gap: "20px",
  padding: "15px",

  [theme.breakpoints.up("xs")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
  },
}));

const SidebarContainer = styled(Box)(({ theme, isSmallScreen }) => ({
  height: "100%",
  backgroundColor: "#FAFAFB",
  padding: "12px",
  border: commonBorder,
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: isSmallScreen ? "space-between" : "flex-start",
  gap: "7px",

  [theme.breakpoints.up("xs")]: {
    flexDirection: "row",
    width: "100%",
    height: "max-content",
  },
  [theme.breakpoints.up("lg")]: {
    flexDirection: "column",
    width: "200px",
    height: "100%",
  },
}));

const TaskItemLabel = styled(Typography)({
  fontSize: "13px",
  padding: "0px 3px",
});

const CustomGrid = styled(Grid)(({ isActive }) => ({
  borderRadius: "4px",

  backgroundColor: isActive ? "#DCD7FC" : "",
  color: isActive ? "#6249EF" : "#868A97",

  "&:hover": {
    backgroundColor: isActive ? "#DCD7FC" : "#E5E5E5",
    color: isActive ? "#6249EF" : "#150F36",
    cursor: "pointer",
  },
}));

const CustomSelect = styled(Select)({
  fontSize: "14px", // Adjust font size here
  color: "#868A97",

  "& .MuiSelect-select": {
    padding: "8px", // Adjust padding here
  },
  "& .MuiOutlinedInput-root": {
    padding: "0px", // Adjust padding for the outlined input
  },
});

const CustomTitle = styled(Typography)({
  fontWeight: 500,
  fontSize: "15px",
  marginRight: "10px",
});

const CustomTD = styled("td")({
  width: "120px",
  maxWidth: "140px" /* Set a specific width */,
  whiteSpace: " nowrap" /* Prevent text from wrapping */,
  overflow: "hidden" /* Hide the overflowed text */,
  textOverflow: "ellipsis" /* Show ellipsis (...) */,
});

const CustomCard = styled(Box)(({ taskStatus }) => ({
  width: "100%",
  height: "fit-content",
  border:
    taskStatus === "play"
      ? " 1px solid #00BB25"
      : taskStatus === "pause"
      ? "1px solid #FA8008"
      : "1px solid #ABABAB",
  borderRadius: "4px",
  display: "flex",
}));

const CustomAction = styled(Box)(({ taskStatus }) => ({
  backgroundColor:
    taskStatus === "play"
      ? "#E5F8E8"
      : taskStatus === "pause"
      ? "#FFF3E8"
      : "#E5E5E5",
  borderRadius: "4px",
  width: "60px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const CustomBadge = styled(Box)(({ taskBadge }) => ({
  backgroundColor:
    taskBadge === "Scheduled"
      ? "#E9F7FF"
      : taskBadge === "Completed"
      ? "#E5F8E8"
      : "#FFF3F3",
  border:
    taskBadge === "Scheduled"
      ? "1px solid #00A2FF"
      : taskBadge === "Completed"
      ? "1px solid #00BB25"
      : "1px solid #EB0000",
  width: "fit-content",
  padding: "0px 5px",
  borderRadius: "10px",
  fontSize: "10px",
  color:
    taskBadge === "Scheduled"
      ? "#00A2FF"
      : taskBadge === "Completed"
      ? "#00BB25"
      : "#EB0000",
  fontWeight: 500,
}));

const CustomPriority = styled(Box)(({ taskPriority }) => ({
  backgroundColor:
    taskPriority === "High"
      ? "#FFF3F3"
      : taskPriority === "Medium"
      ? "#FFF9EC"
      : "#E5F8E8",
  border:
    taskPriority === "High"
      ? "1px solid #EB0000"
      : taskPriority === "Medium"
      ? "1px solid #FFAA00"
      : "1px solid #00BB25",
  width: "fit-content",
  padding: "0px 5px",
  borderRadius: "10px",
  fontSize: "10px",
  color:
    taskPriority === "High"
      ? "#EB0000"
      : taskPriority === "Medium"
      ? "#FFAA00"
      : "#00BB25",
  fontWeight: 500,
}));

const CustomLabel = styled("label")({
  fontSize: "11px",
  color: "#868A97",
  position: "absolute",
  backgroundColor: "#ffff",
  top: "2px",
  left: "10px",
  padding: "0px 5px",
});

const CustomButtonAction = styled(Button)(({ theme, buttonColor }) => ({
  textTransform: "capitalize",
  border: `1px solid ${buttonColor}`,
  padding: "4px 7px",
  color: buttonColor,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "5px",
  height: "20px",
  fontSize: "12px",
  [theme.breakpoints.up("xs")]: {
    width: "fit-content",
    minWidth: "0px",
  },
  [theme.breakpoints.up("lg")]: {
    width: "63px",
  },

  "&:hover": {
    backgroundColor: buttonColor,
    color: "#ffff",
  },
}));

const PaginationContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
}));

const ShowEntries = styled(Box)(({ theme }) => ({
  color: "#868A97",
  fontSize: "12px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "12px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "14px",
  },
}));

const EntriesInput = styled("input")(({ theme }) => ({
  padding: "4px 8px",
  color: "#868A97",
  width: "50px",
  borderRadius: "5px",
  outline: "none",
  border: "1px solid #868A97",
  marginLeft: "10px",
}));

const CustomBtnText = styled(Typography)(({ isSmallScreen }) => ({
  fontSize: "12px",
  display: isSmallScreen ? "none" : "block",
}));

export {
  MainContainer,
  TasksMainContainer,
  SidebarContainer,
  TaskItemLabel,
  CustomGrid,
  CustomSelect,
  CustomTitle,
  CustomTD,
  CustomCard,
  CustomAction,
  CustomBadge,
  CustomPriority,
  CustomLabel,
  CustomButtonAction,
  PaginationContainer,
  ShowEntries,
  EntriesInput,
  CustomBtnText,
};
