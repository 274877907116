/**
 * @file CustomDropdown.jsx
 * @description This component renders a customizable dropdown menu that allows users to select from a list of options. 
 *              It manages its own state for the selected option and supports external control via props.
 * @date [test]
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  DropdownContainer,
  DropdownButton,
  DropdownList,
  DropdownListItem
} from "./CustomDropdownStyles";
import { dropdownArrow } from '../../Constants/Constant';
import { Box } from '@mui/material';

const CustomDropdown = (props) => {
  const { options, value, onChange, placeholder, width, disabled } = props;
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef} width={width}>
      <DropdownButton onClick={handleToggle} disabled={disabled}>
        <Box>
          {value ? value.label : placeholder}
        </Box>
        <img src={dropdownArrow} alt="arrow" />
      </DropdownButton>
      {isOpen && (
        <DropdownList>
          {options.map((option, index) => (
            <DropdownListItem key={index} onClick={() => handleSelect(option)}>
              {option.label}
            </DropdownListItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

CustomDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.shape({
    label: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  width: PropTypes.string,
};

export default CustomDropdown;
