import { styled, Box, Button } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

const MainContainer = styled(Box)({
  marginLeft: "15px",
  border: "1px solid #DCDDDF",
  padding: "20px 10px 10px 10px",
  borderRadius: "0px 0px  5px 5px",
  borderTop: "none"
});

const SourceTargetContainer = styled(Box)({
  padding: "10px",
  fontSize: "12px",
  color: "#212529",
});

const HeadingContainer = styled(Box)({
  display: "flex",
  padding: "10px",
  width: "100%",
  fontSize: "14px",
  fontWeight: "bold"
});

const Heading = styled(Box)({
  width: "50%",
});

const HeadingTarget = styled(Box)({
  width: "50%",
  paddingLeft: "24px"
});

const MappingBox = styled(Box)({
  maxHeight: "300px",
  overflowY: "auto",
  padding: "10px",
  border: "1px solid #00000029",
  borderRadius: "5px",
});

const MappingRow = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: "10px",
  width: "100%"
});

const Field = styled(Box)({
  width: "48%",
  height: "43px",
  borderRadius: "10px",
  padding: "10px 20px",
  display: "flex",
  alignItems: "center",
  border: "1px solid #00000029",
  boxShadow: "0 0 13px 2px #efeded",
});

const TargetField = styled(Box)({
  width: "48%",
  height: "43px",
  borderRadius: "10px",
  padding: "10px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  border: "1px solid #00000029",
  boxShadow: "0 0 13px 2px #efeded",
});

const DotsContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginLeft: "10px",
  marginRight: "10px",
});

const Dot = styled(Box)({
  width: "3px",
  height: "3px",
  borderRadius: "50%",
  backgroundColor: "#707070",
  marginLeft: "1px",
});

const CustomEditIcon = styled(EditIcon)({
  cursor: "pointer",
  fontSize: "20px",
  color: "#8248D0"
})

const CustomInput = styled('input')({
  border: "none",
  fontSize: "12px",
  color: "#212529",
  "&:focus": {
    outline: "none",
  },
})

const CustomButton = styled(Button)({
  textTransform: "capitalize",
  backgroundColor: "#6249EF",
  color: "white",
  "&:hover": {
    backgroundColor: "#4E3BBC",
  },
})

const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  marginTop: '10px'
})

const TargetFieldRow = styled(Box)({
  width : '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

export {
  MainContainer,
  SourceTargetContainer,
  HeadingContainer,
  Heading,
  HeadingTarget,
  MappingBox,
  MappingRow,
  Field,
  DotsContainer,
  Dot,
  TargetField,
  CustomEditIcon,
  CustomInput,
  CustomButton,
  ButtonContainer,
  TargetFieldRow,
} 