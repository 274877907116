import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import PropTypes from 'prop-types';
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  InputAdornment,
  Box,
  Backdrop,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

import { ignithoLogo } from "../../Constants/Constant";
import {
  AuthButtons,
  LoginButton,
  Logo,
  MenuButton,
  NavbarContainer,
  SearchBox,
  SearchContainerXs,
  SearchInput,
  SearchXsButton,
  SignUpButton,
  ToolbarContainer,
} from "./LandingPageStyle";



const InitialAppBar = (props) => {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [inputText, setInputText] = useState("");

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const onAction = () => {
    navigate("/login");
  };

  const signIn = () => {
    navigate("/sign-up");
  };

  const handleSearchXs = () => {
    setSearchOpen(!searchOpen);
    setInputText("");
  };
  
  const searchAgents = ()=>{
    props.onSearch(inputText)
  }

  const handleInput = (e) => {
    setInputText(e.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchAgents();
    }
  };

  return (
    <NavbarContainer position="static">
      <ToolbarContainer disableGutters>
        <IconButton edge="start" color="inherit" aria-label="logo">
          <Logo src={ignithoLogo} alt="logo" />
        </IconButton>
        <SearchBox sx={{ display: { xs: "none", sm: "flex" } }}>
          <SearchInput
            placeholder="Search by agent name."
            inputProps={{ "aria-label": "search" }}
            onChange={handleInput}
            onKeyDown={handleKeyPress}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={searchAgents} edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </SearchBox>

        <Box
          sx={{
            marginLeft: { xs: "50%", sm: "0%" },
          }}
        >
          <SearchXsButton
            sx={{
              display: { xs: "flex", sm: "none" },
            }}
            onClick={handleSearchXs}
          >
            <SearchIcon  onClick={searchAgents}/>
          </SearchXsButton>
          <AuthButtons>
            <LoginButton variant="text" onClick={onAction}>
              Login
            </LoginButton>
            <SignUpButton variant="contained" onClick={signIn}>
              Sign Up
            </SignUpButton>
          </AuthButtons>
        </Box>

        <MenuButton
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </MenuButton>
      </ToolbarContainer>
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={onAction}>
              <ListItemText primary="Login" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={signIn}>
              <ListItemText primary="Sign Up" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: { xs: "flex", sm: "none" },
        }}
        open={searchOpen}
      >
        <SearchContainerXs>
          <Box>
            <SearchBox>
              <SearchInput
                sx={{ width: "95%" }}
                value={inputText}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => setInputText(e.target.value)}
                onKeyDown={handleKeyPress}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      sx={{
                        borderRight: "1px solid #0000001a",
                        borderRadius: "0px",
                      }}
                    >
                      <SearchIcon  onClick={searchAgents} />
                    </IconButton>

                    <IconButton
                      edge="end"
                      onClick={handleSearchXs}
                      sx={{ paddingLeft: "15px" }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </SearchBox>
            <Box sx={{ color: "lightgray", margin: "5px" }}>
              Press enter to see all results
            </Box>
          </Box>
        </SearchContainerXs>
      </Backdrop>
    </NavbarContainer>
  );
};

InitialAppBar.propTypes = {
  onSearch: PropTypes.func.isRequired
};

export default InitialAppBar;
