import { Box, styled, Typography } from "@mui/material";

const TypographyOnboardLabel = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: 500,
  textTransform: "capitalize",
  opacity: "1",
  color: "0D162F",
  marginBottom: "10px",

  [theme.breakpoints.up("xs")]: {
    textAlign: "center",
  },

  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
}));

const AgentsBox = styled(Box)({
  width: "100%",
  marginBottom: "20px",
  paddingInline: "24px",
});

export { TypographyOnboardLabel, AgentsBox };
