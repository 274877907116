import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Box,
  Divider,
  IconButton,
  Pagination,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ReplayIcon from "@mui/icons-material/Replay";

import Header from "../../Reusable/CommonHeader/Header";
import {
  playGreen,
  pauseOrange,
  pauseGrey,
  emptyBox,
} from "../../Constants/Constant";
import AllTasksSidebar from "./AllTasksSidebar";
import {
  EntriesInput,
  MainContainer,
  PaginationContainer,
  ShowEntries,
  TasksMainContainer,
} from "./AllTasksStyle";
import AllTasksContent from "./AllTasksContent";
import { getUserRole } from "../../utils/localStorageUtils";
import { useToast } from "../../utils/ToastContext";
import { API } from "../../global";
import { LoaderContainer } from "../../Common/commonStyles";
import Loader from "../../Reusable/Loader/Loader";
import { StyledInnerBox } from "../DataQualityCheckAgentChat/DataQualityCheckStyles";

// const tasks = [
//   {
//     title: "Daily Data Sync",
//     id: "TID10004",
//     status: "Scheduled",
//     created: "1 Jul 2024 @ 08:11 AM",
//     startTime: "1 Jul 2024 @ 9:00 AM",
//     endTime: "31 Jul 2024 @ 9:00 PM",
//     occurrence: "Daily @ 09:00 AM",
//     priority: "High",
//     result: "N/A",
//     error: "None",
//     comments: "Scheduled daily at 9 AM",
//     icon: <PauseIcon fontSize="12px" />,
//     btn: "Pause",
//     statusIcon: playGreen,
//     currentStatus: "play",
//   },
//   {
//     id: "TID10001",
//     title: "Validate Customer Data",
//     status: "Completed",
//     created: "1 Jul 2024 @ 08:12 AM",
//     startTime: "5 Jul 2024 @ 10:15 AM",
//     endTime: "5 Jul 2024 @ 10:19 AM",
//     occurrence: "N/A",
//     priority: "Medium",
//     result: "95% valid, 5% error",
//     error: "None",
//     comments: "Data validated successfully",
//     icon: <PlayArrowIcon fontSize="12px" />,
//     btn: "Run",
//     statusIcon: pauseGrey,
//     currentStatus: "failed",
//   },
//   {
//     id: "TID10003",
//     title: "Validate Sales Transactions",
//     status: "Failed",
//     created: "1 Jul 2024 @ 08:14 AM",
//     startTime: "1 Jul 2024 @ 9:00 AM",
//     endTime: "31 Jul 2024 @ 9:00 PM",
//     occurrence: "Daily @ 09:00 AM",
//     priority: "High",
//     result: "N/A",
//     error: "Network timeout",
//     comments: "Retrying recommended",
//     icon: <ReplayIcon fontSize="12px" />,
//     btn: "Retry",
//     statusIcon: pauseOrange,
//     currentStatus: "pause",
//   },
//   {
//     id: "TID10002",
//     title: "Map User Data to CRM Fields",
//     status: "Failed",
//     created: "1 Jul 2024 @ 08:15 AM",
//     startTime: "2 Jul 2024 @ 02:14 PM",
//     endTime: "2 Jul 2024 @ 02:14 PM",
//     occurrence: "N/A",
//     priority: "Low",
//     result: "100% error",
//     error: "Data format mismatch",
//     comments: "Data validation failed",
//     icon: <ReplayIcon fontSize="12px" />,
//     btn: "Retry",
//     statusIcon: pauseGrey,
//     currentStatus: "failed",
//   },
// ];

const AllTasks = () => {
  const navigate = useNavigate();
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isXLScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const itemsPerPage = isXLScreen ? 4 : isLargeScreen ? 3 : 2;
  const [entriesPerPage, setEntriesPerPage] = useState(itemsPerPage);
  const { showToast } = useToast();
  const { agentId } = useParams();

  useEffect(() => {
    fetchTasks();
    // eslint-disable-next-line
  }, []);

  const fetchTasks = async () => {
    const agentDetails = {
      agentId: agentId,
    };
    setLoading(true);
    fetch(`${API}/agent/get-agent-history`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(agentDetails),
    })
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        if (res.success) {
          const updatedTasks = res.result.agentHistories.map((task) => {
            let icon, btn, statusIcon;
            switch (task.currentStatus) {
              case "play":
                icon = <PauseIcon fontSize="12px" />;
                btn = "Pause";
                statusIcon = playGreen;
                break;
              case "pause":
                icon = <ReplayIcon fontSize="12px" />;
                btn = "Retry";
                statusIcon = pauseOrange;
                break;
              case "failed":
              default:
                icon = <PlayArrowIcon fontSize="12px" />;
                btn = "Run";
                statusIcon = pauseGrey;
                break;
            }

            return {
              title: task.task,
              id: task.task_id,
              status: task.status,
              created: new Date(task.created_at).toLocaleString(),
              startTime: task.start_date
                ? new Date(task.start_date).toLocaleString()
                : "N/A",
              endTime: task.end_date
                ? new Date(task.end_date).toLocaleString()
                : "N/A",
              occurrence: task.occurrence || "N/A",
              priority: task.priority || "N/A",
              result: task.result || "N/A",
              error: task.error || "None",
              comments: task.comments || "N/A",
              icon,
              btn,
              statusIcon,
              currentStatus: task.status.toLowerCase(),
            };
          });
          setTasks(updatedTasks);
        } else {
          showToast("error", `${res.error.msg}`);
        }
      })
      .catch((error) => {
        setLoading(false);
        showToast("error", "Failed to fetch agent histories.");
        console.error("Error fetching agent histories:", error);
      });
  };

  const onBack = () => {
    navigate("/ig-ai/onboard-ai-agent");
  };

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  const handleEntriesPerPageChange = (event) => {
    setEntriesPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTasks = tasks.slice(indexOfFirstItem, indexOfLastItem);
  const pageCount = Math.ceil(tasks.length / itemsPerPage);

  const agentName = localStorage.getItem("agentName");

  console.log(currentTasks, "currentTasks");

  return (
    <MainContainer sx={{ p: "0px" }}>
      <Header
        title={agentName}
        showBackBtn={true}
        showCenterBtn={true}
        showSettingsBtn={true}
        showCloneBtn={true}
        onBack={onBack}
        buttonTitle="Clone AI Agent"
        history={true}
        userRole={getUserRole()}
      />
      <Divider sx={{ margin: "0 10px" }} />

      <TasksMainContainer>
        <AllTasksSidebar isSmallScreen={isSmallScreen} />
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            gap: "20px",
            marginBottom: "20px",
          }}
        >
          {loading ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : (
            <>
              {currentTasks.length === 0 ? (
                <StyledInnerBox>
                  <IconButton>
                    <img src={emptyBox} alt="empty-icon" />
                  </IconButton>
                  <Typography variant="div" p={2} sx={{ textAlign: "center" }}>
                    There is no history available for this agent.
                  </Typography>
                </StyledInnerBox>
              ) : (
                <>
                  <AllTasksContent
                    tasks={currentTasks}
                    isSmallScreen={isSmallScreen}
                  />

                  <PaginationContainer>
                    <ShowEntries sx={{ display: { xs: "none", md: "block" } }}>
                      Showing {indexOfFirstItem + 1} -{" "}
                      {Math.min(indexOfLastItem, tasks.length)} of{" "}
                      {tasks.length} records
                      <EntriesInput
                        type="number"
                        value={entriesPerPage}
                        onChange={handleEntriesPerPageChange}
                        min="1"
                        max={tasks.length}
                      />
                    </ShowEntries>
                    <Pagination
                      count={pageCount}
                      page={currentPage}
                      onChange={handleChangePage}
                      color="primary"
                      variant="outlined"
                      shape="rounded"
                    />
                  </PaginationContainer>
                </>
              )}
            </>
          )}
        </Box>
      </TasksMainContainer>
    </MainContainer>
  );
};

export default AllTasks;
