import React, { useContext, useEffect, useState } from "react";

import { Box, Divider } from "@mui/material";

import { LoaderContainer, MainContainer } from "../../Common/commonStyles";
import Header from "../../Reusable/CommonHeader/Header";
import NameContainer from "./NameContainer";
import EmailContainer from "./EmailContainer";
import PasswordContainer from "./PasswordContainer";
import ManageAccountContainer from "./ManageAccountContainer";
import { MainAccountContainer } from "./ProfilePageStyles";
import { API } from "../../global";
import { MyContext } from "../../utils/Provider";
import Loader from "../../Reusable/Loader/Loader";

const ProfilePage = () => {
  const [loading, setLoading] = useState(false);
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  const [emailID, setEmailID] = useState("");
  const { firstName, setFirstName, lastName, setLastName } =
    useContext(MyContext);

  useEffect(() => {
    fetchDetails();
    //eslint-disable-next-line
  }, []);

  const fetchDetails = () => {
    setLoading(true);
    viewProfileDetails();
  };

  const viewProfileDetails = () => {
    fetch(`${API}/profile/view-profile-details`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setFirstName(res.result.firstName);
          setLastName(res.result.lastName);
          setEmailID(res.result.email);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };


  return (
    <MainContainer>
      <Header title="Account" />
      <Divider sx={{ margin: "0 10px" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {loading ? (
          <LoaderContainer data-testid="loader-container">
            <Loader />
          </LoaderContainer>
        ) : (
          <MainAccountContainer>
            <NameContainer
              firstName={firstName}
              lastName={lastName}
              viewProfileDetails={viewProfileDetails}
            />
            <EmailContainer
              emailID={emailID}
              viewProfileDetails={viewProfileDetails}
            />
            <PasswordContainer viewProfileDetails={viewProfileDetails} />
            <ManageAccountContainer />
          </MainAccountContainer>
        )}
      </Box>
    </MainContainer>
  );
};

export default ProfilePage;
