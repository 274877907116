import React from "react";
import { useNavigate } from "react-router-dom";

import { Avatar, Typography } from "@mui/material";

import {
  Wrapper,
  BackButton,
  Container,
  Header,
  Icon,
  OnboardTag,
  Content,
  BackImg,
  ContentHeader,
  ContentBody,
  SubHeader,
  MainHeader,
  Overview,
  OverviewContent,
  OverviewInfo,
  Heading,
  List,
  List1,
  ListItem,
  ListItem1,
  NestedList,
  ListItemBold,
  NestedListItem,
} from "./AiAgentDetailedViewStyles";
import { backIcon, whiteBackIcon } from "../../Constants/Constant";

const AiAgentDetailedView = () => {
  const navigate = useNavigate();

  const title = localStorage.getItem("title");
  const ImgSrc = localStorage.getItem("imgSrc");

  const onBack = () => {
    navigate("/");
  };

  const onBoard = () => {
    navigate("/login");
  };

  function stringAvatar() {
    return {
      sx: {
        background:
          "transparent linear-gradient(210deg, #8248D0 0%, #E86444 100%) 0% 0% no-repeat padding-box",
        color: "white",
        width: "100px",
        height: "100px",
        fontSize: "45px",
      },
      children: `${title?.split(" ")[0][0]}`,
    };
  }

  return (
    <>
      {/* Navbar */}
      <Wrapper>
        <Container>
          <BackButton onClick={onBack}>
            <BackImg src={backIcon} alt="back" className="back-default" />
            <BackImg src={whiteBackIcon} alt="back" className="back-hover" />
            Back
          </BackButton>
          <Content>
            <ContentHeader
              sx={{
                backgroundColor: "#FF6E471A",
              }}
            >
              <Icon
                sx={{
                  display: { xs: "none", md: "flex" },
                }}
              >
                {ImgSrc !== " " ? (
                  <img src={ImgSrc} alt="agent" />
                ) : (
                  <Avatar {...stringAvatar()} />
                )}
              </Icon>

              <Header>
                <MainHeader>
                  {title}
                  <OnboardTag onClick={onBoard}>Onboard</OnboardTag>
                </MainHeader>
                <SubHeader>Sales</SubHeader>
              </Header>
            </ContentHeader>
            <ContentBody>
              <Overview>Overview</Overview>
              <OverviewContent>
                <OverviewInfo>
                  Step into the future of email marketing with Piqual AI SDR
                  Agent, a cutting-edge solution designed to redefine the way
                  you engage with your audience. Piqual harnesses the power of
                  artificial intelListItemgence to transform mundane email
                  campaigns into dynamic, personaListItemzed experiences that
                  captivate and convert.
                </OverviewInfo>
                <OverviewInfo>
                  At the heart of Piqual ListItemes sophisticated AI algorithms,
                  which meticulously analyze a myriad of data points including
                  recipient behavior, content performance, and engagement
                  metrics. By gaining deep insights into each interaction,
                  Piqual empowers marketers to craft highly targeted and
                  relevant email campaigns that resonate with their audience on
                  a profound level.
                </OverviewInfo>
                <OverviewInfo>
                  As an expert tool at Ignite, Piqual AI SDR Agent is designed
                  to revolutionize your email marketing campaigns with
                  cutting-edge AI technology.
                </OverviewInfo>
              </OverviewContent>

              <Heading>Your Tools :</Heading>
              <List>
                <ListItem>
                  <ListItemBold component="span">
                    Upload email content and identify themes:
                  </ListItemBold>
                  <Typography variant="body2" component="span" display="block">
                    Allows users to upload email content and automatically
                    identify themes within the text.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemBold component="span">Bulk-run to:</ListItemBold>
                  <NestedList>
                    <NestedListItem>
                      Apply high-level analysis per email:
                      <Typography
                        variant="body2"
                        component="span"
                        display="block"
                      >
                        - Applies a high-level analysis to each uploaded email
                        content.
                      </Typography>
                    </NestedListItem>
                    <NestedListItem>
                      Create an email Knowledge-set:
                      <Typography
                        variant="body2"
                        component="span"
                        display="block"
                      >
                        - Generates a dataset of email utterances based on
                        confirmed themes for further analysis.
                      </Typography>
                    </NestedListItem>
                  </NestedList>
                </ListItem>
                <ListItem>
                  <ListItemBold component="span">
                    Calculate category statistics:
                  </ListItemBold>
                  <Typography variant="body2" component="span" display="block">
                    Analyzes category statistics within the email dataset,
                    providing counts and percentages for each category.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemBold component="span">
                    Theme focused summarization and quote extraction:
                  </ListItemBold>
                  <Typography variant="body2" component="span" display="block">
                    Extracts summarized content and quotes from the email
                    dataset based on specified themes.
                  </Typography>
                </ListItem>
              </List>

              <Heading>Requirements for Success :</Heading>
              <List1>
                <ListItem1>Keep careful track of actions and goals.</ListItem1>
                <ListItem1>
                  Use minimal words for instructions, avoiding self-speculation.
                </ListItem1>
                <ListItem1>
                  Provide clear bullet-point lists for user input.
                </ListItem1>
                <ListItem1>
                  Refrain from modifying output for specific tools.
                </ListItem1>
                <ListItem1>
                  Direct users to documentation for tasks not listed.{" "}
                </ListItem1>
                <ListItem1>
                  Maintain the integrity of output, avoiding modifications.
                </ListItem1>
                <ListItem1>
                  Avoid speculation about platform capabilities.
                </ListItem1>
              </List1>

              <Heading>Your Tools :</Heading>
              <List>
                <ListItem>
                  <ListItemBold component="span">
                    Prompt User to Upload Email :
                  </ListItemBold>
                  <Typography variant="body2" component="span" display="block">
                    Encourage users to upload all email content using the
                    paperclip icon.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemBold component="span">
                    Run "Upload email content and identify themes":
                  </ListItemBold>
                  <Typography variant="body2" component="span" display="block">
                    Ensure all file_name_urls are accurately included in a
                    Python dictionary format.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemBold component="span">
                    Output Result of "Upload email content and identify themes":
                  </ListItemBold>
                  <Typography variant="body2" component="span" display="block">
                    Ask users to confirm the identified themes.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemBold component="span">
                    Confirm List of Themes:
                  </ListItemBold>
                  <Typography variant="body2" component="span" display="block">
                    Ensure all file_name_urls are accurately included in a
                    Python dictionary format.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemBold component="span">
                    Activate Bulk - run for High - level Analysis:
                  </ListItemBold>
                  <Typography variant="body2" component="span" display="block">
                    Run high - level analysis based on confirmed themes.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemBold component="span">
                    Create Email Dataset:
                  </ListItemBold>
                  <Typography variant="body2" component="span" display="block">
                    Generate a dataset of email utterances using the confirmed
                    themes.{" "}
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemBold component="span">
                    Wait for Dataset Confirmation:
                  </ListItemBold>
                  <Typography variant="body2" component="span" display="block">
                    Prompt users to confirm when the email dataset is ready.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemBold component="span">
                    Perform Category Statistics Calculation:
                  </ListItemBold>
                  <Typography variant="body2" component="span" display="block">
                    Calculate category statistics and provide results in a table
                    format.
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemBold component="span">
                    Initiate Summarization and Quote Extraction :
                  </ListItemBold>
                  <Typography variant="body2" component="span" display="block">
                    Start theme - focused summarization and quote extraction.
                  </Typography>
                </ListItem>
              </List>
            </ContentBody>
          </Content>
        </Container>
      </Wrapper>
      {/* Footer */}
    </>
  );
};

export default AiAgentDetailedView;
