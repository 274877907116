import {
  Box,
  Chip,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
  styled,
} from "@mui/material";

// Do not change the common variable. If changes are needed, modify the in the specific style instead.
const commonBorderVariable = "1px solid #DCDCDC"

const AddAgentContainer = styled(Box)({
  border: commonBorderVariable,
  borderRadius: "4px",
  position: "relative",
});

const HeaderContainer = styled(Box)({
  padding: "10px",
  backgroundColor: "#ECECEC",
  color: "#ECECEC",
});

const AddIconBtn = styled(IconButton)({
  position: "absolute",
  top: "7%",
});

const MainContent = styled(Box)({
  marginTop: "7%",
  marginBottom: "5px",
  padding: "10px ",
  // maxHeight: "20vh",
  // overflowY: "auto",
});

const SubHeading = styled(Typography)({
  fontSize: "18px",
  color: "#868A97",
});

const StyledChip = styled(Chip)({
  borderRadius: "5px",
  padding: "0px",
  height: "20px",
  border: commonBorderVariable,
  cursor: "pointer",
  backgroundColor: "#ffffff",

  "& .MuiChip-label": {
    padding: "0 5px",
    fontSize: "12px",
  },

  "&:hover": {
    backgroundColor: "#6249EF !important",
    borderColor: "#6249EF",
    color: '#ffffff'
  },
});

const StyledRadioBtn = styled(FormControlLabel)({
  color: "#868A97",
  width: "fit-content",

  "& .Mui-checked": {
    color: "#6249EF",
  },

  "& .MuiSvgIcon-root": {
    fontSize: 13,
  },
});

const OptionBox = styled(Box)({
  color: "#868A97",
  fontSize: "10px",
  display: "flex",
  gap: "10px",
  alignItems: "center",
  margin: "2px 0px",
});

const StyledChipAPI = styled(Chip)({
  borderRadius: "3px",
  padding: "0px",
  height: "15px",
  border: commonBorderVariable,
  cursor: "pointer",
  backgroundColor: "white",

  "&:hover": {
    backgroundColor: "blue",
  },

  "& .MuiChip-label": {
    padding: "0 5px",
    color: "#ABABAB",
    fontSize: "10px",
  },

  "& .MuiChip-label:hover": {
    color: "white",
  },
});

const StyledURLInput = styled(TextField)({
  height: "20px",
  background: " #FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: "0px 0px 10px #8248D01A",
  borderRadius: "4px",
  width: "65%",

  "& .MuiInputBase-root": {
    height: "100%",
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px", // Adjust padding as needed
    fontSize: "11px",
    color: "#ABABAB",
  },

  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#C9C9C9",
    },
    "&.Mui-focused fieldset": {
      borderColor: "none",
    },
  },
});

const TitleTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
    backgroundColor: "transparent",
  },
  "& .MuiInputBase-input": {
    fontWeight: "500",
    color: "black",
    fontSize: "20px",
    padding: "0px",
  },
});

const AgentProfileImg = styled('img')({
  width: "50px",
  height: "50px",
  borderRadius: "50%"
});

export {
  AddAgentContainer,
  HeaderContainer,
  AddIconBtn,
  MainContent,
  SubHeading,
  StyledChip,
  StyledRadioBtn,
  OptionBox,
  StyledChipAPI,
  StyledURLInput,
  TitleTextField,
  AgentProfileImg
};
