import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TailSpin } from "react-loader-spinner";

import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Backdrop, Box, Link, TextField, Typography } from "@mui/material";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import HelpIcon from "@mui/icons-material/Help";

import {
  AccountBtn,
  AccountInput,
  ContentBox,
  CustomBox,
  CustomModalBg,
  SubText,
} from "./ProfilePageStyles";
import { ErrorMsg, LoaderContainer } from "../../Common/commonStyles";
import { API } from "../../global";
import { useToast } from "../../utils/ToastContext";

const schema = object().shape({
  email: string()
    .email("Please enter a valid email.")
    .required("Email is a required field.")
    .matches(/^[^\s#[\]()]*\.com$/, "Please enter a valid email."),
  newEmail: string()
    .email("Please enter a valid email.")
    .required("Email is a required field.")
    .matches(/^[^\s#[\]()]*\.com$/, "Please enter a valid email."),
});

const EmailContainer = (props) => {
  const { emailID, viewProfileDetails } = props;
  const { showToast } = useToast();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

  const [openSendOtp, setOpenSendOtp] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);

  const [otp, setOtp] = useState(["", "", "", ""]);
  const [disableVerify, setDisableVerify] = useState(true);

  const [emailType, setEmailType] = useState("verify-email");
  const [newEmailId, setEmailId] = useState(emailID);

  const [verifyEmailLoading, setVerifyEmailLoading] = useState(false);
  const [verifyOtpLoading, setVerifyOtpLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [resendOtpLoading, setResendOtpLoading] = useState(false);

  useEffect(() => {
    setValue("email", newEmailId);
    //eslint-disable-next-line
  }, [newEmailId]);

  useEffect(() => {
    // Check if all otp fields are filled
    setDisableVerify(otp.some((field) => field === ""));
  }, [otp]);

  const handleClose = () => {
    setOpenModal(!openModal);
    setOtp(["", "", "", ""]);
  };

  const handleOpenEmail = () => {
    setOpenEmailModal(!openEmailModal);
    setOtp(["", "", "", ""]);
    setOpenModal(false);
  };

  const handleChange = (e, index) => {
    const { value } = e.target;

    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value !== "" && index < 3) {
        const nextField = document.getElementById(`otp-input-${index + 1}`);
        nextField?.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      const prevField = document.getElementById(`otp-input-${index - 1}`);
      prevField?.focus();
    }
  };

  const onSubmitSendOtp = (e) => {
    const updateBtn = e.target.id;
    if (updateBtn === "update-button") {
      setVerifyEmailLoading(true);
    }

    const Obj = {
      email: newEmailId,
    };
    // setEmailId(newEmailId);
    fetch(`${API}/profile/send-otp-current-email`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(Obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setVerifyEmailLoading(false);
        if (response.success) {
          setEmailType("verify-email");
          setOpenSendOtp(false);
          if (updateBtn === "update-button") {
            handleClose();
          }

          if (updateBtn !== "update-button") {
            showToast("success", "OTP sent successfully");
          }
        } else {
          showToast("error", `${response.error.msg}`);
          setOtp(["", "", "", ""]);
        }
      })
      .catch(() => {
        setVerifyEmailLoading(false);
        showToast("error", "Error occurred please try again");
      });
  };

  const verifyOTP = () => {
    setVerifyOtpLoading(true);

    const Obj = {
      otp: otp.join(""),
      type: emailType,
    };
    setValue("newEmail", "");
    fetch(`${API}/profile/verify-email-with-otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(Obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setVerifyOtpLoading(false);
        if (response.success) {
          handleOpenEmail();

          if (emailType === "update-email") {
            showToast("success", "Email updated successfully");

            setOpenEmailModal(false);
            viewProfileDetails();
          }
        } else {
          showToast("error", `${response.error.msg}`);
        }
      })
      .catch(() => {
        setVerifyOtpLoading(false);
        showToast("error", "Error occurred please try again");
      });
  };

  const resendOTP = () => {
    setResendOtpLoading(true);
    const Obj = {
      email: newEmailId,
    };
    // setEmailId(newEmailId);
    fetch(`${API}/profile/resend-otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(Obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setResendOtpLoading(false);
        if (response.success) {
          setOpenSendOtp(false);
          showToast("success", "OTP sent successfully");
        } else {
          showToast("error", `${response.error.msg}`);
          setOtp(["", "", "", ""]);
        }
      })
      .catch(() => {
        setResendOtpLoading(false);
        showToast("error", "Error occurred please try again");
      });
  };

  const onUpdateEmailSubmit = (data) => {
    if (newEmailId === data.newEmail) {
      showToast(
        "error",
        "The new email address must be different from the existing one, please provide another email address."
      );
      return;
    } else {
      setUpdateLoading(true);
      const Obj = {
        email: data.newEmail,
      };
      setEmailId(data.newEmail);
      fetch(`${API}/profile/update-user-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(Obj),
      })
        .then((res) => res.json())
        .then((response) => {
          setUpdateLoading(false);
          if (response.success) {
            setEmailType("update-email");
            handleClose();
            setOpenEmailModal(false);
          } else {
            showToast("error", `${response.error.msg}`);
          }
        })
        .catch(() => {
          setUpdateLoading(false);
          showToast("error", "Error occurred please try again");
        });
    }
  };

  const inputDisabled = true;

  return (
    <CustomBox>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h6">Email</Typography>
        <SubText>
          Change the email address associated with your account. We'll send a
          verification link.
        </SubText>
      </Box>

      <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
        <SubText>Email</SubText>
        <AccountInput
          {...register("email")}
          disabled={inputDisabled}
          defaultValue="johndoe@test.com"
          placeholder="Enter your Email-Id"
          sx={{ color: inputDisabled ? "lightgrey" : "black" }}
        />
        <ErrorMsg variant="p">{errors?.email?.message}</ErrorMsg>
      </Box>
      <AccountBtn
        type="submit"
        variant="outlined"
        id="update-button"
        loading={verifyEmailLoading}
        loadingIndicator="Loading…"
        sx={{
          border: "1px solid #6249EF",
          color: "#6249EF",
          "&:hover": {
            bgcolor: "#6249EF",
          },
        }}
        onClick={() => setOpenSendOtp(!openSendOtp)}
      >
        Update
      </AccountBtn>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openSendOtp}
      >
        <CustomModalBg
          sx={{
            height: "max-content",
            width: { xs: "70%", sm: "50%", md: "30%" },
          }}
        >
          <Box>
            <HelpIcon sx={{ color: "#6249EF", fontSize: 80 }} />
          </Box>
          <ContentBox>
            <Typography
              variant="p"
              sx={{ fontWeight: "500", fontSize: "18px" }}
            >
              Are you sure you want to update the email?
            </Typography>

            <Typography
              variant="div"
              sx={{ color: "#ABABAB", fontSize: "12px" }}
            >
              we'll send the OTP to the existing email address.
            </Typography>
          </ContentBox>

          <Box sx={{ width: "100%", display: "flex", gap: "5px" }}>
            <AccountBtn
              sx={{
                border: "1px solid grey",
                color: "grey",
                width: "50%",
                "&:hover": {
                  bgcolor: "grey",
                },
              }}
              onClick={() => setOpenSendOtp(!openSendOtp)}
            >
              No
            </AccountBtn>
            <AccountBtn
              type="submit"
              variant="outlined"
              id="update-button"
              loading={verifyEmailLoading}
              loadingIndicator="Loading…"
              sx={{
                width: "50%",
                border: "1px solid #6249EF",
                color: "#6249EF",
                "&:hover": {
                  bgcolor: "#6249EF",
                },
              }}
              onClick={onSubmitSendOtp}
            >
              Yes
            </AccountBtn>
          </Box>
        </CustomModalBg>
      </Backdrop>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openModal}
      >
        <CustomModalBg
          sx={{
            height: { xs: "50%", sm: "60%" },
            width: { xs: "70%", sm: "50%", md: "30%" },
          }}
        >
          {resendOtpLoading ? (
            <LoaderContainer data-testid="loader-container">
              <TailSpin color="#6249EF" />
            </LoaderContainer>
          ) : (
            <>
              <Box>
                <MarkEmailReadIcon sx={{ color: "#6249EF", fontSize: 80 }} />
              </Box>
              <ContentBox>
                <Typography
                  variant="p"
                  sx={{ fontWeight: "500", fontSize: "18px" }}
                >
                  Please check your email
                </Typography>
                <Typography
                  variant="p"
                  sx={{ color: "#ABABAB", fontSize: "12px" }}
                >
                  We've sent a code to {newEmailId}
                </Typography>
              </ContentBox>
              <Box sx={{ marginBlock: "20px" }}>
                <Box sx={{ display: "flex", gap: "5px" }}>
                  {otp?.map((_, index) => (
                    <TextField
                      sx={{ width: "45px" }}
                      key={index}
                      id={`otp-input-${index}`}
                      value={otp[index]}
                      onChange={(e) => handleChange(e, index)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      inputProps={{
                        maxLength: 1,
                        style: { textAlign: "center" },
                      }}
                      variant="outlined"
                    />
                  ))}
                </Box>

                <Typography
                  variant="p"
                  sx={{ fontSize: "12px", color: "#ABABAB" }}
                >
                  Didn't get the code ?{" "}
                  <Link
                    component="button"
                    id="re-send-otp"
                    underline="always"
                    sx={{ color: "blue" }}
                    onClick={resendOTP}
                  >
                    Click to resend
                  </Link>
                </Typography>
              </Box>
              <Box sx={{ width: "100%", display: "flex", gap: "5px" }}>
                <AccountBtn
                  sx={{
                    border: "1px solid grey",
                    color: "grey",
                    width: "50%",
                    "&:hover": {
                      bgcolor: "grey",
                    },
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </AccountBtn>
                <AccountBtn
                  variant="outlined"
                  loading={verifyOtpLoading}
                  loadingIndicator="Loading…"
                  disabled={disableVerify}
                  sx={{
                    border: disableVerify
                      ? "1px solid lightgrey"
                      : "1px solid #6249EF",
                    color: disableVerify ? "grey" : "#6249EF",
                    width: "50%",
                    "&:hover": {
                      bgcolor: disableVerify ? "inherit" : "#6249EF",
                    },
                  }}
                  onClick={verifyOTP}
                >
                  Verify
                </AccountBtn>
              </Box>
            </>
          )}
        </CustomModalBg>
      </Backdrop>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openEmailModal}
      >
        <CustomModalBg
          sx={{
            width: { xs: "70%", sm: "50%", md: "30%" },
            height: "fit-content",
          }}
        >
          <Typography variant="h5">Email verified successfully</Typography>
          <Typography variant="h6">Please enter new email</Typography>
          <form
            onSubmit={handleSubmit(onUpdateEmailSubmit)}
            style={{ width: "100%" }}
          >
            <Box sx={{ marginBlock: "10px" }}>
              <AccountInput
                {...register("newEmail")}
                placeholder="Enter your Email-Id"
              />
              <ErrorMsg variant="p">{errors?.newEmail?.message}</ErrorMsg>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "5px",
              }}
            >
              <AccountBtn
                sx={{
                  border: "1px solid grey",
                  color: "grey",
                  width: "50%",
                  "&:hover": {
                    bgcolor: "grey",
                  },
                }}
                onClick={handleOpenEmail}
              >
                Cancel
              </AccountBtn>
              <AccountBtn
                type="submit"
                variant="outlined"
                loading={updateLoading}
                loadingIndicator="Loading…"
                sx={{
                  border: "1px solid #6249EF",
                  color: "#6249EF",
                  width: "50%",
                  "&:hover": {
                    bgcolor: "#6249EF",
                  },
                }}
                // onClick={handleOpenEmail}
              >
                Verify
              </AccountBtn>
            </Box>
          </form>
        </CustomModalBg>
      </Backdrop>
    </CustomBox>
  );
};

export default EmailContainer;
