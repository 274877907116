/*
 * File: UserAgentSettings.jsx
 */

import React, { useEffect, useState } from "react";
import { Box, Divider } from "@mui/material";

import { LoaderContainer, MainContainer } from "../../../Common/commonStyles";
import Header from "../../../Reusable/CommonHeader/Header";
import { MainAccountContainer } from "../../ProfilePage/ProfilePageStyles";
import { API } from "../../../global";
import DestinationContainer from "./DestinationContainer";
import SourceContainer from "./SourceContainer";
import Loader from "../../../Reusable/Loader/Loader";
import { useNavigate } from "react-router-dom";

const UserAgentSettings = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [destinationData, setDestinationData] = useState(null);
  const [sourceData, setSourceData] = useState(null);

  useEffect(() => {
    fetchDetails();
    //eslint-disable-next-line
  }, []);

  const fetchDetails = () => {
    setLoading(true);
    viewSettingsDetails();
  };

  const viewSettingsDetails = () => {
    fetch(`${API}/agent/get-user-agent-setting-details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ agentId: localStorage.getItem("agentId") }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.success) {
          setDestinationData(res.result.destinationSettings);
          setSourceData(res.result.sourceSettings);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onBack = ()=>{
    navigate(-1); // Go back to the previous page
  }

  return (
    <MainContainer>
      <Header title="Agent Settings" showBackBtn={true} onBack={onBack}/>
      <Divider sx={{ margin: "0 10px" }} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {loading ? (
          <LoaderContainer data-testid="loader-container">
            <Loader />
          </LoaderContainer>
        ) : (
          <MainAccountContainer>
            <SourceContainer sourceData={sourceData} />
            <DestinationContainer destinationData={destinationData} />
          </MainAccountContainer>
        )}
      </Box>
    </MainContainer>
  );
};

export default UserAgentSettings;
