/*
 * File: SourceContainer.jsx
 */

import React, { useEffect, useState } from "react";
import { AccountBtn, CustomBox } from "../../ProfilePage/ProfilePageStyles";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { API } from "../../../global";
import { useToast } from "../../../utils/ToastContext";

const SourceContainer = (props) => {
  const [sourceType, setSourceType] = useState("file");
  const [fileLocation, setFileLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();

  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      sourceType: "file",
    },
  });

  // const schema = yup.object().shape({
  //   fileLocationType: yup
  //     .string()
  //     .required("File location is a required field")

  // });

  useEffect(() => {
    if (props?.sourceData) {
      const sourceData = props?.sourceData?.source[0];
      setValue(
        "sourceType",
        props?.sourceData?.sourceType ? props?.sourceData?.sourceType : "file"
      );
      setValue("fileLocationType", sourceData?.fileLocation);
      setSourceType(
        props?.sourceData?.sourceType ? props?.sourceData?.sourceType : "file"
      );
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFileLocation(watch("fileLocationType"));

    // eslint-disable-next-line
  }, [watch("fileLocationType")]);

  const onSubmit = (data) => {
    setLoading(true);
    const sourceDetailsData = {
      sourceType: data.sourceType,
      agentId: localStorage.getItem("agentId"),
      sourceDetails: {
        fileLocation: data.fileLocationType,
      },
    };
    fetch(`${API}/agent/add-source-details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(sourceDetailsData),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);

        if (response.success) {
          showToast("success", "Source details updated successfully");
        } else {
          showToast("error", `${response.error.msg}`);
        }
      });
  };

  return (
    <>
      <CustomBox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <form style={{ width: "95%" }} onSubmit={handleSubmit(onSubmit)}>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 2, width: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <Typography sx={{ fontWeight: "bold" }}>Source</Typography>
              <FormControl sx={{ mt: 1 }} fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Source Type
                </InputLabel>
                <Select
                  value={sourceType}
                  label="Source Type"
                  {...register("sourceType")}
                  defaultValue="file"
                  fullWidth
                  sx={{ width: "100%" }}
                >
                  <MenuItem value={"file"}>File</MenuItem>
                  <MenuItem value={"DB"} disabled>
                    DB
                  </MenuItem>
                  <MenuItem value={"API"} disabled>
                    API
                  </MenuItem>
                </Select>
              </FormControl>
              {watch("sourceType") === "file" && (
                <FormControl sx={{ mt: 1 }} fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    File Location Type
                  </InputLabel>
                  <Select
                    value={fileLocation}
                    label="File Location Type"
                    {...register("fileLocationType")}
                    fullWidth
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value={"fileUrl"}>File URL</MenuItem>
                    <MenuItem value={"ftpDetails"}>FTP Details</MenuItem>
                    <MenuItem value={"s3Details"}>S3 Details</MenuItem>
                    <MenuItem value={"azureStorageDetails"}>
                      Azure Storage Details
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </Box>
            {watch("sourceType") === "DB" && (
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 2, width: "300px" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-basic"
                  label="Host Name"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  {...register("hostName")}
                />
                <TextField
                  id="standard-basic"
                  label="Port Address"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  {...register("portAddress")}
                />
                <TextField
                  id="standard-basic"
                  label="Database Name"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  {...register("dataBaseName")}
                />
                <TextField
                  id="standard-basic"
                  label="User Name"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  {...register("userName")}
                />
                <TextField
                  id="standard-basic"
                  label="Password"
                  variant="outlined"
                  size="small"
                  type="password"
                  autoComplete="off"
                  {...register("password")}
                />
              </Box>
            )}
            <AccountBtn
              type="submit"
              loading={loading}
              loadingIndicator="Loading…"
              variant="outlined"
              sx={{
                border: "1px solid blue",
                color: "blue",
                "&:hover": {
                  bgcolor: "blue",
                },
                marginLeft: "15px",
              }}
            >
              Update
            </AccountBtn>
          </form>
        </Box>
      </CustomBox>
    </>
  );
};

export default SourceContainer;
