import React from "react";

import Modal from "@mui/material/Modal";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";

import {
  StyledModal,
  ModalTopBg,
  ModalContent,
  ModalIcon,
  CloseIcon,
  ModalPlayIcon,
  VideoWrapper,
  ModalIconWrapper,
  ModalHeading,
  ModalDescription,
  ModalInfo,
} from "./PlayDemoModalStyles";
import { modalCloseIcon, videoPlayIcon } from "../../../Constants/Constant";
import { Avatar } from "@mui/material";

const PlayDemoModal = (props) => {
  const { open, handleModalClose, data } = props;

  function stringAvatar() {
    return {
      sx: {
        background:
          "transparent linear-gradient(210deg, #8248D0 0%, #E86444 100%) 0% 0% no-repeat padding-box",
        color: "white",
        width: "50px",
        height: "50px",
        fontSize: "25px",
      },
      children: data?.title ? (
        data?.title?.split(" ")[0][0]
      ) : (
        <PersonOutlinedIcon sx={{ fontSize: "30px" }} />
      ),
    };
  }

  console.log(data.videoSrc);

  return (
    <Modal open={open} onClose={handleModalClose}>
      <StyledModal>
        <ModalTopBg
          sx={{
            backgroundColor: data.hoverClr,
          }}
        >
          <ModalIcon>
            {data.imgSrc ? (
              <img src={data.imgSrc} alt="img" width="56" height="56" />
            ) : (
              <Avatar {...stringAvatar()} />
            )}
          </ModalIcon>

          <CloseIcon
            src={modalCloseIcon}
            alt="icon"
            onClick={handleModalClose}
          />
        </ModalTopBg>
        <ModalContent>
          <ModalInfo>
            <ModalHeading>{data.title}</ModalHeading>
            <ModalDescription>{data.status}</ModalDescription>
          </ModalInfo>

          {data.videoSrc ? (
            <VideoWrapper>
              <video width="100%" controls style={{ borderRadius: "10px" }}>
                <source src={data.videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </VideoWrapper>
          ) : (
            <ModalIconWrapper>
              <ModalPlayIcon src={videoPlayIcon} alt="play" />
            </ModalIconWrapper>
          )}
        </ModalContent>
      </StyledModal>
    </Modal>
  );
};

export default PlayDemoModal;
