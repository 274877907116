import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";

import {
  SourceTargetContainer,
  MappingBox,
  HeadingContainer,
  MappingRow,
  DotsContainer,
  Dot,
  MainContainer,
  Heading,
  Field,
  HeadingTarget,
  TargetField,
  CustomEditIcon,
  CustomInput,
  CustomButton,
  ButtonContainer,
  TargetFieldRow,
} from "./DataMappingStyles";
import { BasicFlexBox } from "../../../Common/commonStyles";
import { API } from "../../../global";
import { useToast } from "../../../utils/ToastContext";

const DataMapping = (props) => {
  const mapping = props.mappingData.dataMappingProps;
  const { mappingData, fileUrl } = mapping;

  const [editableField, setEditableField] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [parsedMappingData, setParsedMappingData] = useState([]);
  const { showToast } = useToast();

  useEffect(() => {
    parseMappingData(mappingData);
    //eslint-disable-next-line
  }, [mappingData]);

  const parseMappingData = (data) => {
    try {
      if (data && data.length !== 0) {
        const parsedData = JSON.parse(data);
        const formattedData = parsedData.map((item) => {
          const newItem = {};
          Object.keys(item).forEach((key) => {
            const newKey = key.replace(/ /g, "_");
            newItem[newKey] = item[key];
          });
          return newItem;
        });
        setParsedMappingData(formattedData);
      }
    } catch (error) {
      showToast("error", "Error parsing mapping data")
      console.error("Error parsing mapping data:", error);
    }
  };

  const handleEditClick = (index) => {
    setEditableField(index);
    setEditValue(parsedMappingData[index].Mapped_Column_Names);
  };

  const handleEditChange = (e, index) => {
    setEditValue(e.target.value);
    const newData = [...parsedMappingData];
    newData[index].Mapped_Column_Names = e.target.value;
    setParsedMappingData(newData);
  };

  const handleEditBlur = () => {
    setEditableField(null);
  };

  const handleSaveAgentDataMapping = () => {
    const mappedDataEdited = {
      agentId: localStorage.getItem("agentId"),
      editedMappingData: parsedMappingData,
      userFile: fileUrl,
    };
    fetch(`${API}/agent/agent-data-mapping`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(mappedDataEdited),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          showToast("success", "Data Mapping Response Saved Successfully");
        } else {
          showToast("error", `${response.error.msg}`);
        }
      })
      .catch(() => {
        showToast("error", "Error occurred please try again");
      });
  };

  return (
    <Box>
      <MainContainer>
        <SourceTargetContainer>
          {parsedMappingData.length === 0 ? (
            <BasicFlexBox>
              <Typography color="#ababab" fontSize="14px">
                No mapping data available.
              </Typography>
            </BasicFlexBox>
          ) : (
            <MappingBox>
              <HeadingContainer>
                <Heading>Source Fields</Heading>
                <HeadingTarget>Target Fields</HeadingTarget>
              </HeadingContainer>
              {parsedMappingData.map((obj, index) => (
                <MappingRow key={index}>
                  <Field>{obj.Destination_File_Column_Names}</Field>
                  <DotsContainer>
                    {[...Array(7)].map((_, i) => (
                      <Dot key={i} />
                    ))}
                  </DotsContainer>
                  <TargetField>
                    {editableField === index ? (
                      <CustomInput
                        type="text"
                        value={editValue}
                        onChange={(e) => handleEditChange(e, index)}
                        onBlur={() => handleEditBlur()}
                        autoFocus
                      />
                    ) : (
                      <TargetFieldRow>
                        <span>{obj.Mapped_Column_Names}</span>
                        <CustomEditIcon
                          onClick={() => handleEditClick(index)}
                        />
                      </TargetFieldRow>
                    )}
                  </TargetField>
                </MappingRow>
              ))}
            </MappingBox>
          )}
        </SourceTargetContainer>
        {parsedMappingData.length !== 0 && (
          <ButtonContainer>
            <CustomButton onClick={handleSaveAgentDataMapping}>
              Save
            </CustomButton>
          </ButtonContainer>
        )}
      </MainContainer>
    </Box>
  );
};

export default DataMapping;
