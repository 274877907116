import React, { useState } from "react";

import { Box } from "@mui/material";

import {
  OutterContainer,
  TextContainer,
  TitleContainer,
} from "../../Common/commonStyles";
import { StyledChip } from "./AddAiAgentStyle";

const UserInput = (props) => {
  const { setValue, watch } = props;

  const [selectedInputs, setSelectedInputs] = useState([]);

  const userInputArr = [
    "Text input",
    "Long text input",
    "Options dropdown",
    "Checkbox",
    "JSON",
    "List of JSONs",
    "File to text",
    "File to URL",
    "Text list",
    "Knowledge table",
    "OAuth account",
    "API Key input",
    "Tables",
    "Multiple files to URLs",
    "Numeric inputs",
  ];

  const handleChipClick = (input) => {
    setSelectedInputs((prev) =>
      prev.includes(input) ? prev.filter((i) => i !== input) : [...prev, input]
    );
    setValue("userInputs", [...selectedInputs, input], { shouldValidate: true });
  };

  return (
    <OutterContainer>
      <TitleContainer>User Inputs</TitleContainer>
      <TextContainer>
        Specify the information the user needs to provide for this tool.
      </TextContainer>
      <Box sx={{ marginTop: "10px" }}>
        {userInputArr.map((input, i) => (
          <StyledChip
            label={input}
            variant="outlined"
            onClick={() => handleChipClick(input)}
            sx={{
              margin: "1px",
              backgroundColor: watch("userInputs")?.includes(input)
                ? "#6249EF"
                : "",
              color: watch("userInputs")?.includes(input)
                ? "#ffffff"
                : "#ABABAB",
            }}
          />
        ))}
      </Box>
    </OutterContainer>
  );
};

export default UserInput;
