import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Avatar, Box, Typography } from "@mui/material";

import {
  AccountBtn,
  AccountInput,
  Asterisk,
  CustomBox,
  SubText,
} from "./ProfilePageStyles";

import { ErrorMsg } from "../../Common/commonStyles";

import { API } from "../../global";
import { useToast } from "../../utils/ToastContext";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("First Name is a required field")
    .min(3, "Name must be at least 3 characters.")
    .matches(/^[A-Za-z]+$/, "Name must contain only alphabets."),
  lastName: yup
    .string()
    .test("is-valid-name", "Name must contain only alphabets.", (value) => {
      if (!value) return true;
      return /^[A-Za-z]+$/.test(value);
    }),
});

const NameContainer = (props) => {
  const { firstName, lastName, viewProfileDetails } = props;
  const [btnLoading, setBtnLoading] = useState(false);
  const { showToast } = useToast();

  const {
    register,
    handleSubmit,
    setValue,

    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

  useEffect(() => {
    setValue("firstName", firstName);
    setValue("lastName", lastName);
    //eslint-disable-next-line
  }, []);

  function stringAvatar() {
    return {
      sx: {
        bgcolor: "#6249EF",
        color: "white",
        width: "50px",
        height: "50px",
        fontSize: "25px",
      },
      children: `${firstName?.split(" ")[0][0]}${lastName === "" ? "" : lastName?.split(" ")[0][0]
        }`,
    };
  }

  const onSubmit = (data) => {
    setBtnLoading(true);
    const Obj = {
      firstName: data.firstName,
      lastName: data.lastName,
    };

    fetch(`${API}/profile/update-user-details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(Obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setBtnLoading(false);
        if (response.success) {
          showToast("success", "Name updated successfully");

          viewProfileDetails();
          localStorage.setItem("name", `${data.firstName} ${data.lastName}`);
        } else {
          showToast("error", `${response.error.msg}`);
        }
      })
      .catch(() => {
        setBtnLoading(false);
        showToast("error", "Error occurred please try again");
      });
  };
  return (
    <CustomBox>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h6">Full Name</Typography>
            <SubText>Edit your full name as displayed on your account</SubText>
          </Box>
          <Box>
            <Avatar {...stringAvatar()} />
          </Box>
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
            <SubText>
              First Name <Asterisk>*</Asterisk>
            </SubText>

            <AccountInput
              {...register("firstName")}
              placeholder="Enter your first name"
            />
            <ErrorMsg variant="p">{errors?.firstName?.message}</ErrorMsg>
          </Box>
          <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
            <SubText>
              Last Name
              <Asterisk sx={{ color: "transparent" }}>*</Asterisk>
            </SubText>
            <AccountInput
              {...register("lastName")}
              placeholder="Enter your last name"
            />
            <ErrorMsg variant="p">{errors?.lastName?.message}</ErrorMsg>
          </Box>
        </Box>

        <AccountBtn
          type="submit"
          loading={btnLoading}
          loadingIndicator="Loading…"
          variant="outlined"
          disabled={!isValid}
          sx={{
            border:
              btnLoading || !isValid
                ? "1px solid lightgrey"
                : "1px solid #6249EF",
            color: "#6249EF",
            "&:hover": {
              bgcolor: "#6249EF",
            },
          }}
        >
          Update
        </AccountBtn>
      </form>
    </CustomBox>
  );
};

export default NameContainer;
