import { Box, Button, styled } from "@mui/material";

const StyledModal = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  maxHeight: "80%",
  backgroundColor: "#fff",
  border: "1px solid #ECECEE",
  borderRadius: "8px",
  overflowY: "auto",
});

const ModalTopBg = styled(Box)({
  width: "100%",
  height: "60px",
  borderBottom: "1px solid #ECECEE",
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  paddingInline: "20px",
  boxSizing: "border-box",
});

const CloseIcon = styled("img")({
  width: "14px",
  height: "14px",
  objectFit: "cover",
  cursor: "pointer",
});

const ModalContent = styled(Box)({
  width: "100%",
  padding: "0 20px 20px 20px",
  marginTop: "36px",
  boxSizing: "border-box",
});

const ModalIcon = styled(Box)({
  width: "56px",
  height: "56px",
  position: "absolute",
  left: "20px",
  top: "28px",
});

const ModalInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
}));

const ModalHeading = styled(Box)(({ theme }) => ({
  fontWeight: "500",
  fontSize: "20px",
  color: "#0D162F",
}));

const ModalDescription = styled(Box)(({ theme }) => ({
  fontWeight: "normal",
  fontSize: "14px",
  color: "#868A97",
}));

const ModalIconWrapper = styled(Box)({
  width: "100%",
  aspectRatio: "5 / 2",
  background: "#DCDCDC",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "20px",
  borderRadius: "8px",
  cursor: "pointer",
});

const ModalPlayIcon = styled("img")({
  width: "48px",
  height: "48px",
});

const VideoWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginTop: "30px",
});

const StyledPlayButton = styled(Button)({
  borderRadius: "8px",
  backgroundColor: "#007BFF",
  color: "#FFFFFF",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#0056b3",
  },
});

export {
  ModalHeading,
  ModalDescription,
  StyledPlayButton,
  StyledModal,
  ModalTopBg,
  ModalContent,
  ModalIcon,
  CloseIcon,
  ModalIconWrapper,
  ModalPlayIcon,
  VideoWrapper,
  ModalInfo,
};
