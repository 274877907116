import React, { useState } from "react";

import {
  Box,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  taskActive,
  taskCompleted,
  taskCompletedActive,
  taskFailed,
  taskFailedActive,
  taskInActive,
  taskScheduled,
  taskScheduledActive,
} from "../../Constants/Constant";

import {
  CustomGrid,
  CustomSelect,
  CustomTitle,
  SidebarContainer,
  TaskItemLabel,
} from "./AllTasksStyle";

const taskItems = [
  {
    id: 1,
    name: "All Tasks",
    img: taskInActive,
    imgActive: taskActive,
  },
  {
    id: 2,
    name: "Completed Tasks",
    img: taskCompleted,
    imgActive: taskCompletedActive,
  },
  {
    id: 3,
    name: "Failed Tasks",
    img: taskFailed,
    imgActive: taskFailedActive,
  },
  {
    id: 4,
    name: "Scheduled Tasks",
    img: taskScheduled,
    imgActive: taskScheduledActive,
  },
];

const AllTasksSidebar = ({ isSmallScreen }) => {
  const [activeItem, setActiveItem] = useState(0);
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleItemClick = (index) => {
    setActiveItem(index);
  };

  const handleCopy = (event) => {
    event.preventDefault();
  };
  return (
    <SidebarContainer isSmallScreen={isSmallScreen}>
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "row" : "column",
          gap: "7px",
        }}
        onCopy={handleCopy}
      >
        {taskItems.map((task, index) => {
          return (
            <CustomGrid
              key={index}
              isActive={activeItem === index}
              onClick={() => handleItemClick(index)}
            >
              <Tooltip
                title={isSmallScreen ? task.name : null}
                placement="top"
                arrow
              >
                <IconButton>
                  <img
                    src={activeItem === index ? task.imgActive : task.img}
                    alt="img"
                  />
                </IconButton>
              </Tooltip>

              {isSmallScreen ? null : (
                <TaskItemLabel variant="span">{task.name}</TaskItemLabel>
              )}
            </CustomGrid>
          );
        })}
      </Box>

      <Box>
        {isSmallScreen ? "" : <Divider />}

        <CustomTitle variant="div" isSmallScreen={isSmallScreen}>
          Sort By
        </CustomTitle>
        <FormControl
          sx={{
            m: 0,
            minWidth: 120,
          }}
        >
          <CustomSelect
            value={age}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="" disabled>
              Default Option
            </MenuItem>
            <MenuItem value="Ascending">Ascending</MenuItem>
            <MenuItem value="Descending">Descending</MenuItem>
          </CustomSelect>
        </FormControl>
      </Box>
    </SidebarContainer>
  );
};

export default AllTasksSidebar;
