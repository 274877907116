import { LoadingButton } from '@mui/lab';
import { styled, Box, Button, Typography, IconButton } from '@mui/material';

// Do not change the common variable. If changes are needed, modify the in the specific style instead.
const commonBorder = "1px solid #DCDCDC"

const StyledFlexBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
});

const BasicFlexBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

const BoxContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column"
});

const StyledPlayButton = styled(Button)({
  color: "#868A97",
  textTransform: "capitalize",
  height: "32px",
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  border: commonBorder,
  borderRadius: "4px",
  opacity: 1,
  fontSize: "14px"
});

const TypographyHeadingLabel = styled(Typography)({
  textAlign: "left",
  fontSize: "20px",
  fontWeight: "bold",
  letterSpacing: "0x",
  textTransform: "capitalize",
  opacity: "1"
});

const TypographySubLabel = styled(Typography)({
  textAlign: "left",
  fontSize: "14px",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  lineHeight: "1.5em",
  maxHeight: "4.5em"
});

const ErrorMsg = styled(Typography)({
  color: "red",
  textAlign: "left",
  fontSize: "13px",
  fontWeight: "bold"
});

const MainContainer = styled(Box)({
  width: "100%",
  height: "100%",
  overflowY: "auto"
});

const TextContainer = styled(Typography)({
  fontSize: "10px",
  color: "#868A97",
  marginTop: "10px"
});

const SubContainer = styled(Box)(({ theme }) => ({
  margin: "0 10px",
  padding: "10px 0",
  display: "flex",
  gap: "10px",
  [theme.breakpoints.up("xs")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.up("sm")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  }
}));

const OutterContainer = styled(Box)({
  border: commonBorder,
  borderRadius: "4px",
  padding: "10px"
});

const TitleContainer = styled(Typography)({
  fontWeight: "500",
  fontSize: "14px",
});

const StyledTextArea = styled("textarea")(({ theme }) => ({
  resize: "none",
  width: "100%",
  marginTop: "10px",
  border: commonBorder, 
  borderRadius: "4px",
  fontSize: "12px",
  fontFamily: theme.typography.fontFamily,
  padding: "5px",

  "::placeholder": {
    color: "#DCDCDC",
    fontSize: "10px",
    fontFamily: theme.typography.fontFamily,
  },
  ":focus": {
    outline: "none",
  },
}));

const AuthButton = styled(LoadingButton)({
  textTransform: "capitalize",
  backgroundColor: "#6249EF",
  color: "white",

  "&:hover": {
    backgroundColor: "#4E3BBC",
  },
});

const LoaderContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  width: "100%",
});
const PasswordToggleIcon = styled(IconButton)(() => ({
  position: "absolute",
  right: "12px",
  cursor: "pointer",
  color: "#868A97",
  background: "none",
  border: "none",
}));

const GreyButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#ffffff",
  color: '#868a97',
  border: '1px solid #868a97',
  fontSize: "14px",
  padding: "5px 10px",
  borderRadius: '5px',
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#dcdcdc",
    color: '#868a97',
    border: '1px solid #dcdcdc',
  },
}));

const BlueButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.bluebutton.main,
  color: "white",
  fontSize: "14px",
  padding: "5px 10px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: theme.palette.bluebutton.hover,
  },
  "&:disabled": {
    backgroundColor: theme.palette.bluebutton.disable,
    color: "grey",
  },
}));

export {
  StyledFlexBox,
  BasicFlexBox,
  BoxContainer,
  StyledPlayButton,
  TypographyHeadingLabel,
  TypographySubLabel,
  ErrorMsg,
  TextContainer,
  MainContainer,
  SubContainer,
  OutterContainer,
  TitleContainer,
  StyledTextArea,
  AuthButton,
  LoaderContainer,
  PasswordToggleIcon,
  GreyButton,
  BlueButton
};
