/**
 * @file CustomSnackbar.jsx
 * @description This component renders a customizable toast message.
 * @date [test]
 */

import React, { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { errorIcon, tickGreenIcon } from "../../Constants/Constant";
import { useToast } from "../../utils/ToastContext";

const Alert = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const CustomIcon = ({ severity }) => {
  let iconSrc;
  switch (severity) {
    case "success":
      iconSrc = tickGreenIcon;
      break;
    case "error":
      iconSrc = errorIcon;
      break;
    default:
      iconSrc = null;
  }

  return iconSrc ? (
    <img
      src={iconSrc}
      alt=""
      style={{
        color: severity,
        width: "20px",
        height: "20px",
        margin: "2px",
      }}
    />
  ) : null;
};

const CustomSnackbar = () => {
  const { toast, closeToast } = useToast();
  const { open, severity, message } = toast;

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        closeToast();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [open, closeToast]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    closeToast();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{
        width: "500px",
      }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        icon={<CustomIcon severity={severity} />}
        sx={{
          bgcolor: "white",
          color: "#858585",
          fontWeight: "200",
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
