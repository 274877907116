import { styled, Button, Box, Toolbar, Typography } from "@mui/material";

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  padding: "10px !important",
});

const StyledBox = styled(Box)({
  display: "flex",
  gap: "10px",
});

const StyledNavBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const StyledButton = styled(Button)(({ theme, active }) => ({
  borderBottom: active ? `2px solid blue` : "none",
  backgroundColor: active ? "#ded7fa" : `${theme.palette.button.main}`,
  color: active ? `${theme.palette.bluebutton.main}` : "#ABABAB",
  padding: "5px",
  fontSize: "12px",
  "&:hover": {
    backgroundColor: active ? "#ded7fa" : `${theme.palette.button.main}`,
  },
}));

const ButtonIcon = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.bluebutton.main}`,
  color: ` ${theme.palette.bluebutton.main}`,
  fontSize: "12px",
  padding: "0px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: `${theme.palette.bluebutton.hover}`,
    color: "white",
  },
}));

const ButtonIconContained = styled(Button)(({ theme }) => ({
  backgroundColor: `${theme.palette.bluebutton.main}`,
  color: "white",
  fontSize: "12px",
  padding: "0px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: `${theme.palette.bluebutton.hover}`,
  },
}));

const TextButton = styled(Typography)({
  padding: "0px 10px 0px 0px",
  fontSize: "14px",
  fontWeight: "500"
});

const DrawerHeader = styled(Box)({
  display: "flex",
  alignItems: "center",
});

export {
  StyledToolbar,
  StyledBox,
  StyledNavBox,
  StyledButton,
  ButtonIcon,
  ButtonIconContained,
  TextButton,
  DrawerHeader,
};
