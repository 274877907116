import {
  AppBar,
  Box,
  Button,
  IconButton,
  InputBase,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  alpha,
  styled,
} from "@mui/material";

const CustomTabs = styled(Tabs)({
  backgroundColor: "#F0F0F4",
  width: "100%",
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent", // Custom indicator color
  },
});

const CustomTab = styled(Tab)(({ theme }) => ({
  minWidth: "unset",
  textTransform: "none",
  fontWeight: "bold", // Make text bold
  border: "1px solid #E6E7EA",
  borderRadius: "4px", // Rounded corners
  margin: "10px",
  minHeight: "40px",

  [theme.breakpoints.up("xs")]: {
    margin: "5px",
  },
}));

const SignUpButton = styled(Button)({
  padding: "8px 12px",
  borderRadius: "4px",
  width: "90px",
  height: "32px",
  backgroundColor: "#6249ef",
  color: "#fff",
  fontSize: "14px",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#4e3bbc",
  },
  textTransform: "capitalize",
});

const LoginButton = styled(Button)({
  borderRadius: "4px",
  width: "90px",
  height: "32px",
  backgroundColor: "transparent",
  color: "#0d162f",
  textTransform: "capitalize",
  fontSize: "14px",
  fontWeight: "bold",
});

const NavbarContainer = styled(AppBar)({
  backgroundImage: "linear-gradient(90deg, #e7dbf6, #fbe0da)",
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "56px",
  zIndex: 1000,
});

const ToolbarContainer = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px 20px",
  boxSizing: "border-box",
  width: "100%",
});

const Logo = styled("img")({
  width: "102px",
  height: "32px",
  objectFit: "cover",
});

const SearchBox = styled(Box)(({ theme }) => ({
  position: "relative",
  flexGrow: 1,
  marginLeft: "20px",
  marginRight: "20px",
  display: "flex",
  justifyContent: "center",

  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginLeft: "10px",
    marginRight: "10px",
  },
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
  width: "50%",
  height: "32px",
  padding: "6px 12px",
  paddingRight: "10px",
  border: "none",
  outline: "none",
  borderRadius: "4px",
  boxShadow: "0px 0px 8px #0000001a",
  backgroundColor: alpha(theme.palette.common.white, 1),
  "&::placeholder": {
    color: "#9ca4ba",
  },
}));

const AuthButtons = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

const LandingTypography = styled(Typography)({
  fontSize: { xs: "30px", md: "40px" },
  fontWeight: 700,
  background: "linear-gradient(to right, #8248d0, #e86444)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
  color: "transparent",
});

const LandingSubTypography = styled(Typography)({
  fontSize: { xs: "16px", md: "20px" },
  color: "#868a97",
  marginTop: 2,
  fontWeight: 400,
});

const FooterLogoBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  mb: 2,
  flexDirection: { xs: "column", md: "row" },
});

const TypographyBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  p: 2,
});

const TabBoxStyle = styled(Box)({
  backgroundColor: "#f9f9f9",
  display: "flex",
  flexDirection: "column",
  borderRadius: 1,
  mt: 4,
});

const ArrowButtonStyle = styled(Box)({
  background: "#7eb965",
  borderRadius: "30px",
  textTransform: "capitalize",
  color: "#fff",
  fontWeight: "bold",
});

const LandingPageImg = styled("img")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    height: "25vh",
    width: "90%",
    objectFit: "contain",
  },
  [theme.breakpoints.up("sm")]: {
    width: "90%",
    height: "auto",
    objectFit: "cover",
  },
}));

const SearchXsButton = styled(IconButton)({
  backgroundColor: "white",
  borderRadius: "4px",
  padding: "3px",

  "&:active": {
    backgroundColor: "white", // Ensure the background stays white when clicked
  },
  "&:hover": {
    backgroundColor: "white", // Ensure the background stays white when clicked
  },
});

const SearchContainerXs = styled(Box)({
  backgroundColor: "white",
  width: "100%",
  position: "absolute",
  top: "0%",
  maxHeight: "60%",
  overflowY: "auto",
  overflowX: "hidden",
});

export {
  CustomTab,
  CustomTabs,
  SignUpButton,
  LoginButton,
  MenuButton,
  AuthButtons,
  SearchInput,
  SearchBox,
  Logo,
  ToolbarContainer,
  NavbarContainer,
  LandingTypography,
  LandingSubTypography,
  FooterLogoBox,
  TypographyBox,
  TabBoxStyle,
  ArrowButtonStyle,
  LandingPageImg,
  SearchXsButton,
  SearchContainerXs,
};
