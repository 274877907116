// src/ProtectedRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { getUserRole } from "../../../utils/localStorageUtils";

const ProtectedRoute = ({ element, role }) => {
  const { isAuthenticated } = useAuth();
  const userRole = getUserRole();

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (role && userRole !== role) {
    return <Navigate to="/not-found" />;
  }

  return element;
};

export default ProtectedRoute;

