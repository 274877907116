import { Box, IconButton } from "@mui/material";

import React from "react";
import {
  calendar,
  calendarTime,
  clockGrey,
  status,
  taskID,
  priority,
  result,
  error,
} from "../../Constants/Constant";
import {
  CustomAction,
  CustomBadge,
  CustomCard,
  CustomPriority,
  CustomTD,
  CustomTitle,
} from "./AllTasksStyle";
import AllTasksCommentBox from "./AllTasksCommentBox";

const AllTasksContent = ({ tasks, isSmallScreen }) => {
  console.log(tasks, "tasks");

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: "7px",
      }}
    >
      {tasks.map((task, index) => (
        <CustomCard taskStatus={task.currentStatus}>
          <CustomAction taskStatus={task.currentStatus}>
            <IconButton>
              <img src={task.statusIcon} alt="" />
            </IconButton>
          </CustomAction>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: "7px",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box sx={{ width: "65%" }}>
              <CustomTitle variant="div">{task.title}</CustomTitle>

              <Box
                sx={{
                  display: "flex",
                  gap: "15px",
                  paddingBlock: "7px",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <table
                  style={{
                    width: "35%",
                    fontSize: "11px",
                    color: "#868A97",

                    borderRight: "1px solid #DCDCDC",
                    verticalAlign: "middle", // Vertical alignment
                  }}
                >
                  <tr>
                    <td>
                      <img src={taskID} alt="img" width="12px" />
                    </td>
                    <td>Task ID</td>
                    <CustomTD>{task.id}</CustomTD>
                  </tr>
                  <tr>
                    <td>
                      <img src={status} alt="img" width="12px" />
                    </td>
                    <td>Status</td>
                    <CustomTD>
                      <CustomBadge taskBadge={task.status}>
                        {task.status}
                      </CustomBadge>
                    </CustomTD>
                  </tr>
                  <tr>
                    <td>
                      <img src={calendar} alt="img" width="12px" />
                    </td>
                    <td>Created</td>
                    <CustomTD>{task.created}</CustomTD>
                  </tr>
                </table>
                <table
                  style={{
                    width: "35%",
                    fontSize: "11px",
                    color: "#868A97",
                    borderRight: "1px solid #DCDCDC",
                    verticalAlign: "middle", // Vertical alignment
                  }}
                >
                  <tr>
                    <td>
                      <img src={clockGrey} alt="img" width="12px" />
                    </td>
                    <td>Start Time</td>
                    <CustomTD>{task.startTime}</CustomTD>
                  </tr>
                  <tr>
                    <td>
                      <img src={clockGrey} alt="img" width="12px" />
                    </td>
                    <td>End Time</td>
                    <CustomTD>{task.endTime}</CustomTD>
                  </tr>
                  <tr>
                    <td>
                      <img src={calendarTime} alt="img" width="12px" />
                    </td>
                    <td>Occurrence</td>
                    <CustomTD>{task.occurrence}</CustomTD>
                  </tr>
                </table>
                <table
                  style={{
                    width: "35%",
                    fontSize: "11px",
                    color: "#868A97",
                    verticalAlign: "middle", // Vertical alignment
                  }}
                >
                  <tr>
                    <td>
                      <img src={priority} alt="img" width="12px" />
                    </td>
                    <td>Priority</td>
                    <CustomTD>
                      <CustomPriority taskPriority={task.priority}>
                        {task.priority}
                      </CustomPriority>
                    </CustomTD>
                  </tr>
                  <tr>
                    <td>
                      <img src={result} alt="img" width="12px" />
                    </td>
                    <td>Result</td>
                    <CustomTD>{task.result}</CustomTD>
                  </tr>
                  <tr>
                    <td>
                      <img src={error} alt="img" width="12px" />
                    </td>
                    <td>Error</td>
                    <CustomTD>{task.error}</CustomTD>
                  </tr>
                </table>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", md: "flex-end" },
                width: { xs: "100%", md: "35%" },
                gap: "10px",
                padding: "0px 10px",
              }}
            >
              <AllTasksCommentBox
                comments={task.comments}
                btn={task.btn}
                icon={task.icon}
                isSmallScreen={isSmallScreen}
              />
            </Box>
          </Box>
        </CustomCard>
      ))}
    </Box>
  );
};

export default AllTasksContent;
