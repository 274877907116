import { Stack } from "@mui/material";

import LanguageModel from "../../Reusable/Agents/LanguageModel";
import OutputContainer from "../../Reusable/Agents/OutputContainer";
import GreetingContainer from "../../Reusable/Agents/GreetingContainer";

const CreateAgentMiddle = (props) => {
  const {
    createAgent,
    setValue,
    watch,
    errors,
    setError,
    clearErrors,
    modelFileUploaded,
    setModelFileUploaded,
    selectedModel,
    setSelectedModel,
    setModelFileInput,
    agentCreate
  } = props;

  const languageModelProps = {
    createAgent,
    setValue,
    watch,
    errors,
    setError,
    clearErrors,
    modelFileUploaded,
    setModelFileUploaded,
    selectedModel,
    setModelFileInput,
    setSelectedModel,
    agentCreate,
  };

  return (
    <Stack spacing={1}>
      <LanguageModel {...languageModelProps} />
      <OutputContainer
        createAgent={createAgent}
        setValue={setValue}
        watch={watch} errors={errors}
      />
      <GreetingContainer createAgent={createAgent} errors={errors} />
    </Stack>
  );
};

export default CreateAgentMiddle;
