import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { BoxContainer } from "../../Common/commonStyles";
import { IconButton, Typography } from "@mui/material";
import { uploadImg } from "../../Constants/Constant";

const FileInput = (props) => {
  const { handleFileChange, maxFileSize, disabled } = props;

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    handleFileChange(file);

    // eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
  });

  return (
    <BoxContainer
      sx={{
        border: "1px dashed #C9C9C9",
        height: { xs: "30%", sm: "50%" },
        marginTop: "10px",
        padding: "20px",
        opacity: disabled ? 0.8 : 1,
        pointerEvents: disabled ? "none" : "auto",
      }}
      {...getRootProps()}
    >
      <input
        style={{ display: "none" }}
        type="file"
        onChange={(e) => handleFileChange(e.target.files[0])}
        {...getInputProps()}
      />
      <IconButton onClick={open}>
        <img src={uploadImg} alt="uploadImg" width="30px" />
      </IconButton>
      <Typography
        sx={{
          fontSize: "11px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Drag & drop file or{" "}
        <Typography
          component="span"
          sx={{
            fontSize: "11px",
            fontWeight: "bold",
            color: "blue",
            cursor: "pointer"
          }}
          onClick={open}
        >
          browse file
        </Typography>
      </Typography>
      <Typography sx={{ fontSize: "10px", color: "#C9C9C9" }}>
        Max file size {maxFileSize}
      </Typography>
    </BoxContainer>
  );
};

FileInput.propTypes = {
  handleFileChange: PropTypes.func.isRequired,
  maxFileSize: PropTypes.string.isRequired,
};

export default FileInput;
