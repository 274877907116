import React, { useState } from "react";
import {
  InnerContainer,
  StyledCounterBtn,
  StyledAddIconBtn,
  StyledCounter,
  StartProcessImg,
} from "./CreateAIAgentStyles";
import { StyledButton } from "../../Reusable/CommonHeader/HeaderStyle";
import { Box, Divider, IconButton, Typography } from "@mui/material";

import {
  BasicFlexBox,
  TitleContainer,
  BoxContainer,
  OutterContainer,
  StyledFlexBox,
} from "../../Common/commonStyles";
import { addIconborder, startProcess } from "../../Constants/Constant";

const CreateAgentRight = () => {
  const [activeButton, setActiveButton] = useState("workflow");
  const handleButtonClick = (button) => {
    setActiveButton(button);
  };
  return (
    <OutterContainer sx={{ height: "85%" }}>
      <Box sx={{ display: "flex", justifyContent: "center", gap: "5px" }}>
        <StyledButton
          active={activeButton === "code"}
          onClick={() => handleButtonClick("code")}
        >
          Code
        </StyledButton>
        <StyledButton
          active={activeButton === "workflow"}
          onClick={() => handleButtonClick("workflow")}
        >
          Workflow
        </StyledButton>
      </Box>
      <InnerContainer>
        <StyledFlexBox sx={{ height: "10%", padding: "0px 10px" }}>
          <StyledCounter>
            <StyledCounterBtn variant="text">-</StyledCounterBtn>
            <Typography
              variant="div"
              sx={{ fontSize: "12px", paddingTop: "3px" }}
            >
              {50}%
            </Typography>
            <StyledCounterBtn variant="text">+</StyledCounterBtn>
          </StyledCounter>

          <StyledAddIconBtn>Add</StyledAddIconBtn>
        </StyledFlexBox>
        <BasicFlexBox
          sx={{
            height: "90%",
          }}
        >
          <BoxContainer
            sx={{
              width: "100%",
            }}
          >
            <BasicFlexBox
              sx={{
                width: "100%",
              }}
            >
              <StartProcessImg>
                <img src={startProcess} alt="startProcess" width="25px" />
              </StartProcessImg>
              <Box
                component="span"
                sx={{
                  width: 8,
                  height: 8,
                  bgcolor: "#ABABAB",
                  borderRadius: "50%",
                }}
              />
              <Divider
                sx={{
                  width: "4%",
                  bgcolor: "#ABABAB",
                }}
              />
              <IconButton sx={{ padding: "0px" }}>
                <img src={addIconborder} alt="addIconborder" />
              </IconButton>
            </BasicFlexBox>

            <TitleContainer
              sx={{
                width: "10%",
                textAlign: "center",
                marginRight: "45px",
                marginTop: "5px",
              }}
            >
              Start Process
            </TitleContainer>
          </BoxContainer>
        </BasicFlexBox>
      </InnerContainer>
    </OutterContainer>
  );
};

export default CreateAgentRight;
