import React from "react";
import { Backdrop, Box, Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

import {
  AccountBtn,
  ContentBox,
  CustomModalBg,
} from "../../../components/ProfilePage/ProfilePageStyles";

const ConfirmModal = (props) => {
  const { open, title, message, handleCancel, handleConfirm, loading } = props;

  const onCancelClick = () => {
    handleCancel();
  }

  const onConfirmClick = () => {
    handleConfirm();
  }

  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
    >
      <CustomModalBg
        sx={{
          height: "max-content",
          width: { xs: "70%", sm: "50%", md: "30%" },
        }}
      >
        <Box>
          <HelpIcon sx={{ color: "#6249EF", fontSize: 80 }} />
        </Box>
        <ContentBox>
          <Typography
            variant="p"
            sx={{ fontWeight: "500", fontSize: "18px" }}
          >
            {title}
          </Typography>

          <Typography
            variant="div"
            sx={{ color: "#ABABAB", fontSize: "12px" }}
          >
            {message}
          </Typography>
        </ContentBox>

        <Box sx={{ width: "100%", display: "flex", gap: "5px" }}>
          <AccountBtn
            sx={{
              border: "1px solid grey",
              color: "grey",
              width: "50%",
              "&:hover": {
                bgcolor: "grey",
              },
            }}
            onClick={onCancelClick}
          >
            No
          </AccountBtn>
          <AccountBtn          
            variant="outlined"
            id="update-button"
            loading={loading}
            loadingIndicator="Loading…"
            sx={{
              width: "50%",
              border: "1px solid #6249EF",
              color: "#6249EF",
              "&:hover": {
                bgcolor: "#6249EF",
              },
            }}
            onClick={onConfirmClick}
          >
            Yes
          </AccountBtn>
        </Box>
      </CustomModalBg>
    </Backdrop>
  )
}

export default ConfirmModal
