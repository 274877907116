import { Box, styled } from "@mui/material";

const SubText = styled(Box)({
  fontSize: "15px",
  color: "#ABABAB",
  margin: "0px 7px 0px 0px",
});

const MainText = styled(Box)({
  fontSize: "18px",
  color: "#4d4d4d",
  margin: "7px 0px",
});

export { SubText, MainText };
