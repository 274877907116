/**
 * @file FileUploadCompleteCardStyle.js
 * @description This component renders a customizable file upload.
 * @author "Monika"
 * @date [test]
 */


import { styled } from "@mui/material";

const FileImg = styled('img')({
  height: '50px',
  width: '50px'
});

export {
  FileImg
}