import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#fffff", // your custom primary color
    },
    navbar: {
      main: "#fffff",
    },
    button: {
      main: "#E5E5E5",
    },
    bluebutton: {
      main: "#6249EF",
      hover: "#4E3BBC",
      disable: "#C5C5C5",
    },
    redbtn: {
      main: "red",
    },
    success: {
      main: "#00BB25"
    }
  },
  typography: {
    fontFamily: "Roboto, Arial, sans-serif",
  },
});
