import { LoadingButton } from "@mui/lab";
import { Box, Button, IconButton, styled } from "@mui/material";

const InnerContainer = styled(Box)({
  backgroundColor: "#ECECEC",
  border: "1px solid #ECECEC",
  height: "93%",
  marginTop: "10px",
});

const StyledCounterBtn = styled(Button)({
  minWidth: "10px",
  padding: "0px",
});

const StyledAddIconBtn = styled(Button)({
  border: "1px solid #DCDCDC",
  backgroundColor: "#FFFFFF",
  color: "grey",
  borderRadius: "4px",
  padding: "2px 5px",
  fontSize: "12px",
});

const StyledCounter = styled(Box)({
  border: "1px solid #DCDCDC",
  backgroundColor: "#FFFFFF",
  borderRadius: "4px",
  display: "flex",
  alignContent: "center",
  color: "grey",
  gap: "10px",
  padding: "0px 5px",
});

const StartProcessImg = styled(IconButton)({
  padding: "15px",
  backgroundColor: "white",
  width: "9%",
  height: "15%",
  borderRadius: "10px",
  border: "1px solid #ABABAB",
});

const FileUploadContainer = styled(Box)({
  marginBlock: '10px'
});

const VideoContainer = styled(Box)({
  position: "relative",
  borderRadius: "8px",
  width: "100%",
  paddingTop: "56.25%",
  marginTop: "12px"
});

const VideoPlayer = styled('video')({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  borderRadius: "8px",
});

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  marginTop: "12px",
  gap: "12px"
}));

const OutlinedButton = styled(LoadingButton)(({ theme, btncolor }) => ({
  backgroundColor: "#ffffff",
  color: btncolor,
  fontSize: "12px",
  padding: "5px 10px",
  border: `1px solid ${btncolor}`,
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: btncolor,
    color: "#ffffff"
  },
}));

const UploadedFile = styled(Box)(({ theme }) => ({
  borderRadius: "4px",
  fontSize: "12px",
  color: "#868a97",
  display: "flex",
  justifyContent: "space-between"
}));

const FileDetails = styled(Box)(({ theme }) => ({
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: "0px 0px 10px #6249EF26",
  border: "1px solid #C9C9C9",
  borderRadius: "4px",
  padding: "10px 15px"
}));

const CloseIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingBottom: "10px"
}));

export {
  InnerContainer,
  StyledCounterBtn,
  StyledAddIconBtn,
  StyledCounter,
  StartProcessImg,
  FileUploadContainer,
  VideoContainer,
  VideoPlayer,
  ButtonContainer,
  OutlinedButton,
  UploadedFile,
  FileDetails,
  CloseIcon
};
