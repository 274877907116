import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import PropTypes from "prop-types";
import { Avatar, Box, IconButton, Stack, styled } from "@mui/material";

import {
  gBlack,
  dashboard,
  analytics,
  chat,
  link,
  moon,
  queries,
  settings,
} from "../../Constants/Constant";

// import { useAuth } from "../../components/Authentication/AuthContext/AuthContext";

const StyledBox = styled(Box)({
  background: "linear-gradient(180deg, #E8644433, #8248D033)",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const iconButtons = [
  { src: gBlack, alt: "g", to: "/", includedPath: ["/"] },
  { src: dashboard, alt: "dashboard", to: "/", includedPath: ["/dashboard"] },
  {
    src: chat,
    alt: "chat",
    to: "/ig-ai/onboard-ai-agent",
    includedPath: [
      "/ig-ai/onboard-ai-agent",
      /^\/ig-ai\/employ\/[^/]+\/[^/]+$/,
      "/ig-ai/data-processing",
    ],
  },
  { src: link, alt: "link", to: "/", includedPath: ["/"] },
  { src: analytics, alt: "analytics", to: "/", includedPath: ["/analytics"] },
];

const bottomIconButtons = [
  { src: moon, alt: "moon", to: "/", includedPath: ["/moon"], type: "theme" },
  {
    src: queries,
    alt: "queries",
    to: "/",
    includedPath: ["/queries"],
    type: "faq",
  },
  {
    src: settings,
    alt: "settings",
    to: "/",
    includedPath: ["/settings"],
    type: "setting",
  },
];

const CustomIconButton = ({ src, alt, to, active }) => (
  <IconButton
    component={to ? Link : "div"}
    to={to}
    sx={{
      bgcolor: active ? "#FFFFFF" : "transparent",
      boxShadow: active && "0px 0px 5px #60009733",
      borderRadius: "5px",
      margin: "3px !important",
    }}
  >
    <img src={src} alt={alt} />
  </IconButton>
);

// Define prop types for the component
CustomIconButton.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  to: PropTypes.string,
  active: PropTypes.bool,
};

const Sidebar = (props) => {
  const { firstName, lastName } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/ig-ai/account-settings");
  };

  const isActive = (includedPath) => {
    return includedPath.some((path) =>
      typeof path === "string"
        ? path === location.pathname
        : path instanceof RegExp
        ? path.test(location.pathname)
        : false
    );
  };

  function stringAvatar() {
    let firstNameData = firstName ? firstName?.split(" ")[0][0] : "";
    let lastNameData = lastName ? lastName?.split(" ")[0][0] : "";
    return {
      sx: {
        bgcolor: "#6249EF",
        color: "white",
        width: "30px",
        height: "30px",
        fontSize: "15px",
      },
      children: `${firstNameData}${lastNameData}`,
    };
  }

  return (
    <StyledBox
      sx={{
        display: { xs: "none", sm: "none", md: "flex" },
      }}
    >
      <Stack direction="column" spacing={1}>
        {iconButtons.map((button, index) => (
          <CustomIconButton
            key={button.src}
            {...button}
            active={isActive(button.includedPath)}
            // active={button.includedPath.includes(location.pathname)}
          />
        ))}
      </Stack>
      {/* <div>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={handleClose}>Logout</MenuItem>
        </Menu>
      </div> */}
      <div>
        <Stack direction="column" spacing={1}>
          {bottomIconButtons.map((button, index) => (
            <CustomIconButton
              id="botton-menu"
              key={button.type}
              {...button}
              type={button}
              active={button.includedPath.includes(location.pathname)}
            />
          ))}
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls="account-menu"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <Avatar {...stringAvatar()} />
          </IconButton>
        </Stack>

        {/* <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)} // Open the Menu when anchorEl is set
          onClose={handleClose}
          TransitionComponent={Fade}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>My account</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu> */}
      </div>
    </StyledBox>
  );
};

export default Sidebar;
