import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import PropTypes from "prop-types";
import { Avatar, Grid, Tooltip, useMediaQuery } from "@mui/material";

import { CustomTab, CustomTabs } from "./LandingPageStyle";
import OnboardCards from "../../Reusable/Cards/OnboardCards";
import InitialAppBar from "./InitialAppBar";
import {
  marketingIcon,
  researchIcon,
  allagent,
  salesGraph,
  operations,
  emptyBox,
} from "../../Constants/Constant";
import { API } from "../../global";
import { LoaderContainer } from "../../Common/commonStyles";
import Loader from "../../Reusable/Loader/Loader";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { xs: 1, sm: 3 } }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const TabArr = [
  {
    label: "All Agents",
    icon: allagent,
  },
  {
    label: "Sales",
    icon: salesGraph,
  },
  { label: "Research", icon: researchIcon },
  { label: "Marketing", icon: marketingIcon },
  { label: "Operations", icon: operations },
];

export default function FullWidthTabs() {
  const navigate = useNavigate();

  const [value] = useState(0);
  const [cardDetails, setCardDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    handleCardDetails(null);
    //eslint-disable-next-line
  }, []);

  const onAction = () => {
    navigate("/login");
  };

  const viewDetails = (data) => {
    navigate(`/agent-details/${data.id}`);
  };

  const handleCardDetails = (searchKey) => {
    setLoading(true);
    fetch(`${API}/agent/list-landing-page-agents`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ searchKey }),
      // authorization: `Bearer ${token}`,
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        const updatedAgents = res.result.agents.map((agent, index) => {
          return {
            id: agent._id,
            title: agent.agent_name,
            status: agent.agent_description,
            imgSrc: agent.profile_image,
            videoSrc: agent.demo_video,
            hoverClr: "#FF6E471A",
          };
        });
        setCardDetails(updatedAgents);
      });
  };

  const onSearchAgents = (keyData) => {
    handleCardDetails(keyData);
  };

  return (
    <>
      <InitialAppBar onSearch={onSearchAgents} />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {loading ? (
          <LoaderContainer
            sx={{ height: "60vh" }}
            data-testid="loader-container"
          >
            <Loader />
          </LoaderContainer>
        ) : (
          <Box
            sx={{
              maxWidth: { md: "1200px", xl: "1300px" },
              backgroundColor: "#ffff",
            }}
          >
            <CustomTabs
              // sx={{ backgroundColor: "red" }}
              value={value}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
            >
              {TabArr.map((tab, i) => (
                <Tooltip
                  key={tab.label}
                  title={isSmallScreen ? tab.label : null}
                  placement="top"
                  arrow
                >
                  <CustomTab
                    key={tab.label}
                    label={isSmallScreen ? null : tab.label}
                    iconPosition="start"
                    icon={
                      <Avatar
                        alt="Profile Picture"
                        src={tab.icon}
                        sx={{
                          width: 17.5,
                          height: 15.5,
                          color: "#FFFFFF",
                          borderRadius: 0,
                        }}
                      />
                    }
                    {...a11yProps(i)}
                    sx={{
                      backgroundColor: value === i ? "#6249EF" : "#FFFFFF", // Conditional background color
                      color: value === i ? "#fff" : "#868A97",
                    }}
                  />
                </Tooltip>
              ))}
            </CustomTabs>

            <TabPanel value={value} index={0} dir={theme.direction}>
              <Grid
                container
                sx={{
                  columnGap: 2,
                  rowGap: 2,
                  flexWrap: "wrap",
                  justifyContent: {
                    xs: "center",
                    md: "center",
                    lg: "flex-start",
                  },
                }}
              >
                {cardDetails.length !== 0 ? (
                  <>
                    {cardDetails?.map((card) => (
                      <OnboardCards
                        key={card._id}
                        data={card}
                        viewDetails={viewDetails}
                        onAction={onAction}
                        onboard={true}
                        type={"landingPage"}
                      />
                    ))}
                  </>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    // height="100vh"
                    width="100%"
                    textAlign="center"
                  >
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold" }}
                      color="textSecondary"
                    >
                      No matches found. Please adjust your search criteria and
                      try again.
                    </Typography>

                    <img src={emptyBox} alt="No Data" style={{ width: 120 }} />
                  </Box>
                )}
              </Grid>
            </TabPanel>
          </Box>
        )}
      </Box>
    </>
  );
}
