import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LoginText } from "./RegisterStyles";
import {
  FormContainer,
  LogoImage,
  HeaderTypography,
  FormFieldsWrapper,
  CustomInput,
  PasswordInputWrapper,
  AccountText,
  LogoImgContainer,
} from "../Login/LoginStyles";
import {
  AuthButton,
  ErrorMsg,
  PasswordToggleIcon,
} from "../../../Common/commonStyles";
import AuthLayout from "../../Layout/AuthLayout/AuthLayout";
import { ignithoLogo } from "../../../Constants/Constant";
import { API } from "../../../global";
import { useToast } from "../../../utils/ToastContext";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required("First name is a required field")
    .min(3, "First name must be at least 3 characters."),
  lastName: yup.string(),
  email: yup
    .string()
    .email("Please enter a valid email.")
    .required("Email is a required field.")
    .matches(/^[^\s#[\]()]*\.com$/, "Please enter a valid email. "),
  password: yup
    .string()
    .required("Password is a required field.")
    .min(8, "Password must be at least 8 characters.")
    .max(20, "Password must be at most 20 characters.")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter.")
    .matches(/\d/, "Password must contain at least one numeric digit.")
    .matches(
      /[@$!%*?&]/,
      "Password must contain at least one special character."
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match.")
    .required("Password is a required field."),
});

const Register = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { showToast } = useToast();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

  const onSubmit = (data) => {
    setLoading(true);
    const Obj = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
    };
    console.log(Obj);

    //integrate API here
    fetch(`${API}/auth/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        if (response.success) {
          navigate("/success");
        } else {
          showToast("error", `${response.error.msg}`);
        }
      })
      .catch(() => {
        setLoading(false);
        showToast("error", "Error occurred please try again");
      });
  };

  return (
    <AuthLayout>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <LogoImgContainer>
          <LogoImage src={ignithoLogo} alt="logo" />
        </LogoImgContainer>
        <HeaderTypography>Let's Get Started</HeaderTypography>
        <FormFieldsWrapper>
          <CustomInput
            {...register("firstName")}
            type="text"
            placeholder="Enter First Name"
          />
          <ErrorMsg variant="p">{errors?.firstName?.message}</ErrorMsg>
          <CustomInput
            {...register("lastName")}
            type="text"
            placeholder="Enter Last Name"
          />
          <ErrorMsg variant="p">{errors?.lastName?.message}</ErrorMsg>
          <CustomInput
            {...register("email")}
            type="email"
            placeholder="Enter Email Address"
          />
          <ErrorMsg variant="p">{errors?.email?.message}</ErrorMsg>
          <PasswordInputWrapper>
            <CustomInput
              {...register("password")}
              type={showPassword ? "text" : "password"}
              placeholder="Enter Password"
              onCopy={(e) => e.preventDefault()}
              onPaste={(e) => e.preventDefault()}
              onCut={(e) => e.preventDefault()}
            />
            <PasswordToggleIcon onClick={togglePasswordVisibility}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </PasswordToggleIcon>
          </PasswordInputWrapper>
          <ErrorMsg variant="p">{errors?.password?.message}</ErrorMsg>
          <PasswordInputWrapper>
            <CustomInput
              {...register("confirmPassword")}
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Re-enter Password"
              onCopy={(e) => e.preventDefault()}
              onPaste={(e) => e.preventDefault()}
              onCut={(e) => e.preventDefault()}
            />
            <PasswordToggleIcon onClick={toggleConfirmPasswordVisibility}>
              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
            </PasswordToggleIcon>
          </PasswordInputWrapper>
          <ErrorMsg variant="p">{errors?.confirmPassword?.message}</ErrorMsg>

          <AuthButton
            type="submit"
            disabled={!isValid}
            loading={loading}
            loadingIndicator="Loading…"
            variant="contained"
          >
            <span>Sign Up</span>
          </AuthButton>
        </FormFieldsWrapper>
        <AccountText>
          Already have an account?{" "}
          <LoginText>
            <Link to="/login">Login</Link>
          </LoginText>
        </AccountText>
      </FormContainer>
    </AuthLayout>
  );
};

export default Register;
