import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import DOMPurify from "dompurify";

import {
  ChooseFile,
  CustomText,
  ProgressBox,
  TextTitle,
} from "../../components/DataQualityCheckAgentChat/DataQualityCheckStyles";
import { StyledFlexBox } from "../../Common/commonStyles";
import FileInput from "../FileInput/FileInput";
import {
  blackCloseIcon,
  excelImg,
  greyCloseIcon,
  profile,
  tickGreenIcon,
} from "../../Constants/Constant";
import { useNavigate } from "react-router-dom";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const AvatarBox = React.memo(
  (props) => {
    const {
      data,
      fileName,
      fileSize,
      selectOption,
      selectedType,
      saveUserMessage,
      setStatusProgress,
      setTerminalMessages,
      setIntermediateMessage,
      setCurrentTask,
      setFileName,
      setFileUploaded,
      setStatusList,
      setSelectedOptions
    } = props;
    const [isUser] = useState(false);
    const [progress, setProgress] = useState(0);
    const navigate = useNavigate();

    const handleFileInputChange = (e) => {
      props.handleFileChange(e);
    };

    useEffect(() => {
      if (props.progress === "uploaded") {
        setProgress(100);
      }
    }, [props.progress]);

    const selectedOption = (option) => {
      setSelectedOptions(option.text)
      saveUserMessage(option.text)
      if (option.text === "Enter Details" || option.text === 'Enter again' || option.text === 'Provide Details Now') {
        navigate("/ig-ai/user-agent-settings");
      } else if (option.text === "continue") {
        navigate("/ig-ai/data-processing");
      } else if (option.text === 'Not now') {
        selectOption("not_now");
      } else if (option.text === 'Cancel') {
        selectOption("cancel");
      }else{
        selectOption("cancel");
      }
    };

    const resetFileUpload = () => {
      setFileName("");
      setFileUploaded(false);
      setStatusList([]);
      setCurrentTask("Waiting...");
      setTerminalMessages(["Plan prepared"]);
      setIntermediateMessage("");
      setStatusProgress(0);
    };

  

    return (
      <Box
        key={data.msg}
        sx={{
          display: "flex",
          justifyContent: isUser ? "flex-end" : "flex-start",
          margin: "20px",
        }}
      >
        {data.type === "user" ? (
          <Avatar src={profile} alt="User Avatar" sx={{ width: 35, height: 35 }}>
            M
          </Avatar>
        ) : (
          <Avatar sx={{ bgcolor: "grey.500", mr: 1 }}>B</Avatar>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <Typography variant="subtitle2" sx={{ color: "#868A97" }}>
            {data.type === "user" ? "You" : "Agent"}
          </Typography>
          <CustomText
            variant="body1"
            sx={{
              color: "#868A97",
              fontSize: "14px",
              margin: "5px",
            }}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.msg) }}
          ></CustomText>
          <Box sx={{ ml: 2 }}>
            {data?.destinationData && (
              <>
                <CustomText>
                  Host Name : {data?.destinationData.host}
                </CustomText>
                <CustomText>User Name : {data?.destinationData.user_name}</CustomText>
                <CustomText>Port Address : {data?.destinationData.port}</CustomText>
                <CustomText>Database Name : {data?.destinationData.database_name}</CustomText>
              </>
            )}
          </Box>


          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            {data?.options?.map((op) => (
              <Button
                disabled={op.selected}
                key={op}
                onClick={() => selectedOption(op)}
                sx={{
                  background: op.selected === 'selected' ? "#6249EF" : "transparent",
                  border: "1px solid #868A97",
                  height: "32px",
                  margin: "5px",
                  color: op.selected === 'selected' ? "white": "#868A97",
                  textTransform: "capitalize",
                }}
              >
                {op.text}
              </Button>
            ))}
          </Box>
          {selectedType && (
            <>
              {fileName ? (
                <ChooseFile
                  sx={{
                    width: { xs: "70%", sm: "80%", md: "60%" },
                    padding: "5px",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <IconButton
                      p={0}
                      sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}
                    >
                      <img src={excelImg} alt="excelImg" />
                    </IconButton>
                    <Box sx={{ width: "100%" }}>
                      <StyledFlexBox>
                        <TextTitle
                          sx={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            display: { xs: "none", sm: "flex" },
                          }}
                        >
                          Upload Completed
                        </TextTitle>
                        <IconButton
                          onClick={() => resetFileUpload()}
                          sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}
                        >
                          <img src={greyCloseIcon} alt="greyCloseIcon" />
                        </IconButton>
                      </StyledFlexBox>
                      <Box sx={{ display: "flex" }}>
                        <IconButton
                          p={0}
                          sx={{ display: { xs: "flex", sm: "none", md: "flex" } }}
                        >
                          <img src={tickGreenIcon} alt="tickGreenIcon" />
                        </IconButton>
                        <StyledFlexBox
                          sx={{
                            width: "100%",
                            gap: "10px",
                          }}
                        >
                          <Typography
                            variant="span"
                            sx={{ color: "#868A97", fontSize: "9px" }}
                          >
                            {fileName}
                          </Typography>
                          <Typography
                            variant="span"
                            sx={{
                              color: "#868A97",
                              fontSize: "9px",
                              display: { xs: "none", sm: "none", md: "flex" },
                            }}
                          >
                            size:{fileSize}kb
                          </Typography>
                          <Typography
                            variant="span"
                            sx={{
                              color: "#868A97",
                              fontSize: "9px",
                              display: { xs: "none", sm: "none", md: "flex" },
                            }}
                          >
                            {`${progress}%`}
                          </Typography>
                        </StyledFlexBox>
                      </Box>
                      <ProgressBox>
                        <BorderLinearProgress
                          variant="determinate"
                          value={progress}
                        />
                      </ProgressBox>
                    </Box>
                  </Box>
                </ChooseFile>
              ) : (
                <ChooseFile
                  sx={{
                    width: { xs: "70%", sm: "80%", md: props.height },
                    padding: "5px",
                  }}
                >
                  <StyledFlexBox>
                    <TextTitle sx={{ fontSize: "13px", fontWeight: "bold" }}>
                      Attach File
                    </TextTitle>
                    <IconButton>
                      <img src={blackCloseIcon} alt="blackCloseIcon" />
                    </IconButton>
                  </StyledFlexBox>
                  <FileInput handleFileChange={handleFileInputChange}
                    imgSrc={excelImg} maxFileSize="10MB"
                  />
                </ChooseFile>
              )}
            </>
          )}
        </Box>
      </Box>
    );
  }
)


AvatarBox.propTypes = {
  data: PropTypes.object,
  fileName: PropTypes.string.isRequired,
  fileSize: PropTypes.number.isRequired,
  setFileName: PropTypes.func.isRequired,
  selectOption: PropTypes.array.isRequired,
  selectedType: PropTypes.string.isRequired,
  setFileUploaded: PropTypes.func.isRequired,
};

export default AvatarBox;
