import React, { useRef } from "react";

import {
  AddAgentContainer,
  AddIconBtn,
  AgentProfileImg,
  HeaderContainer,
  MainContent,
  SubHeading,
  TitleTextField,
} from "./AddAiAgentStyle";
import { StyledTextArea, TextContainer } from "../../Common/commonStyles";

const AddAiAgent = (props) => {
  const { Img, createAgent, setImageFile, showSnackbar, uploadedImg, setUploadedImg } = props;
  const fileInputRef = useRef(null);

  const allowedFileTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "image/bmp",
    "image/webp",
    "image/tiff",
    "image/svg+xml"
  ];

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!allowedFileTypes.includes(file.type)) {
        const allowedFileTypesString = allowedFileTypes.map(type => type.split('/')[1].toUpperCase()).join(', ');
        showSnackbar("error", `Invalid file type. Please upload an image file. Accepted types are: ${allowedFileTypesString}.`);
      } else if (file.size > 409600) {
        showSnackbar("error", "File size exceeds 400 KB. Please upload a smaller file.");
      } else {
        setImageFile(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          setUploadedImg(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      console.error("No file selected");
      showSnackbar("error", "No file selected");
    }
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  return (
    <AddAgentContainer>
      <HeaderContainer>
        <>heading</>
      </HeaderContainer>
      <AddIconBtn onClick={handleIconClick}>
        {uploadedImg ? (
          <AgentProfileImg src={uploadedImg} alt="uploadedIcon" />
        ) : (
          <img src={Img} alt="addIcon" width="50px" />
        )}
      </AddIconBtn>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept="image/*"
      />
      <MainContent>
        <SubHeading>
          <TitleTextField
            id="title-input"
            placeholder="Enter Agent Name"
            variant="outlined"
            {...createAgent("agentName")}
          />
        </SubHeading>
        <TextContainer variant="div">
          <StyledTextArea
            sx={{
              border: "none",
              padding: "0 5px 0 0",
              margin: "0px",
              color: "#868A97",
              fontSize: "11px",
              "&::placeholder": {
                fontSize: "11px",
                color: "#ABABAB",
              },
            }}
            id="description-input"
            rows={5}
            placeholder="Enter Description"
            {...createAgent("description")}
          />
        </TextContainer>
      </MainContent>
    </AddAgentContainer>
  );
};

export default AddAiAgent;
