import React from "react";

import {
  IconButton,
} from "@mui/material";

import {
  ChatBox,
  TextTitle,
} from "./DataQualityCheckStyles";
import {
  expandIcon,
} from "../../Constants/Constant";
import { StyledFlexBox } from "../../Common/commonStyles";
import AvatarBox from "../../Reusable/ChatBox/AvatarBox";



const messageList = {
  msg: `Certainly! I'll analyze the data, validate it, and then proceed with automapping. Let's get started by uploading file.`,
  type: "Api",
  options: [],
};



const DataValidation = (props) => {
  const validationData = props.dataValidationProps;
  const {
    fileSize,
    fileName,
    handleFileChange,
    setFileName,
    setFileUploaded,
    setStatusList,
    setProgress,
    setTerminalMessages,
    setIntermediateMessage,
    setCurrentTask
  } = validationData;

  return (
    <ChatBox
      sx={{
        width: { xs: "100%", sm: "50%" },
        height: { xs: "max-content", sm: "100%" },
      }}
    >
      <StyledFlexBox>
        <TextTitle variant="div">Data Validation & Mapping</TextTitle>
        <IconButton>
          <img src={expandIcon} alt="expandIcon" />
        </IconButton>
      </StyledFlexBox>
      <AvatarBox data={messageList} selectedType={true} handleFileChange={handleFileChange}
        fileName={fileName} fileSize={fileSize} progress={props.progress} setStatusProgress={setProgress}
        setTerminalMessages={setTerminalMessages} setIntermediateMessage={setIntermediateMessage}
        setCurrentTask={setCurrentTask} setFileName={setFileName}
        setFileUploaded={setFileUploaded} setStatusList={setStatusList} />
    </ChatBox>
  );
};

export default DataValidation;
