import {
  styled,
  Box,
  TextField,
  Typography,
  Tab,
  Stepper,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Select,
  MenuItem,
  Tabs,
  tabsClasses,
} from "@mui/material";

const MainBox = styled(Box)({
  height: "85%",
  display: "flex",
  padding: "10px 24px",
});

const StyledBox = styled(Box)({
  border: "1px solid #E6DBF6",
  borderRadius: "5px",
});

const StyledInnerBox = styled(Box)({
  height: "80%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "5px",
  color: "#868A97",
  padding: "10px",
});

const HeadingBox = styled(Box)({
  height: "10%",
  display: "flex",
  alignItems: "center",
  paddingLeft: "20px",
});

const CustomTextField = styled(TextField)({
  height: "40px",
  background: " #FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: "0px 0px 10px #8248D01A",
  borderRadius: "4px",

  "& .MuiInputBase-root": {
    height: "100%",
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px", // Adjust padding as needed
  },

  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#C9C9C9",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#C9C9C9",
    },
  },
});

const HeaderBox = styled(Box)({
  display: "flex",
  gap: "10px",
  width: "100%",
});

const InnerBox = styled(Box)({
  flexGrow: 1,
  height: "100%",
  border: "1px solid #D0DAE7",
  borderRadius: "5px",
});

const TitleBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background:
    "transparent linear-gradient(90deg, #FBE0DA 0%, #E6DBF6 100%) 0% 0% no-repeat padding-box",
});

const TextTitle = styled(Typography)({
  fontWeight: "500",
  fontSize: "17px",
  paddingLeft: "10px",
});

const ContentBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "0px",
});

const ProgressBox = styled(Box)({
  border: "1px solid #E5E5E5",

  //   padding: "5px",
  borderRadius: "5px",
  width: "100%",

  //   backgroundColor: "red",
});

const TitleLabel = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "700",
  color: "#0D162F",
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

const ChatBox = styled(Box)({
  //   display: "flex",
  //   justifyContent: "space-between",
  //   flexDirection: "column",
  backgroundColor: "#fdf8fb",
  border: "1px solid #E6DBF6",
  borderRadius: "5px",
});

const ChooseFile = styled(Box)({
  background: " #FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: " 0px 0px 10px #6249EF26",
  border: " 1px solid #C9C9C9",
  borderRadius: " 4px",  
});

const StyledTab = styled(Tab)({
  textTransform: "capitalize",
  fontSize: "16px",
  fontWeight: 600,
  color: "#868A97",
  "&.Mui-selected": {
    color: "#6249EF",
    borderBottom: "3px solid #6249EF",
  },
});

const StyledStepper = styled(Stepper)(() => ({
  padding: "10px 0",

  "& .MuiStepLabel-root": {
    padding: 0,
  },
  "& .MuiStepLabel-iconContainer": {
    padding: 0,
  },
  "& .MuiStepConnector-line": {
    borderLeft: "1px solid #bdbdbd",
    marginLeft: "-7px",
  },

  "& .MuiIconButton-root": {
    padding: 0,
  },
}));

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#DCDDDF",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#00BB25",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderLeftWidth: 3,
    borderRadius: 1,
    borderColor: "#DCDDDF",
    minHeight: 24,
  },
}));

const CustomStepLabel = styled(StepLabel)(({ theme, progress, step }) => ({
  [`& .${stepConnectorClasses.alternativeLabel}`]: {
    marginTop: 0,
  },
  "& .MuiStepLabel-label": {
    color: "#868A97",
    fontWeight: "500",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F1F1F1",
    border: "1px solid #DCDDDF",
    padding: "5px",
    borderRadius: "4px",
    fontSize: "12px",
    marginLeft: "10px",
    "&.Mui-completed": {
      color: "#868A97",
    },
    "&.Mui-active": {
      color: "#868A97",
    },
    // "&.Mui-disabled": {
    //   color: theme.palette.text.disabled,
    // },

    // ...(loading && {
    //   color: "#FFAA00",
    //   border: "1px solid #FFAA00",
    // }),
  },
}));

const CustomTabs = styled(Tabs)({
  maxWidth: "400px", // Set a maximum width for the Tabs component
  "& .MuiTab-root": {
    textTransform: "none", // Disable uppercase transformation
    fontSize: "12px", // Custom font size
    padding: "0px 0px", // Custom padding
    minHeight: "25px",
  },

  [`& .${tabsClasses.scrollButtons}`]: {
    height: "30px",
    width: "30px",
    "&.Mui-disabled": { opacity: 0.3 },
    "&:first-of-type": {
      left: 0, // Custom placement for the first scroll button
    },
    "&:last-of-type": {
      right: 0, // Custom placement for the last scroll button
    },
  },
});

const CustomTableContainer = styled(Box)({
  height: "200px",
  borderRadius: "5px",
  border: "1px solid #DDDEE0",
  overflowY: "auto",
});

const CustomTable = styled("table")({
  borderCollapse: " collapse",
  fontSize: "12px",
  width: "100%",
  padding: "5px",
});

const CustomTHeader = styled("th")({
  padding: "5px 0px 5px 10px",
  textAlign: "left",
});

const CustomTRow = styled("tr")({
  borderTop: "1px solid #DDDEE0",
  padding: "5px",
});

const CustomTData = styled("td")({
  padding: "5px 0px 5px 10px",
});

const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    width: "80px",
    fontSize: "13px",
    padding: "0 5px 0 5px", // Customize the padding
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#C7C7C7", // Customize the border color
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#C7C7C7", // Customize the border color on hover
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#C7C7C7", // Customize the border color when focused
  },
  "& .MuiSelect-icon": {
    display: "none", // Hide the down arrow icon
  },
}));

const CustomMenuITem = styled(MenuItem)({
  fontSize: "12px",
});

const CustomText = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  color: "#868A97",
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

const TerminalMessage = styled(Box)(({ theme }) => ({
  fontSize: "14px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "16px",
  },
}));

export {
  MainBox,
  StyledBox,
  StyledInnerBox,
  HeadingBox,
  CustomTextField,
  HeaderBox,
  InnerBox,
  TitleBox,
  TextTitle,
  ContentBox,
  ProgressBox,
  TitleLabel,
  ChatBox,
  ChooseFile,
  StyledTab,
  StyledStepper,
  CustomConnector,
  CustomStepLabel,
  CustomTable,
  CustomTHeader,
  CustomTRow,
  CustomTData,
  CustomSelect,
  CustomMenuITem,
  CustomTabs,
  CustomTableContainer,
  CustomText,
  TerminalMessage,
};
