import React from "react";

import {
  Radio,
  RadioGroup,
  FormControl,
  Divider,
  Box,
  Stack,
} from "@mui/material";
import {
  ErrorMsg,
  OutterContainer,
  TextContainer,
  TitleContainer,
} from "../../Common/commonStyles";
import {
  OptionBox,
  StyledChipAPI,
  StyledRadioBtn,
  StyledURLInput,
} from "./AddAiAgentStyle";

const CustomLabel = ({ text }) => {
  return <span style={{ fontSize: "12px" }}>{text}</span>;
};

const OutputContainer = (props) => {
  const { setValue, watch, errors } = props;

  const output = watch("outputType");

  const handleChange = (event) => {
    setValue("outputType", event.target.value, { shouldValidate: true });
  };

  return (
    <>
      <OutterContainer>
        <TitleContainer>Output</TitleContainer>
        <TextContainer>
          This section allows users to select the format in which the AI's
          responses are delivered.
        </TextContainer>
        <FormControl component="fieldset" sx={{ width: "100%" }}>
          <RadioGroup
            aria-label="options"
            name="radio-buttons-group"
            value={watch("outputType")}
            onChange={handleChange}
          >
            <StyledRadioBtn
              value="Send to another agent"
              control={<Radio />}
              label={<CustomLabel text="Send to another agent" />}
            />
            <Divider />
            <StyledRadioBtn
              value="Show it in chat"
              control={<Radio />}
              label={<CustomLabel text="Show it in chat" />}
            />
            <Divider />
            <StyledRadioBtn
              value="JSON"
              control={<Radio />}
              label={<CustomLabel text="JSON" />}
            />
            <Divider />
            <StyledRadioBtn
              value="API"
              control={<Radio />}
              label={<CustomLabel text="API" />}
            />
          </RadioGroup>

          {output === "API" && (
            <Stack>
              <OptionBox>
                Response Format
                <Box>
                  <StyledChipAPI label="JSON" /> <StyledChipAPI label="XML" />
                </Box>
              </OptionBox>
              <OptionBox>
                HTTP Method
                <Box>
                  <StyledChipAPI label="GET" /> <StyledChipAPI label="POST" />
                </Box>
              </OptionBox>
              <OptionBox>
                API Endpoint URL <StyledURLInput />
              </OptionBox>
            </Stack>
          )}
        </FormControl>
      </OutterContainer>
      {errors?.outputType &&
        <ErrorMsg variant="p">{errors.outputType.message}</ErrorMsg>
      }
    </>
  );
};

export default OutputContainer;
