
/*
 * File: CommonProgressBar.jsx
 * Description: This file contains the implementation of the common progressbar component.
 */


import React, { useEffect, useState } from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import { Box, styled } from "@mui/material";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 2,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#C9C9C9",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#00BB25",
  },
}));

const CommonProgressBar = ({ steps, disabled = true }) => {
  const [numBars] = useState(steps); // Default to 4 progress bars
  const [progress, setProgress] = useState(Array(numBars).fill(0));

  useEffect(() => {
    if (!disabled) {
      handleStepper();
    }
  }, [numBars, disabled]); // Depend on numBars to restart the effect when numBars changes

  const handleStepper = () => {
    const intervals = [];
    const currentIndex = 0;

    const startProgress = (index) => {
      intervals[index] = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = [...prevProgress];
          if (newProgress[index] >= 100) {
            clearInterval(intervals[index]);
            if (index < newProgress.length - 1) {
              startProgress(index + 1);
            }
          } else {
            newProgress[index] += 10;
          }
          return newProgress;
        });
      }, 800);
    };

    startProgress(currentIndex);

    return () => {
      intervals.forEach(clearInterval);
    };
  };

  const widthPercentage = `${100 / steps}%`;

  return (
    <>
      <Box sx={{ fontSize: "10px" }}>0 steps of {steps} completed </Box>
      <Box sx={{ display: "flex", gap: "10px" }}>
        {progress.map((item, index) => (
          <Box key={index} sx={{ width: widthPercentage }}>
            <BorderLinearProgress variant="determinate" value={item} />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default CommonProgressBar;
