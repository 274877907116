import React from "react";
import { Box, Typography } from "@mui/material";
import { AccountBtn, CustomBox, SubText } from "./ProfilePageStyles";
import { useAuth } from "../Authentication/AuthContext/AuthContext";

const ManageAccountContainer = () => {
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  let disableDelete = true;
  return (
    <CustomBox>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h6">Manage Account</Typography>
        <SubText>Log out or delete your account securely</SubText>
      </Box>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <AccountBtn
          sx={{
            border: "1px solid #6249EF",
            color: "#6249EF",
            "&:hover": {
              bgcolor: "#6249EF",
            },
          }}
          onClick={handleLogout}
        >
          Log Out
        </AccountBtn>
        <AccountBtn
          disabled={disableDelete}
          sx={{
            border: disableDelete ? "1px solid lightgrey" : "1px solid red",
            color: "red",
            "&:hover": {
              bgcolor: "red",
            },
          }}
        >
          Delete my account
        </AccountBtn>
      </Box>
    </CustomBox>
  );
};

export default ManageAccountContainer;
