import { Box, Card, CardContent, Typography, styled } from "@mui/material";

const OnboardCardBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  borderRadius: "8px",
  height: "45px",
  borderBottom: "1px solid #DCDCDC",
  borderBottomRightRadius: 0, // To remove border radius on the top right
  borderBottomLeftRadius: 0, // To remove border radius on the bottom left
  transition: "background-color 0.3s ease",
});

const OnboardCardStyle = styled(Card)(({ theme }) => ({
  width: "100%", // full width for extra small screens
  // maxWidth: 360, // maximum width for larger screens
  // width: "30%",

  border: "1px solid #DCDCDC",
  position: "relative",
  overflow: "visible",
  borderRadius: "8px",
  height: 215,

  [theme.breakpoints.up("xs")]: {
    width: 300,
  },
  [theme.breakpoints.up("sm")]: {
    width: 330,
  },
  [theme.breakpoints.up("md")]: {
    width: 360,
  },
  [theme.breakpoints.up("lg")]: {
    width: 360,
  },

  [theme.breakpoints.up("xl")]: {
    width: 400,
  },
}));

const OnboardStatusButton = styled(Box)({
  borderRadius: "13px",
  border: "1px solid #00BB25",
  background: "#00BB251A 0% 0% no-repeat padding-box",
  color: "#00BB25",
  textTransform: "capitalize",
  fontSize: "14px",
  height: "26px",
  width: "57px",
  cursor: "pointer",
  textAlign: "center",
  margin: "-10px 0 10px 0",
});

const TruncatedText = styled(Box)(({ theme }) => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  // display: "inline-block",
  maxWidth: "300px", // You can adjust the width as needed
  [theme.breakpoints.up("xs")]: {
    width: 250,
  },
  [theme.breakpoints.up("sm")]: {
    width: 250,
  },
  [theme.breakpoints.up("md")]: {
    width: 300,
  },
  [theme.breakpoints.up("lg")]: {
    width: 230,
  },
}));

const WorkflowImg = styled("img")(({ theme }) => ({
  width: "16px",
  height: "16px",
}));

const CustomCard = styled(CardContent)(({ theme }) => ({
  padding: "10px !important",
}));

const AgentTitle = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: "500",
  letterSpacing: "0x",
  textTransform: "capitalize",
}));

export {
  OnboardCardBox,
  OnboardCardStyle,
  OnboardStatusButton,
  TruncatedText,
  WorkflowImg,
  CustomCard,
  AgentTitle,
};
