import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Box, Divider } from "@mui/material";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BlobServiceClient } from "@azure/storage-blob";
import { v4 as uuid } from 'uuid';

import { MainContainer, SubContainer } from "../../Common/commonStyles";
import Header from "../../Reusable/CommonHeader/Header";
import CreateAgentLeft from "./CreateAgentLeft";
import CreateAgentMiddle from "./CreateAgentMiddle";
import CreateAgentRight from "./CreateAgentRight";
import ConfirmModal from "../../Reusable/Modals/ConfirmModal/ConfirmModal";
import { getUserRole } from "../../utils/localStorageUtils";
import { API } from "../../global";
import { useToast } from "../../utils/ToastContext";

const schema = yup.object().shape({
  agentName: yup
    .string()
    .required("Name is a required field")
    .min(3, "Name must be at least 3 characters."),
  description: yup
    .string()
    .notRequired(),
  profileImg: yup
    .string()
    .url("profile Image must be a valid URL")
    .notRequired(),
  demoVideo: yup
    .string()
    .url("Demo Video must be a valid URL")
    .notRequired(),
  instruction: yup
    .string()
    .notRequired(),
  userInputs: yup
    .array()
    .min(1, "At least one user input type must be selected.")
    .required("User Input is a required field."),
  modelType: yup
    .string()
    .required("Please select a language model"),
  modelName: yup
    .string()
    .notRequired(),
  modelFile: yup
    .string()
    .notRequired(),
  customModelUrl: yup
    .string()
    .notRequired(),
  outputType: yup
    .string()
    .required("Output type is a required field."),
  greetingMessage: yup
    .string()
    .notRequired(),
})

const CreateAIAgent = () => {
  const [videoFileUploaded, setVideoFileUploaded] = useState(false);
  const [modelFileUploaded, setModelFileUploaded] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedModel, setSelectedModel] = useState("");
  const navigate = useNavigate();
  const [uploadedImg, setUploadedImg] = useState("");
  const [uploadedVideo, setUploadedVideo] = useState("");
  const [modelFileInput, setModelFileInput] = useState(null);
  const [demoVideoFileInput, setDemoVideoFileInput] = useState(null);
  const { showToast } = useToast();

  const {
    register: createAgent,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
    reset
  } = useForm({
    resolver: yupResolver(schema), mode: "onSubmit",
    defaultValues: {
      agentName: "",
      description: "",
      profileImg: "",
      demoVideo: "",
      instruction: "",
      userInputs: [],
      modelType: "",
      modelName: "",
      modelFile: "",
      customModelUrl: "",
      greetingMessage: "",
      outputType: ""
    }
  });

  const onSubmit = (data) => {
    if (data.modelType === "Select Model") {
      if (!data.modelName) {
        setError("modelName", {
          type: "manual",
          message: "Select a model name from the dropdown"
        });
      } else {
        handleCreateAgent(data);
      }
    };

    if (data.modelType === "Upload Model") {
      if (!modelFileInput) {
        setError("modelFile", {
          type: "manual",
          message: "Please upload a model file"
        });
      } else {
        handleCreateAgent(data);
      }
    };

    if (data.modelType === "Custom Model URL") {
      if (!data.customModelUrl) {
        setError("customModelUrl", {
          type: "manual",
          message: "Please enter a valid model URL"
        });
      } else {
        handleCreateAgent(data);
      }
    };
  }

  const handleCreateAgent = async (data) => {
    setLoading(true);
    if (imageFile) {
      const imageUrl = await handleUploadFileToBlob(imageFile, data.agentName);
      setValue("profileImg", imageUrl, { shouldValidate: true });
      data.profileImg = imageUrl;
    }
    if (demoVideoFileInput) {
      const demoVideoUrl = await handleUploadFileToBlob(demoVideoFileInput, "");
      setValue("demoVideo", demoVideoUrl, { shouldValidate: true });
      data.demoVideo = demoVideoUrl;
    }
    if (modelFileInput) {
      const modelFileUrl = await handleUploadFileToBlob(modelFileInput, "");
      setValue("modelFile", modelFileUrl, { shouldValidate: true });
      data.modelFile = modelFileUrl;
    }

    const modelType = handleSetModelType(data.modelType);

    const agentDetails = {
      name: data.agentName,
      description: data.description,
      profileImg: data.profileImg,
      demoVideo: data.demoVideo,
      instructions: data.instruction,
      userInputs: data.userInputs,
      modelType: modelType,
      modelFile: data.modelFile,
      customModelUrl: data.customModelUrl,
      modelName: data.modelName,
      outputType: data.outputType,
      greetingMessage: data.greetingMessage
    };

    fetch(`${API}/agent/create-agent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(agentDetails),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        if (response.success) {
          showToast("success", "Agent created successfully");
          navigate("/ig-ai/onboard-ai-agent");
        } else {
          showToast("error", `${response.error.msg}`);
        }
      })
      .catch((err) => {
        setLoading(false);
        showToast("error", "Error occurred please try again");
      });
  }

  const handleSetModelType = (modelType) => {
    switch (modelType) {
      case "Select Model":
        return "model_name";
      case "Upload Model":
        return "model_file";
      case "Custom Model URL":
        return "custom_url";
      default:
        return null;
    }
  };

  const handleUploadFileToBlob = async (file, agentName) => {
    const fileType = file.type;
    const fileExtension = file.name.split('.').pop();

    const sasUrl = localStorage.getItem('sas_url');
    const containerName = localStorage.getItem('userId');
    const blobServiceClient = new BlobServiceClient(sasUrl);
    const containerClient = blobServiceClient.getContainerClient(containerName);

    const uploadOptions = {
      blobHTTPHeaders: {
        blobContentType: fileType
      }
    };

    try {
      const fileName = agentName
        ? `${agentName.toLowerCase().replace(/\s+/g, '')}.${fileExtension}`
        : `${uuid()}.${fileExtension}`;

      const blockBlobClient = containerClient.getBlockBlobClient(fileName);
      await blockBlobClient.uploadData(file, uploadOptions);

      const fullUrl = blockBlobClient.url;
      const baseUrl = fullUrl.split('?')[0];

      return baseUrl;
    } catch (error) {
      showToast("error", "Error uploading file");
      console.error('Error uploading file:', error);
    }
  };

  const onCancel = () => {
    setOpenDialog(true);
  };

  const handleConfirmCancel = () => {
    setOpenDialog(false);
    reset();
    setVideoFileUploaded(false);
    setModelFileUploaded(false);
    setSelectedModel("");
    setUploadedImg("");
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onBack = () => {
    navigate("/ig-ai/onboard-ai-agent");
  }

  const leftProps = {
    createAgent,
    setValue,
    errors,
    videoFileUploaded,
    setVideoFileUploaded,
    setImageFile,
    watch,
    uploadedImg,
    setUploadedImg,
    uploadedVideo,
    setUploadedVideo,
    setDemoVideoFileInput,
    agentCreate: true,
  };

  const middleProps = {
    createAgent,
    setValue,
    errors,
    watch,
    setError,
    clearErrors,
    modelFileUploaded,
    setModelFileUploaded,
    selectedModel,
    setSelectedModel,
    setModelFileInput,
    agentCreate: true,
  };

  return (
    <MainContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Header
          title="Create AI Agent"
          showBackBtn="true"
          showCreateAiBtn="false"
          userRole={getUserRole()}
          formSubmit={true}
          btnName="Save"
          onCancel={onCancel}
          loading={loading}
          onBack={onBack}
        />
        <Divider sx={{ margin: "0 10px" }} />
        <SubContainer>
          <Box sx={{ flex: 1 }}>
            <CreateAgentLeft {...leftProps} />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CreateAgentMiddle {...middleProps} />
          </Box>
          <Box sx={{ flex: 2 }}>
            <CreateAgentRight />
          </Box>
        </SubContainer>
      </form>
      <ConfirmModal
        open={openDialog}
        handleCancel={handleCloseDialog}
        handleConfirm={handleConfirmCancel}
        title="Are you sure you want to cancel ?"
        message="This will reset the form."
      />
    </MainContainer>
  );
};

export default CreateAIAgent;
