import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Avatar, Box, Tooltip } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";

import {
  StyledPlayButton,
  TypographySubLabel,
} from "../../Common/commonStyles";
import {
  AgentTitle,
  CustomCard,
  OnboardCardBox,
  OnboardCardStyle,
  OnboardStatusButton,
  TruncatedText,
  WorkflowImg,
} from "./OnboardCardStyle";
import PlayDemoModal from "../Modals/PlayDemoModal/PlayDemoModal";
import { workflowIcon } from "../../Constants/Constant";
import { API } from "../../global";
import { useToast } from "../../utils/ToastContext";
import { getUserRole } from "../../utils/localStorageUtils";

const OnboardCards = (props) => {
  const navigate = useNavigate();
  const {
    data,
    onAction,
    viewDetails,
    workflow,
    onboard,
    agentTeam,
    onAPISuccess,
    type,
  } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [videoPlay, setVideoPlay] = useState(false);
  const [onBoardLoading, setOnboardLoading] = useState(false);
  const [unSubscribeLoading, setUnSubscribeLoading] = useState(false);
  const subscribe = data.taskStatus === "Open";
  const { showToast } = useToast();
  const userRole = getUserRole();

  // common variable for validation
  const onBoardCardType = "onboard-card";

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setVideoPlay(false);
  };

  const onButtonClick = () => {
    if (props.type === "landingPage") {
      navigate("/login");
    } else {
      if (subscribe) {
        onAction(data);
      } else {
        const Obj = {
          agentId: data?.id,
        };

        setOnboardLoading(true);

        fetch(`${API}/agent/subscribe-agent`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(Obj),
        })
          .then((res) => res.json())
          .then((response) => {
            setOnboardLoading(false);
            if (response.success) {
              onAPISuccess();
              showToast("success", "Subscribed successfully");
            } else {
              showToast("error", `${response.error.msg}`);
            }
          })
          .catch(() => {
            setOnboardLoading(false);
            showToast("error", "Error occurred please try again");
          });
      }
    }
  };

  const handleUnSubscribe = () => {
    const obj = { agentUserId: data.userAgentId };
    setUnSubscribeLoading(true);
    fetch(`${API}/agent/unsubscribe-agent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setUnSubscribeLoading(false);
        if (response.success) {
          onAPISuccess();
          showToast("success", "Unsubscribed successfully");
        } else {
          showToast("error", `${response.error.msg}`);
        }
      })
      .catch(() => {
        setUnSubscribeLoading(false);
        showToast("error", "Error occurred please try again");
      });
  };

  const viewAgentDetails = () => {
    viewDetails(data);
    localStorage.setItem("title", data.title);
    localStorage.setItem("imgSrc", data.imgSrc ? data.imgSrc : " ");
  };

  function stringAvatar() {
    return {
      sx: {
        background:
          "transparent linear-gradient(210deg, #8248D0 0%, #E86444 100%) 0% 0% no-repeat padding-box",
        color: "white",
        width: "50px",
        height: "50px",
        fontSize: "25px",
      },
      children: data?.title ? (
        data?.title?.split(" ")[0][0]
      ) : (
        <PersonOutlinedIcon sx={{ fontSize: "30px" }} />
      ),
    };
  }

  return (
    <OnboardCardStyle
      sx={{
        padding: "0px",
        width:
          type === onBoardCardType
            ? { lg: "280px", xl: "320px" }
            : { lg: "360px", xl: "400px" },
      }}
    >
      <OnboardCardBox
        onClick={userRole && userRole === "ig_ai_admin" ? viewAgentDetails : undefined}
        sx={{
          "&:hover": {
            cursor: userRole && userRole === "ig_ai_admin" ? "pointer" : "default",
            backgroundColor: data.hoverClr,
          },
        }}
      >
        <Box
          sx={{
            width: 56,
            height: 56,
            position: "absolute",
            top: 10,
            left: 10,
          }}
        >
          {data.imgSrc ? (
            <img src={data.imgSrc} alt="img" width="56" height="56" />
          ) : (
            <Avatar {...stringAvatar()} />
          )}
        </Box>
      </OnboardCardBox>
      <CustomCard>
        <Box display="flex" flexDirection="column" gap={1} marginTop={2}>
          <Tooltip title={data.title} placement="top-start" arrow>
            <AgentTitle>
              <TruncatedText>{data.title}</TruncatedText>
            </AgentTitle>
          </Tooltip>

          <Tooltip
            title={subscribe ? "" : data.status}
            placement="top-end"
            arrow
          >
            <TypographySubLabel
              variant="div"
              color="text.secondary"
              sx={{
                lineHeight: type === onBoardCardType ? 1.8 : 1.6,
              }}
            >
              {subscribe ? "Task Status" : data.status}
            </TypographySubLabel>
          </Tooltip>
          {subscribe && (
            <Box>
              <OnboardStatusButton variant="contained" size="small">
                Open
              </OnboardStatusButton>
            </Box>
          )}

          {onboard && (
            <Box
              sx={{
                display: "flex",
                justifyContent: subscribe ? "space-between" : "flex-end",
              }}
            >
              {subscribe && (
                <StyledPlayButton
                  variant="contained"
                  size="medium"
                  // startIcon={<PlayCircleOutlineIcon />}
                  onClick={handleUnSubscribe}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#6249EF1A",
                      border: "1px solid #6249EF",
                      color: "#6249EF",
                    },
                  }}
                >
                  {unSubscribeLoading ? "Loading..." : "Offboard"}
                </StyledPlayButton>
              )}
              <Box display="flex" justifyContent={"end"} gap={1}>
                {type === onBoardCardType ? (
                  <Tooltip title="Play Demo" placement="top" arrow>
                    <StyledPlayButton
                      variant="contained"
                      size="medium"
                      onClick={handleModalOpen}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#00BB251A",
                          border: "1px solid #00BB25",
                          color: "#00BB25",
                        },
                      }}
                    >
                      <PlayCircleOutlineIcon />
                    </StyledPlayButton>
                  </Tooltip>
                ) : (
                  <StyledPlayButton
                    variant="contained"
                    size="medium"
                    startIcon={<PlayCircleOutlineIcon />}
                    onClick={handleModalOpen}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#00BB251A",
                        border: "1px solid #00BB25",
                        color: "#00BB25",
                      },
                    }}
                  >
                    Play Demo
                  </StyledPlayButton>
                )}

                <StyledPlayButton
                  variant="contained"
                  size="medium"
                  onClick={onButtonClick}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#6249EF1A",
                      border: "1px solid #6249EF",
                      color: "#6249EF",
                    },
                  }}
                >
                  {onBoardLoading ? (
                    "Loading...."
                  ) : (
                    <>{subscribe ? "Employ" : "Onboard"}</>
                  )}
                </StyledPlayButton>
              </Box>
            </Box>
          )}

          {agentTeam && (
            <Box display="flex" justifyContent={"end"} gap={1} marginTop={1}>
              <StyledPlayButton
                variant="contained"
                size="medium"
                startIcon={<PlayCircleOutlineIcon />}
                onClick={handleModalOpen}
              >
                Play Demo
              </StyledPlayButton>
              <StyledPlayButton
                variant="contained"
                size="medium"
                onClick={onButtonClick}
              >
                Preview
              </StyledPlayButton>
            </Box>
          )}

          {workflow && (
            <Box display="flex" justifyContent={"end"} gap={1} marginTop={1}>
              <StyledPlayButton
                variant="contained"
                size="medium"
                startIcon={<WorkflowImg src={workflowIcon} alt="workflow" />}
                onClick={handleModalOpen}
              >
                Workflow
              </StyledPlayButton>
              <StyledPlayButton
                variant="contained"
                size="medium"
                onClick={onButtonClick}
              >
                Employ
              </StyledPlayButton>
            </Box>
          )}
        </Box>
      </CustomCard>
      <PlayDemoModal
        data={data}
        open={modalOpen}
        setOpen={setModalOpen}
        handleModalClose={handleModalClose}
        videoPlay={videoPlay}
        setVideoPlay={setVideoPlay}
      />
    </OnboardCardStyle>
  );
};

export default OnboardCards;
