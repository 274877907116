import React from "react";
import AuthLayout from "../../Layout/AuthLayout/AuthLayout";
import { Box, Button, CardContent, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  HeadingText,
  LoginBtn,
  SubHeadingText,
  SuccessCard,
} from "./RegisterStyles";

const SuccessPage = () => {
  const navigate = useNavigate();
  return (
    <AuthLayout>
      <SuccessCard>
        <CardContent>
          <Box className="content">
            <svg
              width="100"
              height="100"
              viewBox="0 0 200 200"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                fill="none"
                stroke="#68E534"
                stroke-width="5"
                cx="100"
                cy="100"
                r="90"
                strokeLinecap="round"
                transform="rotate(-90 100 100)"
                class="circle"
              />
              <polyline
                fill="none"
                stroke="#68E534"
                points="44,114 86.5,142 152,69"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
                class="tick"
              />
            </svg>
          </Box>
          <Stack spacing={1}>
            <HeadingText variant="h3">Congratulations!</HeadingText>
            <SubHeadingText variant="p">
              Your account has been successfully created. Please check your
              email for further instructions and details.
            </SubHeadingText>
            <LoginBtn>
              <Button
                size="small"
                variant="contained"
                color="success"
                sx={{color:"white"}}
                onClick={() => navigate("/login")}
              >
                Back to Login
              </Button>
            </LoginBtn>{" "}
          </Stack>
        </CardContent>
      </SuccessCard>
    </AuthLayout>
  );
};

export default SuccessPage;
