import React, { useEffect, useState } from "react";

import { IconButton, Step, Typography, Box, styled } from "@mui/material";

import {
  CustomConnector,
  CustomStepLabel,
  StyledBox,
  StyledStepper,
  TextTitle,
} from "./DataQualityCheckStyles";
import { BasicFlexBox, StyledFlexBox } from "../../Common/commonStyles";
import PreviewTableData from "./PreviewTableData";
import DataMapping from "./DataMapping/DataMapping";
import {
  expandIcon,
  tickGreenIcon,
  tickGreyIcon,
  greenDownArrow,
  greyDownArrow,
} from "../../Constants/Constant";

const ProgressDetails = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [expandedSteps, setExpandedSteps] = useState([]);

  useEffect(() => {
    if (props.tablePreview.uploadStatus === "uploaded") {
      setActiveStep(1);
      setCompletedSteps([...completedSteps, activeStep]);
    }

    if (props.tablePreview.uploadStatus === "preview") {
      setActiveStep(2);
      setCompletedSteps([...completedSteps, activeStep]);
    }

    //eslint-disable-next-line
  }, [props.tablePreview.uploadStatus]);

  const steps = [
    {
      label: "Source",
      description: "",
    },
    {
      label: "Preview",
      description: <PreviewTableData tableData={props} />,
    },
    {
      label: "Data Mapping",
      description: <DataMapping mappingData={props} />,
    },

  ];

  const handleExpand = (index) => {
    setExpandedSteps((prev) =>
      prev.includes(index)
        ? prev.filter((stepIndex) => stepIndex !== index)
        : [...prev, index]
    );
  };

  const getStepIcon = (label) => {
    const progressMap = {
      "Source": 1,
      "Preview": 22,
      "Data Mapping": 57,
    };

    if (label === "Data Mapping" && props.statusList.includes('Data Mapping') && props.progress >= progressMap[label]) {
      return tickGreenIcon;
    } else if (label === "Data Mapping") {
      return tickGreyIcon;
    } else {
      return props.progress >= progressMap[label] ? tickGreenIcon : tickGreyIcon;
    }
  };

  const getArrowIcon = (label) => {
    const progressMap = {
      "Source": 1,
      "Preview": 22,
      "Data Mapping": 57,
    };

    if (label === "Data Mapping" && props.statusList.includes('Data Mapping') && props.progress >= progressMap[label]) {
      return greenDownArrow;
    } else if (label === "Data Mapping") {
      return greyDownArrow;
    } else {
      return props.progress >= progressMap[label] ? greenDownArrow : greyDownArrow;
    }
  };

  const getStepStyles = (label) => {
    const progressMap = {
      "Source": 1,
      "Preview": 22,
      "Data Mapping": 57,
    };

    if (label === "Data Mapping" && props.statusList.includes('Data Mapping') && props.progress >= progressMap[label]) {
      return {
        backgroundColor: '#00BB2519',
        color: '#00BB25',
        border: '1px solid #00BB25',
      };
    }

    if (label === "Data Mapping") {
      return {
        backgroundColor: '#F1F1F1',
        color: '#868A97',
        border: '1px solid #DCDDDF',
      };
    }

    if (props.progress >= progressMap[label]) {
      return {
        backgroundColor: '#00BB2519',
        color: '#00BB25',
        border: '1px solid #00BB25',
      };
    }
    return {
      backgroundColor: '#F1F1F1',
      color: '#868A97',
      border: '1px solid #DCDDDF',
    };
  };

  const CustomStepLabelStyled = styled(CustomStepLabel)(({ progress, step }) => {
    const styles = getStepStyles(step.label);
    return {
      '& .MuiStepLabel-label': {
        backgroundColor: styles.backgroundColor,
        border: styles.border,
        color: styles.color,
        fontSize: '14px'
      },
    };
  });

  return (
    <StyledBox
      sx={{
        width: { xs: "100%", sm: "50%" },
        height: "100%",
      }}
    >
      <StyledFlexBox sx={{ height: "10%" }}>
        <TextTitle variant="div">Progress Details</TextTitle>
        <IconButton>
          <img src={expandIcon} alt="expandIcon" />
        </IconButton>
      </StyledFlexBox>

      {props.showInitialContent ?
        <BasicFlexBox
          sx={{ height: { sm: "80%" }, color: "#868A97", fontSize: "14px" }}
        >
          Waiting for plan preparation
        </BasicFlexBox>
        :
        <Box
          sx={{
            height: "80%",
            maxHeight: "45vh",
            overflowY: "auto",
            paddingInline: "10px",
          }}
        >
          <StyledStepper
            activeStep={activeStep}
            orientation="vertical"
            connector={<CustomConnector />}
          >
            {steps.map((step, index) => (
              <Step key={index}>
                <CustomStepLabelStyled
                  step={step}
                  StepIconComponent={() => (
                    <IconButton p={0}>
                      <img src={getStepIcon(step.label)} alt="tick" width={12} />
                    </IconButton>
                  )}>
                  {step.label}
                  {step.label !== 'Source' ?
                    <IconButton
                      sx={{ padding: "0px" }}   
                      // disabled={index === 0}
                      onClick={() => handleExpand(index)}
                    >
                      <img
                        src={getArrowIcon(step.label)}
                        alt="arrow"
                        width={12}
                      />
                    </IconButton>
                    : ''
                  }
                </CustomStepLabelStyled>
                {
                  expandedSteps.includes(index) && (
                    <Box
                      sx={{
                        marginLeft: "5px",
                        borderLeft: `3px solid ${completedSteps.includes(index) ? "#00BB25" : "#DCDDDF"
                          }`,
                      }}
                    >
                      <Typography>{step.description}</Typography>
                    </Box>
                  )
                }
              </Step>
            ))}
          </StyledStepper>
        </Box>
      }
    </StyledBox>
  );
};

export default ProgressDetails;
