/**
 * @file AllTaskCommentBox.js
 * @description This component renders a customizable comment box.
 * @author "Pavithiraa"
 * @date [test]
 */

import React, { useState } from "react";
import { Box, Tooltip } from "@mui/material";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { StyledTextArea } from "../../Common/commonStyles";
import {
  CustomBtnText,
  CustomButtonAction,
  CustomLabel,
} from "./AllTasksStyle";

const AllTasksCommentBox = ({ comments, btn, icon, isSmallScreen }) => {
  const [commentValue, setCommentValue] = useState(comments);
  const [onHover, setOnHover] = useState(false);

  const handleOnChange = (e) => {
    if (e.target.value.length > 0) {
      setCommentValue(e.target.value);
    }
  };

  const handleHover = () => {
    setOnHover(!onHover);
  };

  return (
    <>
      <Box sx={{ width: "60%", position: "relative" }}>
        <CustomLabel htmlFor="comment">Comments</CustomLabel>
        <StyledTextArea
          rows={5}
          placeholder="Enter your comments"
          sx={{ color: "#868A97", padding: "5px" }}
          value={commentValue}
          onChange={(e) => handleOnChange(e)}
          disabled={true}
        />
      </Box>
      <Box
        sx={{
          width: "15%",
          display: "flex",
          flexDirection: "column",
          gap: "7px",
        }}
      >
        <Tooltip title={isSmallScreen ? btn : ""} placement="top" arrow>
          <CustomButtonAction
            variant="outlined"
            buttonColor={btn === "Pause" ? "#FA8008" : "#00BB25"}
            onMouseHover={handleHover}
            onMouseLeave={handleHover}
            disabled={true}
          >
            {icon}
            <CustomBtnText isSmallScreen={isSmallScreen}>{btn}</CustomBtnText>
          </CustomButtonAction>
        </Tooltip>
        <Tooltip title={isSmallScreen ? "View" : ""} placement="top" arrow>
          <CustomButtonAction
            onMouseHover={handleHover}
            onMouseLeave={handleHover}
            variant="outlined"
            buttonColor="#6249EF"
            disabled={true}
          >
            <VisibilityOutlinedIcon fontSize="12px" />
            <CustomBtnText isSmallScreen={isSmallScreen}>View</CustomBtnText>
          </CustomButtonAction>
        </Tooltip>
        <Tooltip title={isSmallScreen ? "Edit" : ""} placement="top" arrow>
          <CustomButtonAction
            onMouseHover={handleHover}
            onMouseLeave={handleHover}
            variant="outlined"
            buttonColor="#5593FE"
            disabled={true}
          >
            <CreateOutlinedIcon fontSize="12px" />
            <CustomBtnText isSmallScreen={isSmallScreen}>Edit</CustomBtnText>
          </CustomButtonAction>
        </Tooltip>
        <Tooltip title={isSmallScreen ? "Delete" : ""} placement="top" arrow>
          <CustomButtonAction
            onMouseHover={handleHover}
            onMouseLeave={handleHover}
            variant="outlined"
            buttonColor="#EB0000"
            disabled={true}
          >
            <DeleteOutlinedIcon fontSize="12px" />
            <CustomBtnText isSmallScreen={isSmallScreen}>Delete</CustomBtnText>
          </CustomButtonAction>
        </Tooltip>
      </Box>
    </>
  );
};

export default AllTasksCommentBox;
