import { Box, Button, styled, Typography } from "@mui/material";

const Wrapper = styled(Box)({
  backgroundColor: "#F9F9F9",
  minHeight: "100vh",
  paddingBottom: "30px",
  paddingTop: "20px",
  paddingInline: "20px",
  width: "100%",
});

const Container = styled(Box)(({ theme }) => ({
  maxWidth: "900px",
  margin: "0 auto",
  width: "100%",
  background: "#F9F9F9",
  [theme.breakpoints.up("xl")]: {
    maxWidth: "1280px",
  },
}));

const BackButton = styled(Button)({
  background: "transparent",
  textTransform: "capitalize",
  display: "flex",
  gap: "8px",
  color: "#868A97",
  borderRadius: "4px",
  border: "1px solid #868A97",
  padding: "4px 12px",
  position: "relative",
  "&:hover": {
    background: "#868A97",
    color: "#fff",
  },
  "& .back-default": {
    display: "block",
  },
  "& .back-hover": {
    display: "none",
  },
  "&:hover .back-default": {
    display: "none",
  },
  "&:hover .back-hover": {
    display: "block",
  },
});

const BackImg = styled("img")({
  width: "18px",
  height: "16px",
});

const Content = styled(Box)({
  backgroundColor: "#fff",
  border: "1px solid #ececee",
  borderRadius: "8px",
  marginTop: "20px",
  position: "relative",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const ContentHeader = styled(Box)({
  display: "flex",
  width: "100%",
  height: "120px",
  position: "relative",
  justifyContent: "center",
});

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  justifyContent: "flex-end",
  flexDirection: "column",
  paddingBottom: "12px",
  [theme.breakpoints.up("xs")]: {
    width: "90%",
  },
  [theme.breakpoints.up("sm")]: {
    width: "60%",
  },
}));

const Icon = styled(Box)({
  position: "absolute",
  top: "40%",
  left: "40px",
  width: "11%",
  height: "auto",
  borderRadius: "50%",
});

const OnboardTag = styled(Button)(({ theme }) => ({
  textTransform: "capitalize",
  backgroundColor: theme.palette.bluebutton.main,
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: theme.palette.bluebutton.hover,
  },
  fontSize: "0.875rem",
  fontWeight: "500",
  padding: "4px 12px",
  height: "30px",
}));

const MainHeader = styled(Box)(({ theme }) => ({
  fontSize: "24px",
  fontWeight: "500",
  display: "flex",

  [theme.breakpoints.up("xs")]: {
    fontSize: "18px",
    gap: "40px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
    gap: "20px",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "26px",
  },
}));

const SubHeader = styled(Box)({
  textTransform: "capitalize",
  color: "#868A97",
  fontSize: "18px",
  fontWeight: "400",
});

const ContentBody = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    width: "90%",
    paddingBlock: "10px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "60%",
    paddingBlock: "20px",
  },
}));

const Overview = styled(Box)({
  color: "#868A97",
  fontSize: "1rem",
  fontWeight: "500",
  borderBottom: "1px solid #868A97",
  paddingBottom: "10px",
  width: "fit-content",
});

const OverviewContent = styled(Box)({
  color: "#868A97",
  fontSize: "0.875rem",
  fontWeight: "400",
  paddingBlock: "32px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
});

const OverviewInfo = styled(Typography)({
  color: "#868A97",
  fontSize: "0.875rem",
  fontWeight: "400",
});

const Heading = styled(Box)({
  color: "#868A97",
  fontSize: "1rem",
  fontWeight: "600",
});

const List = styled("ol")({
  color: "#868A97",
  fontSize: "0.875rem",
  fontWeight: "400",
  paddingLeft: "20px",
  counterReset: "item",
  listStyle: "none",
});

const ListItem = styled("li")({
  marginBottom: "10px",
  counterIncrement: "item",
  "&::before": {
    content: 'counter(item) "."',
    fontWeight: "bold",
    marginRight: "8px",
  },
  "& ol": {
    counterReset: "subitem",
  },
});

const ListItemBold = styled(Typography)({
  fontWeight: "600",
  fontSize: "0.875rem",
});

const NestedList = styled("ol")({
  paddingLeft: "20px",
  counterReset: "subitem",
});

const NestedListItem = styled("li")({
  fontWeight: "500",
  counterIncrement: "subitem",
  "&::before": {
    content: 'counter(item) "." counter(subitem) "."',
    marginRight: "8px",
  },
  "&::marker": {
    content: "none",
  },
});

const List1 = styled("ul")({
  color: "#868A97",
  fontSize: "0.875rem",
  listStyle: "none",
  paddingLeft: "0",
});

const ListItem1 = styled("li")({
  position: "relative",
  paddingLeft: "1.2em",
  "&::before": {
    content: '"*"',
    position: "absolute",
    left: "0",
    color: "#868A97",
  },
});

export {
  Wrapper,
  Container,
  Icon,
  OnboardTag,
  Content,
  BackButton,
  BackImg,
  ContentHeader,
  ContentBody,
  Header,
  MainHeader,
  SubHeader,
  Overview,
  OverviewContent,
  OverviewInfo,
  Heading,
  List,
  ListItem,
  List1,
  ListItem1,
  NestedList,
  ListItemBold,
  NestedListItem,
};
