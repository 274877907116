import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { Box, IconButton, Stack, styled } from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import axios from 'axios';

import {
  MainBox,
  HeaderBox,
  InnerBox,
  TitleBox,
  TextTitle,
  ContentBox,
  ProgressBox,
  TitleLabel,
  CustomText,
  TerminalMessage,
} from './DataQualityCheckStyles';
import {
  BasicFlexBox,
  MainContainer,
  StyledFlexBox,
} from '../../Common/commonStyles';
import DataValidation from './DataValidation';
import ProgressDetails from './ProgressDetails';
import Header from '../../Reusable/CommonHeader/Header';
import {
  expandIcon,
  tickGreenIcon,
  tickGreyIcon,
} from '../../Constants/Constant';

import { BlobServiceClient } from '@azure/storage-blob';
import { v4 as uuid } from 'uuid';

import { API } from '../../global';
import { useToast } from '../../utils/ToastContext';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const DataProcessing = () => {
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState(0);
  const [sheetNames, setSheetNames] = useState([]);
  const [sheetData, setSheetData] = useState([]);
  const [sheet, setSheet] = useState(null);
  const [fileType, setFileType] = useState("");
  //csvData
  const [csvHeader, setCsvHeader] = useState([]);
  const [csvColumnData, setCsvColumnData] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("");
  const [statusList, setStatusList] = useState([]);
  const navigate = useNavigate();
  // progress
  const [fileUploaded, setFileUploaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTask, setCurrentTask] = useState('Waiting...');
  const [terminalMessages, setTerminalMessages] = useState(['Plan prepared']);
  const [intermediateMessage, setIntermediateMessage] = useState("");
  const [showInitialContent, setShowInitialContent] = useState(true);

  const [mappingData, setMappingData] = useState([]);
  const [mappingSuccess, setMappingSuccess] = useState(false);
  const [fileUrl, setFileUrl] = useState("");

  const { showToast } = useToast();

  const allowedFileTypes = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowInitialContent(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const onBack = () => {
    const agentName = localStorage.getItem('agentName');
    const agentId = localStorage.getItem('agentId');
    navigate(`/ig-ai/employ/${agentName}/${agentId}`);
  };

  const handleFileChange = async (file) => {
    if (file) {
      if (!allowedFileTypes.includes(file.type)) {
        showToast("error", "Invalid file type. Please upload an excel or a csv file.");
      } else {
        setUploadStatus("uploaded");
        setStatusList(["Source"]);
        setFileName(file.name);
        setFileSize((file.size / 1024).toFixed(2));
        const ft = file.name.split(".").pop().toLowerCase();
        setFileType(ft);
        setFileUploaded(true);
        setProgress(0);
        if (ft === "csv") {
          setIntermediateMessage("Analyzing uploaded csv... [10% Completed]");
        } else {
          setIntermediateMessage(
            "Analyzing uploaded spreadsheet... [10% Completed]"
          );
        }

        if (ft === "csv") {
          Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: async function (results) {
              setProgress(7);
              setIntermediateMessage("Analyzing uploaded csv... [30% Completed]");
              setCurrentTask("Analyzing uploaded csv");

              const rowsArray = [];
              const valuesArray = [];
              results.data.map((d) => rowsArray.push(Object.keys(d)));
              results.data.map((d) => valuesArray.push(Object.values(d)));

              setCsvHeader(rowsArray[0]);
              setCsvColumnData(valuesArray);

              setProgress(12);
              setIntermediateMessage("Analyzing uploaded csv... [60% Completed]");

              setProgress(18);
              setIntermediateMessage("Analyzing uploaded csv... [90% Completed]");

              setTimeout(() => {
                setProgress(22);
                setIntermediateMessage("");
                setTerminalMessages((prevMessages) => [
                  ...prevMessages,
                  "CSV Analysis Completed",
                ]);
                setCurrentTask("Auto mapping...");
              }, 500);
            },
          });
          setStatusList((prevList) => [...prevList, "Data Analysis"]);
        }
        if (ft === "xls" || ft === "xlsx") {
          const data = await file.arrayBuffer();
          const wb = XLSX.read(data);
          setSheetNames(wb.SheetNames);
          setProgress(7);
          setIntermediateMessage(
            "Analyzing uploaded spreadsheet... [30% Completed]"
          );
          setCurrentTask("Analyzing spreadsheet...");

          try {
            let mySheetData = {};

            for (const sheetName of wb.SheetNames) {
              const worksheet = wb.Sheets[sheetName];
              const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                blankrows: "",
                header: 1,
                defval: "",
                cellDates: true, // Enable date parsing
                raw: false,
              });
              mySheetData[sheetName] = jsonData.map((row) => {
                // Convert date string to Date object for all date values
                for (const key in row) {
                  if (
                    Object.prototype.hasOwnProperty.call(row, key) &&
                    row[key] instanceof Date
                  ) {
                    row[key] = new Date(row[key]);
                  }
                }
                return row;
              });
            }
            setSheetData(mySheetData);
            setSheet(Object.keys(mySheetData)[0]);
            setProgress(12);
            setIntermediateMessage(
              "Analyzing uploaded spreadsheet... [60% Completed]"
            );

            setProgress(18);
            setIntermediateMessage(
              "Analyzing uploaded spreadsheet... [90% Completed]"
            );

            setTimeout(() => {
              setProgress(22);
              setIntermediateMessage("");
              setTerminalMessages((prevMessages) => [
                ...prevMessages,
                "Spreadsheet Analysis Completed",
              ]);
              setCurrentTask("Auto mapping...");
            }, 500);
            setStatusList((prevList) => [...prevList, "Data Analysis"]);
          } catch (error) {
            console.error("Error processing the file:", error);
          }
        }
        handleFileUpload(file, ft);
      }
    }
  };

  const handleFileUpload = async (file, ft) => {
    const blobServiceClient = new BlobServiceClient(
      `${localStorage.getItem('sas_url')}`
    );
    const containerName = localStorage.getItem('userId');
    const containerClient = blobServiceClient.getContainerClient(containerName);
    // Upload the file
    const options = {
      blobHTTPHeaders: {
        blobContentType:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    };

    try {
      // Generate a unique filename using UUID
      const fileName = uuid() + "." + ft;
      const blockBlobClient = containerClient.getBlockBlobClient(fileName);
      await blockBlobClient.uploadData(file, options);
      setFileUrl(blockBlobClient.url);
      getMappingData(blockBlobClient.url);
    } catch (error) {
      console.error('Error uploading File:', error);
    }
  };

  const getMappingData = (url) => {
    const destinationData = JSON.parse(localStorage.getItem('destinationData'));
    const dbDetails = {
      userFile: url,
      dbHost: destinationData.host,
      dbUser: destinationData.user_name,
      dbPassword: destinationData.password,
      dbDatabase: destinationData.database_name,
      agentId: localStorage.getItem('agentId'),
      mapper_API: localStorage.getItem('mapperAPI'),
    };

    axios
      .post(`${API}/mapping_chat/get-mapping-data`, dbDetails, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setIntermediateMessage(
            `Auto mapping data... [${percentCompleted}% Completed]`
          );
        },
      })
      .then((response) => {
        if (response.success) {
          setProgress(57);
          setIntermediateMessage("");
          setTerminalMessages((prevMessages) => [
            ...prevMessages,
            'Data Mapping Completed',
          ]);
          setMappingSuccess(true);
          setMappingData(response.result);
          setCurrentTask('Data Validation...');
          setStatusList((prevList) => [...prevList, 'Data Mapping']);
        } else {
          setCurrentTask('Auto mapping failed');
          setIntermediateMessage('Data Mapping Incomplete');
          showToast('error', 'Mapping data is not available');
        }
      })
      .catch((error) => {
        showToast('error', "Error occurred. Please try again.");
      });
  };

  const tablePreview = {
    sheet: sheet,
    setSheet: setSheet,
    sheetNames: sheetNames,
    sheetData: sheetData,
    fileType: fileType,
    csvHeader: csvHeader,
    csvColumnData: csvColumnData,
    uploadStatus: uploadStatus,
    fileUploaded: fileUploaded,
    progress: progress,
  };

  const dataMappingProps = {
    mappingData: mappingData,
    fileUrl: fileUrl,
  };

  const dataValidationProps = {
    handleFileChange: handleFileChange,
    fileName: fileName,
    fileSize: fileSize,
    setFileName: setFileName,
    setSheetNames: setSheetNames,
    setSheet: setSheet,
    setFileUploaded: setFileUploaded,
    setStatusList: setStatusList,
    setProgress: setProgress,
    setTerminalMessages: setTerminalMessages,
    setIntermediateMessage: setIntermediateMessage,
    setCurrentTask: setCurrentTask,
  };

  return (
    <MainContainer>
      <Header
        title={localStorage.getItem('agentName')}
        showBackBtn={true}
        showCenterBtn={true}
        showSettingsBtn={true}
        showCloneBtn={true}
        onBack={onBack}
        buttonTitle='Clone AI Agent'
      />
      <MainBox sx={{ flexDirection: 'column', gap: '10px' }}>
        <HeaderBox
          sx={{
            height: { xs: '100%', sm: '50%', md: '40%' },
            flexDirection: { xs: 'column', sm: 'row', md: 'row' },
          }}
        >
          {/* Planning Box */}
          <InnerBox
            sx={{
              width: { xs: '100%', sm: '30%' },
              height: '100%',
            }}
          >
            <TitleBox sx={{ height: '18%' }}>
              <TextTitle variant="div">Planning</TextTitle>
              <IconButton>
                <img src={expandIcon} alt='expandIcon' />
              </IconButton>
            </TitleBox>
            {showInitialContent ? (
              <BasicFlexBox
                sx={{
                  height: { sm: "80%" },
                  color: "#868A97",
                  fontSize: "14px",
                }}
              >
                Preparing a plan
              </BasicFlexBox>
            ) : (
              <Box
                sx={{
                  margin: "10px",
                  height: "22vh",
                  overflowY: "auto",
                }}
              >
                <ContentBox>
                  <IconButton>
                    <img
                      src={progress >= 1 ? tickGreenIcon : tickGreyIcon}
                      alt='tickIcon'
                      width={12}
                    />
                  </IconButton>
                  <CustomText>Source</CustomText>
                </ContentBox>
                <ContentBox>
                  <IconButton>
                    <img
                      src={progress >= 22 ? tickGreenIcon : tickGreyIcon}
                      alt='tickIcon'
                      width={12}
                    />
                  </IconButton>
                  <CustomText>Preview</CustomText>
                </ContentBox>
                <ContentBox>
                  <IconButton>
                    <img
                      src={
                        progress >= 57 && mappingSuccess
                          ? tickGreenIcon
                          : tickGreyIcon
                      }
                      alt='tickIcon'
                      width={12}
                    />
                  </IconButton>
                  <CustomText>Data Mapping</CustomText>
                </ContentBox>
                {/* <ContentBox>
                  <IconButton>
                    <img src={progress >= 57 ? tickGreenIcon : tickGreyIcon} alt="tickIcon" width={12} />
                  </IconButton>
                  <CustomText sx={{ fontSize: "12px" }}>Data Validation</CustomText>
                </ContentBox>
                 <ContentBox>
                  <IconButton>
                    <img src={tickGreyIcon} alt="tickIcon" width={12} />
                  </IconButton>
                  <CustomText sx={{ fontSize: "12px" }}>Auto mapping</CustomText>
                </ContentBox>
                <ContentBox>
                  <IconButton>
                    <img src={tickGreyIcon} alt="tickIcon" width={12} />
                  </IconButton>
                  <CustomText sx={{ fontSize: "12px" }}>Review</CustomText>
                </ContentBox> 
                <ContentBox>
                  <IconButton>
                    <img src={tickGreyIcon} alt="tickIcon" width={12} />
                  </IconButton>
                  <CustomText sx={{ fontSize: "12px" }}>Destination</CustomText>
                </ContentBox> */}
              </Box>
            )}
          </InnerBox>

          {/* Status Box */}
          <InnerBox sx={{ width: { xs: "100%", sm: "30%" } }}>
            <TitleBox sx={{ height: "18%" }}>
              <TextTitle variant="div">Status</TextTitle>
              <IconButton>
                <img src={expandIcon} alt='expandIcon' />
              </IconButton>
            </TitleBox>

            {showInitialContent ? (
              <BasicFlexBox
                sx={{
                  height: { sm: "80%" },
                  color: "#868A97",
                  fontSize: "14px",
                }}
              >
                Waiting for plan preparation
              </BasicFlexBox>
            ) : (
              <Stack
                spacing={2}
                sx={{
                  padding: "10px",
                  height: "22vh",
                  overflowY: "auto",
                }}
              >
                <Stack>
                  <TitleLabel>Overall</TitleLabel>
                  <ProgressBox>
                    <StyledFlexBox p={1}>
                      <TitleLabel sx={{ color: "#0D162F", fontSize: "12px" }}>
                        In Progress
                      </TitleLabel>
                      <TitleLabel sx={{ color: "#868A97", fontSize: "10px" }}>
                        {progress}%
                      </TitleLabel>
                    </StyledFlexBox>
                    <BorderLinearProgress
                      variant='determinate'
                      value={progress}
                    />
                  </ProgressBox>
                </Stack>
                <Stack>
                  <TitleLabel>Current Task</TitleLabel>
                  <CustomText sx={{ color: "#868A97" }}>
                    {currentTask}
                  </CustomText>
                </Stack>
              </Stack>
            )}
          </InnerBox>

          {/* Terminal Box */}
          <InnerBox sx={{ width: { xs: "100%", sm: "30%" } }}>
            <TitleBox sx={{ height: "18%" }}>
              <TextTitle variant="div">Terminal</TextTitle>
              <IconButton>
                <img src={expandIcon} alt='expandIcon' />
              </IconButton>
            </TitleBox>

            {showInitialContent ? (
              <Box
                sx={{
                  color: "#E76445",
                  fontSize: "14px",
                  padding: "10px",
                  height: "100%",
                }}
              >
                Preparing a plan... [16% Completed]
              </Box>
            ) : (
              <Stack
                sx={{
                  margin: "10px",
                  height: "22vh",
                  overflowY: "auto",
                  color: "#00A981",
                }}
              >
                {terminalMessages.map((msg, index) => (
                  <TerminalMessage
                    key={index}
                    sx={{
                      color: msg.includes('Incomplete')
                        ? "#E76445"
                        : "#00A981",
                    }}
                  >
                    {msg}
                  </TerminalMessage>
                ))}
                {intermediateMessage && (
                  <TerminalMessage style={{ color: "#E76445" }}>
                    {intermediateMessage}
                  </TerminalMessage>
                )}
              </Stack>
            )}
          </InnerBox>
        </HeaderBox>

        <Box
          sx={{
            display: "flex",
            gap: "10px",
            height: { xs: "100%", sm: "50%", md: "70%" },
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <DataValidation
            progress={uploadStatus}
            dataValidationProps={dataValidationProps}
          />
          <ProgressDetails
            tablePreview={tablePreview}
            progress={progress}
            uploadStatus={uploadStatus}
            dataMappingProps={dataMappingProps}
            statusList={statusList}
            showInitialContent={showInitialContent}
          />
        </Box>
      </MainBox>
    </MainContainer>
  );
};

export default DataProcessing;
