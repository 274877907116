import React, { createContext, useState } from "react";

// Create a context
const MyContext = createContext();

const Provider = ({ children }) => {
  const [firstName, setFirstName] = useState("- ");
  const [lastName, setLastName] = useState("-");

  return (
    <MyContext.Provider
      value={{ firstName, setFirstName, lastName, setLastName }}
    >
      {children}
    </MyContext.Provider>
  );
};

export { MyContext, Provider };
