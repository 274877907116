import * as React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

import Sidebar from "../../Reusable/Sidebar/Sidebar";
import { MyContext } from "../../utils/Provider";
import { useEffect } from "react";

const Layout = () => {
  const { firstName, setFirstName, lastName, setLastName } =
    React.useContext(MyContext);

  const loadUserNames = () => {
    const storedFirstName = localStorage.getItem("first_name");
    const storedLastName = localStorage.getItem("last_name");

    if (storedFirstName) {
      setFirstName(storedFirstName);
    }
    if (storedLastName) {
      setLastName(storedLastName);
    }
  };

  useEffect(() => {
    loadUserNames();
    //eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar firstName={firstName} lastName={lastName} />

      {/* Main Content */}
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            width: "100%",
            padding: { xs: "20px 0px", sm: "5px 0", md: "0 0" },
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
