import React from 'react';

import { useNavigate } from 'react-router-dom';

import {
  Box,
  Typography,
  Paper
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { ignithoLogo } from '../../Constants/Constant';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bgcolor="background.default"
      p={3}
    >
      <Paper
        elevation={6}
        sx={{
          padding: { xs: 3, sm: 6 },
          maxWidth: 600,
          textAlign: 'center',
          borderRadius: 2,
          backgroundColor: '#ffffff',
        }}
      >
        <Box
          component="img"
          src={ignithoLogo}
          alt="Website Logo"
          sx={{
            width: '150px',
            display: 'block',
            margin: '0 auto 20px',
          }}
        />
        <ErrorOutlineIcon color="error" sx={{ fontSize: 80 }} />
        <Typography variant="h2" component="h1" color="error">
          404
        </Typography>
        <Typography variant="h5" component="h2" color="textPrimary" gutterBottom>
          Sorry, the page not found.
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          The link you followed may be broken, or the page may have been removed.
        </Typography>
        <Box>
          Return to{' '}
          <Box
            component="span"
            sx={{
              cursor: 'pointer',
              color: '#0052cc',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={handleBack}
          >
            previous page
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default NotFoundPage;
