import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";

import AuthLayout from "../../Layout/AuthLayout/AuthLayout";
import {
  CustomInput,
  ForgotPasswordLink,
  FormContainer,
  FormFieldsWrapper,
  HeaderTypography,
  LogoImage,
  LogoImgContainer,
} from "../Login/LoginStyles";
import { ignithoLogo } from "../../../Constants/Constant";
import { AuthButton, ErrorMsg } from "../../../Common/commonStyles";

import { SubText } from "./ForgotPasswordstyles";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { API } from "../../../global";
import { Box } from "@mui/material";
import { useToast } from "../../../utils/ToastContext";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email.")
    .required("Email is a required field.")
    .matches(/^[^\s#[\]()]*\.com$/, "Please enter a valid email. "),
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [checkEmail, setCheckEmail] = useState(false);
  const [emailId, setEmailId] = useState("");
  const { showToast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

  const onSubmit = (data) => {
    setLoading(true);
    setEmailId(data.email);
    const Obj = {
      email: data.email,
    };

    //integrate API here
    fetch(`${API}/auth/send-reset-link`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(Obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);

        if (response.success) {
          setCheckEmail(true);
        } else {
          showToast("error", `${response.error.msg}`);
        }
      })
      .catch(() => {
        setLoading(false);
        setEmailId("");
        showToast("error", "Error occurred please try again");
      });
  };

  return (
    <AuthLayout>
      {checkEmail ? (
        <FormContainer
          sx={{
            background: "white",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                border: "3px solid #4E3BBC",
                width: "60px",
                borderRadius: "50%",
              }}
            >
              <MarkunreadOutlinedIcon
                sx={{
                  fontSize: "40px",
                  color: "#4E3BBC",
                  marginTop: "5px",
                }}
              />
            </Box>
          </Box>

          <HeaderTypography sx={{ margin: "10px 0" }}>
            Check Your Email
          </HeaderTypography>

          <SubText>
            Please check the email address <b>{emailId}</b> to reset your
            password.
          </SubText>
        </FormContainer>
      ) : (
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          {" "}
          <LogoImgContainer>
            <LogoImage src={ignithoLogo} alt="logo" />
          </LogoImgContainer>
          <HeaderTypography>Reset your password</HeaderTypography>
          <SubText>
            Enter your email address and we will send a link to reset your
            password.
          </SubText>
          <FormFieldsWrapper>
            <CustomInput
              {...register("email")}
              type="email"
              placeholder="Enter Email Address"
            />
            <ErrorMsg variant="p">{errors?.email?.message}</ErrorMsg>
            <AuthButton
              sx={{
                textTransform: "capitalize",
                bgcolor: "#6249EF",
                color: "white",

                "&:hover": {
                  bgcolor: "#4E3BBC",
                },
              }}
              type="submit"
              disabled={!isValid}
              loading={loading}
              loadingIndicator="Loading…"
              variant="contained"
            >
              <span>Continue</span>
            </AuthButton>
            <ForgotPasswordLink onClick={() => navigate("/login")}>
              Back to login
            </ForgotPasswordLink>
          </FormFieldsWrapper>
        </FormContainer>
      )}
    </AuthLayout>
  );
};

export default ForgotPassword;
