import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box } from "@mui/material";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";

import {
  CustomInput,
  FormContainer,
  FormFieldsWrapper,
  HeaderTypography,
  PasswordInputWrapper,
} from "../Login/LoginStyles";
import { MainText, SubText } from "../ForgotPassword/ForgotPasswordstyles";
import AuthLayout from "../../Layout/AuthLayout/AuthLayout";

import {
  AuthButton,
  PasswordToggleIcon,
  ErrorMsg,
} from "../../../Common/commonStyles";
import { API } from "../../../global";
import { useToast } from "../../../utils/ToastContext";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Password is a required field.")
    .min(8, "Password must be at least 8 characters.")
    .max(20, "Password must be at most 20 characters.")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter.")
    .matches(/\d/, "Password must contain at least one numeric digit.")
    .matches(
      /[@$!%*?&]/,
      "Password must contain at least one special character."
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match.")
    .required("Password is a required field."),
});

const PasswordReset = () => {
  const navigate = useNavigate();
  const { uuid, token } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { showToast } = useToast();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const [resetPassword, setResetPassword] = useState(false);

  const SuccessIcon = () => {
    return (
      <svg
        width="60"
        height="60"
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          fill="none"
          stroke="#68E534"
          strokeWidth="8"
          cx="100"
          cy="100"
          r="90"
          strokeLinecap="round"
          transform="rotate(-90 100 100)"
          className="circle"
        />
        <polyline
          fill="none"
          stroke="#68E534"
          points="44,114 86.5,142 152,69"
          strokeWidth="8"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="tick"
        />
      </svg>
    );
  };

  const onSubmit = (data) => {
    setLoading(true);
    const Obj = {
      forget_password_key: uuid,
      password: data.password,
    };
    fetch(`${API}/auth/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(Obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        if (response.success) {
          setResetPassword(true);
        } else {
          showToast("error", `${response.info.msg}`);
        }
      })
      .catch(() => {
        setLoading(false);

        showToast("error", "Error occurred please try again");
      });
  };
  return (
    <AuthLayout>
      {resetPassword ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            background: "white",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            padding: "20px 50px",
            borderRadius: "10px",
            width: "350px",
          }}
        >
          <SuccessIcon />
          <MainText>Password Changed!</MainText>
          <SubText sx={{ textAlign: "center" }}>
            Your password has been changed successfully.
          </SubText>
          <AuthButton
            type="submit"
            variant="contained"
            onClick={() => navigate("/login")}
          >
            <span>Back to Login</span>
          </AuthButton>
        </Box>
      ) : (
        <>
          <FormContainer onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  border: "3px solid #4E3BBC",
                  width: "60px",
                  borderRadius: "50%",
                }}
              >
                <VpnKeyOutlinedIcon
                  sx={{
                    fontSize: "40px",
                    color: "#4E3BBC",
                    marginTop: "5px",
                  }}
                />
              </Box>
            </Box>
            <HeaderTypography sx={{ margin: "10px 0" }}>
              Change Password
            </HeaderTypography>
            <FormFieldsWrapper>
              <PasswordInputWrapper>
                <CustomInput
                  {...register("password")}
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter New Password"
                  onCopy={(e) => e.preventDefault()}
                  onPaste={(e) => e.preventDefault()}
                  onCut={(e) => e.preventDefault()}
                />
                <PasswordToggleIcon onClick={togglePasswordVisibility}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </PasswordToggleIcon>
              </PasswordInputWrapper>
              <ErrorMsg variant="p">{errors?.password?.message}</ErrorMsg>
              <PasswordInputWrapper>
                <CustomInput
                  {...register("confirmPassword")}
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Re-enter Password"
                  onCopy={(e) => e.preventDefault()}
                  onPaste={(e) => e.preventDefault()}
                  onCut={(e) => e.preventDefault()}
                />
                <PasswordToggleIcon onClick={toggleConfirmPasswordVisibility}>
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </PasswordToggleIcon>
              </PasswordInputWrapper>
              <ErrorMsg variant="p">
                {errors?.confirmPassword?.message}
              </ErrorMsg>
              <AuthButton
                type="submit"
                disabled={!isValid}
                loading={loading}
                loadingIndicator="Loading…"
                variant="contained"
              >
                <span>Change</span>
              </AuthButton>
            </FormFieldsWrapper>
          </FormContainer>
        </>
      )}
    </AuthLayout>
  );
};

export default PasswordReset;
