import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { Box, Divider } from "@mui/material";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BlobServiceClient } from "@azure/storage-blob";
import { v4 as uuid } from 'uuid';

import { MainContainer, SubContainer } from "../../Common/commonStyles";
import Header from "../../Reusable/CommonHeader/Header";
import CreateAgentLeft from "../CreateAIAgent/CreateAgentLeft";
import CreateAgentMiddle from "../CreateAIAgent/CreateAgentMiddle";
import CreateAgentRight from "../CreateAIAgent/CreateAgentRight";
import ConfirmModal from "../../Reusable/Modals/ConfirmModal/ConfirmModal";
import { getUserRole } from "../../utils/localStorageUtils";
import { API } from "../../global";
import { useToast } from "../../utils/ToastContext";

const schema = yup.object().shape({
  agentName: yup
    .string()
    .required("Name is a required field")
    .min(3, "Name must be at least 3 characters."),
  description: yup
    .string()
    .notRequired(),
  profileImg: yup
    .string()
    .url("profile Image must be a valid URL")
    .notRequired(),
  demoVideo: yup
    .string()
    .url("Demo Video must be a valid URL")
    .notRequired(),
  instruction: yup
    .string()
    .notRequired(),
  userInputs: yup
    .array()
    .min(1, "At least one user input type must be selected.")
    .required("User Input is a required field."),
  modelType: yup
    .string()
    .required("Please select a language model"),
  modelName: yup
    .string()
    .notRequired(),
  modelFile: yup
    .string()
    .notRequired(),
  customModelUrl: yup
    .string()
    .notRequired(),
  outputType: yup
    .string()
    .required("Output type is a required field."),
  greetingMessage: yup
    .string()
    .notRequired(),
})

const AgentSettings = () => {
  const { agentId } = useParams();
  const [videoFileUploaded, setVideoFileUploaded] = useState(false);
  const [modelFileUploaded, setModelFileUploaded] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedModel, setSelectedModel] = useState("");
  const navigate = useNavigate();
  const [uploadedImg, setUploadedImg] = useState("");
  const [uploadedVideo, setUploadedVideo] = useState("");
  const [modelFileInput, setModelFileInput] = useState(null);
  const [demoVideoFileInput, setDemoVideoFileInput] = useState(null);
  const [agentData, setAgentData] = useState({});
  const { showToast } = useToast();

  const {
    register: createAgent,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
    reset
  } = useForm({
    resolver: yupResolver(schema), mode: "onSubmit",
    defaultValues: {
      agentName: "",
      description: "",
      profileImg: "",
      demoVideo: "",
      instruction: "",
      userInputs: [],
      modelType: "",
      modelName: "",
      modelFile: "",
      customModelUrl: "",
      greetingMessage: "",
      outputType: ""
    }
  });

  useEffect(() => {
    fetchAgentDetails();
    // eslint-disable-next-line
  }, []);

  const fetchAgentDetails = () => {
    const agentDetails = {
      agentId: agentId
    }
    fetch(`${API}/agent/get-agent-details`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(agentDetails),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          setAgentData(res.result.agentData);
          setFormValues(res.result.agentData);
        } else {
          showToast("error", `${res.error.msg}`);
        }
      })
      .catch((error) => {
        showToast("Failed to fetch agent details.");
        console.error("Error fetching agent details:", error);
      });
  }

  const setFormValues = (data) => {
    setValue("agentName", data.agent_name);
    setValue("description", data.agent_description);
    setValue("demoVideo", data.demo_video, { shouldValidate: true });
    setValue("outputType", data.output_type);
    setValue("greetingMessage", data.greeting_message);
    setValue("instruction", data.instruction);
    setValue("userInputs", data.user_inputs);
    if (data.profile_image) {
      setValue("profileImg", data.profile_image, { shouldValidate: true });
      setUploadedImg(data.profile_image);
    }
    if (data.demo_video) {
      setValue("demoVideo", data.demo_video, { shouldValidate: true });
      setUploadedVideo(data.demo_video);
    }
    if (data.model_name) {
      setValue("modelType", "Select Model", { shouldValidate: true });
      setSelectedModel({ label: data.model_name });
      setValue("modelName", data.model_name, { shouldValidate: true });
    }
    if (data.model_file) {
      setValue("modelType", "Upload Model", { shouldValidate: true });
      setValue("modelFile", data.model_file, { shouldValidate: true });
    }
    if (data.custom_model_url) {
      setValue("modelType", "Custom Model URL", { shouldValidate: true });
      setValue("customModelUrl", data.custom_model_url, { shouldValidate: true });
    }
  }

  const onSubmit = (data) => {
    if (data.modelType === "Select Model") {
      if (!data.modelName) {
        setError("modelName", {
          type: "manual",
          message: "Select a model name from the dropdown"
        });
      } else {
        handleUpdateAgent(data);
      }
    };

    if (data.modelType === "Upload Model") {
      if (!data.modelFile && !modelFileInput) {
        setError("modelFile", {
          type: "manual",
          message: "Please upload a model file"
        });
      } else {
        handleUpdateAgent(data);
      }
    };

    if (data.modelType === "Custom Model URL") {
      if (!data.customModelUrl) {
        setError("customModelUrl", {
          type: "manual",
          message: "Please enter a valid model URL"
        });
      } else {
        handleUpdateAgent(data);
      }
    };
  }

  const handleUpdateAgent = async (data) => {
    setLoading(true);

    if (imageFile) {
      const imageUrl = await handleUploadFileToBlob(imageFile, data.agentName);
      setValue("profileImg", imageUrl, { shouldValidate: true });
      data.profileImg = imageUrl;
    }
    if (modelFileInput) {
      const modelFileUrl = await handleUploadFileToBlob(modelFileInput, "");
      setValue("modelFile", modelFileUrl, { shouldValidate: true });
      data.modelFile = modelFileUrl;
    }

    const modelType = handleSetModelType(data.modelType);

    const agentDetails = {
      agentId: agentId,
      name: data.agentName,
      description: data.description,
      profileImg: data.profileImg,
      instructions: data.instruction,
      userInputs: data.userInputs,
      modelType: modelType,
      modelFile: data.modelFile,
      customModelUrl: data.customModelUrl,
      modelName: data.modelName,
      outputType: data.outputType,
      greetingMessage: data.greetingMessage
    };

    fetch(`${API}/agent/update-ai-agent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(agentDetails),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        if (response.success) {
          showToast("success", "Agent updated successfully");
          navigate("/ig-ai/onboard-ai-agent");
        } else {
          showToast("error", `${response.error.msg}`);
        }
      })
      .catch((err) => {
        setLoading(false);
        showToast("error", "Error occurred please try again");
      });
  }

  const handleSetModelType = (modelType) => {
    switch (modelType) {
      case "Select Model":
        return "model_name";
      case "Upload Model":
        return "model_file";
      case "Custom Model URL":
        return "custom_url";
      default:
        return null;
    }
  };

  const handleUploadFileToBlob = async (file, agentName) => {
    const fileType = file.type;
    const fileExtension = file.name.split('.').pop();

    const sasUrl = localStorage.getItem('sas_url');
    const containerName = localStorage.getItem('userId');
    const blobServiceClient = new BlobServiceClient(sasUrl);
    const containerClient = blobServiceClient.getContainerClient(containerName);

    const uploadOptions = {
      blobHTTPHeaders: {
        blobContentType: fileType
      }
    };

    try {
      const fileName = agentName
        ? `${agentName.toLowerCase().replace(/\s+/g, '')}.${fileExtension}`
        : `${uuid()}.${fileExtension}`;

      const blockBlobClient = containerClient.getBlockBlobClient(fileName);
      await blockBlobClient.uploadData(file, uploadOptions);

      const fullUrl = blockBlobClient.url;
      const baseUrl = fullUrl.split('?')[0];

      return baseUrl;
    } catch (error) {
      showToast("error", "Error uploading file");
      console.error('Error uploading file:', error);
    }
  };

  const onCancel = () => {
    setOpenDialog(true);
  };

  const handleConfirmCancel = () => {
    setOpenDialog(false);
    reset();
    setUploadedImg("");
    setUploadedVideo("");
    setFormValues(agentData);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onBack = () => {
    navigate("/ig-ai/onboard-ai-agent");
  }

  const leftProps = {
    createAgent,
    setValue,
    errors,
    videoFileUploaded,
    setVideoFileUploaded,
    setImageFile,
    watch,
    uploadedImg,
    setUploadedImg,
    uploadedVideo,
    setUploadedVideo,
    setDemoVideoFileInput,
    agentId,
    handleUploadFileToBlob,
    demoVideoFileInput,
    fetchAgentDetails
  };

  const middleProps = {
    createAgent,
    setValue,
    errors,
    watch,
    setError,
    clearErrors,
    modelFileUploaded,
    setModelFileUploaded,
    selectedModel,
    setSelectedModel,
    setModelFileInput,
  };

  return (
    <MainContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Header
          title="Settings"
          showBackBtn="true"
          showCreateAiBtn="false"
          userRole={getUserRole()}
          formSubmit={true}
          btnName="Update"
          onCancel={onCancel}
          loading={loading}
          onBack={onBack}
        />
        <Divider sx={{ margin: "0 10px" }} />
        <SubContainer>
          <Box sx={{ flex: 1 }}>
            <CreateAgentLeft {...leftProps} />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CreateAgentMiddle {...middleProps} />
          </Box>
          <Box sx={{ flex: 2 }}>
            <CreateAgentRight />
          </Box>
        </SubContainer>
      </form>
      <ConfirmModal
        open={openDialog}
        handleCancel={handleCloseDialog}
        handleConfirm={handleConfirmCancel}
        title="Are you sure you want to cancel ?"
        message="This will reset the form to its initial values."
      />
    </MainContainer>
  );
};

export default AgentSettings;
