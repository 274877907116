import React from "react";
import { expandIcon } from "../../Constants/Constant";

import {
  ErrorMsg,
  OutterContainer,
  StyledFlexBox,
  StyledTextArea,
  TextContainer,
  TitleContainer,
} from "../../Common/commonStyles";
import { IconButton } from "@mui/material";

const GreetingContainer = (props) => {
  const { createAgent, errors } = props;

  return (
    <>
      <OutterContainer>
        <StyledFlexBox>
          <TitleContainer>Greeting Message</TitleContainer>
          <IconButton sx={{ padding: "0px" }}>
            <img src={expandIcon} alt="expandIcon" width="15px" />
          </IconButton>
        </StyledFlexBox>
        <TextContainer>
          Create a welcome message for users of the agent, outlining the types of
          tasks it can handle and providing instructions.
        </TextContainer>
        <StyledTextArea
          rows={6}
          placeholder="Enter greeting message"
          {...createAgent("greetingMessage")}
        />
      </OutterContainer>
      {errors?.greetingMessage &&
        <ErrorMsg variant="p">{errors.greetingMessage.message}</ErrorMsg>
      }
    </>
  );
};

export default GreetingContainer;
