import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Container,
  IconButton,
} from "@mui/material";

import LandingPageTab from "./LandingPageTab";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import GoogleIcon from "@mui/icons-material/Google";

import {
  landingPageBackground,
  mapView,
  ignithoLogo,
} from "../../Constants/Constant";
import { LandingPageImg } from "./LandingPageStyle";

const LandingPage = () => {
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "#f9f9f9",
        fontFamily: "Roboto",
        margin: 0,
        padding: 0,
      }}
    >
      <Box
        sx={{
          marginTop: { xs: 7, md: 7 },
          position: "relative",
          width: "100%",
          textAlign: "center",
        }}
      >
        <LandingPageImg src={landingPageBackground} alt="img" />
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "18px",
                sm: "30px",
                md: "40px",
                lg: "50px",
                xl: "70px",
              },
              fontWeight: 700,
              background: "linear-gradient(to right, #8248d0, #e86444)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            We Power Intelligent <br />
            Real Time Businesses
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "12px", sm: "15px", md: "18px" },
              color: "#868a97",
              marginTop: 2,
              fontWeight: 400,
            }}
          >
            Effortlessly train and deploy your AI team. <br />
            Scale your business without increasing your workforce.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "#f9f9f9",
          display: "flex",
          flexDirection: "column",
          borderRadius: 1,
          mt: 4,
        }}
      >
        <Box>
          <LandingPageTab />
        </Box>
      </Box>

      <Box sx={{ backgroundColor: "#402640", py: 4 }}>
        <Container>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              <Typography
                sx={{ color: "#fff", fontSize: { xs: "30px", md: "50px" } }}
              >
                Fancy a chat?
              </Typography>
              <Typography
                sx={{
                  color: "#868A97",
                  fontSize: { xs: "16px", md: "20px" },
                  mb: 2,
                }}
              >
                Tell us about your requirement and we'll be in touch
              </Typography>
              <Button
                sx={{
                  background: "#7eb965",
                  borderRadius: "30px",
                  textTransform: "capitalize",
                  color: "#fff",
                  fontWeight: "bold",
                }}
                endIcon={<ArrowRightIcon />}
              >
                Quick Inquiry
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ textAlign: "center" }}>
                <img
                  src={mapView}
                  alt="bg"
                  style={{ width: "100%", height: "auto" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={{ py: 4 }}>
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <img src={ignithoLogo} alt="footer" style={{ width: 120 }} />
            <Box sx={{ display: "flex", gap: 2, mt: { xs: 2, md: 0 } }}>
              <IconButton sx={{ position: "relative" }}>
                <LinkedInIcon />
              </IconButton>
              <IconButton sx={{ position: "relative" }}>
                <FacebookIcon />
              </IconButton>
              <IconButton sx={{ position: "relative" }}>
                <TwitterIcon />
              </IconButton>
              <IconButton sx={{ position: "relative" }}>
                <YouTubeIcon />
              </IconButton>
              <IconButton sx={{ position: "relative" }}>
                <GoogleIcon />
              </IconButton>
            </Box>
          </Box>
          <hr />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 2,
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
            }}
          >
            <Typography variant="body2" color="text.secondary">
              © 2024 Ignitho, All Rights Reserved
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 3,
                mt: { xs: 2, md: 0 },
                textAlign: { xs: "center", md: "right" },
              }}
            >
              <Typography variant="body2" color="text.secondary">
                Sitemap
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Legal terms
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Privacy Policy
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default LandingPage;
