import React, { useState } from "react";

import { Stack } from "@mui/material";

import {
  ErrorMsg,
  OutterContainer,
  TitleContainer
} from "../../Common/commonStyles";
import {
  ButtonContainer,
  FileUploadContainer,
  OutlinedButton,
  VideoContainer,
  VideoPlayer
} from "./CreateAIAgentStyles";
import FileInput from "../../Reusable/FileInput/FileInput";
import AddAiAgent from "../../Reusable/Agents/AddAiAgent";
import Instruction from "../../Reusable/Agents/Instruction";
import UserInput from "../../Reusable/Agents/UserInput";
import FileUploadedCard from "../../Reusable/FileUploadedCard/FileUploadedCard";
import { addIcon, videoImg } from "../../Constants/Constant";
import { API } from "../../global";
import ConfirmModal from "../../Reusable/Modals/ConfirmModal/ConfirmModal";
import { useToast } from "../../utils/ToastContext";

const CreateAgentLeft = (props) => {
  const {
    createAgent,
    setValue,
    errors,
    videoFileUploaded,
    setVideoFileUploaded,
    setImageFile,
    watch,
    uploadedImg,
    setUploadedImg,
    uploadedVideo,
    setUploadedVideo,
    setDemoVideoFileInput,
    agentCreate,
    agentId,
    handleUploadFileToBlob,
    demoVideoFileInput,
    fetchAgentDetails
  } = props;
  const { showToast } = useToast();

  const [fileDetails, setFileDetails] = useState({});
  const [loadingAction, setLoadingAction] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [confirmDialogContent, setConfirmDialogContent] = useState({
    title: "",
    message: ""
  });

  const allowedFileTypes = ["video/mp4"];

  const handleFileChange = async (file) => {
    if (file) {
      if (!allowedFileTypes.includes(file.type)) {
        showToast("error", "Invalid file type. Please upload an MP4 video file with a maximum size of 100 MB.");
      } else if (file.size > 104857600) {
        showToast("error", "File size exceeds 100 MB. Please upload a smaller file. Acceptable file type: MP4.");
      } else {
        setVideoFileUploaded(true);
        setDemoVideoFileInput(file);
        const fileType = file.type;
        const fileSize = (file.size / 1024).toFixed(2);
        const fileName = file.name;

        setFileDetails({
          fileType: fileType,
          fileName: fileName,
          fileSize: fileSize
        });
        const videoUrl = URL.createObjectURL(file);
        setUploadedVideo(videoUrl);
      }
    } else {
      console.error("No file selected");
      showToast("error", "No file selected");
    }
  };

  const resetFileUpload = () => {
    setVideoFileUploaded(false);
    setFileDetails({});
  }

  const getVideoTypeFromUrl = (url) => {
    const extension = url.split('.').pop().split('?')[0];
    return extension === 'mp4' ? 'video/mp4' : 'video/mp4';
  };

  const handleDeleteVideo = () => {
    if (videoFileUploaded) {
      setVideoFileUploaded(false);
      setUploadedVideo(null);
    } else {
      setLoadingAction("delete");
      const demoVideoDetails = {
        agentId: agentId,
        demoVideo: "",
        type: "delete"
      }
      fetch(`${API}/agent/update-demo-video`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(demoVideoDetails),
      })
        .then((res) => res.json())
        .then((response) => {
          setLoadingAction("");
          if (response.success) {
            showToast("success", "Video deleted successfully");
            setUploadedVideo(null);
          } else {
            showToast("error", `${response.error.msg}`);
          }
        })
        .catch((err) => {
          setLoadingAction(false);
          showToast("error", "Error occurred please try again");
        });
    }
  }

  const handleUpdateVideo = async () => {
    setLoadingAction("update");
    const demoVideoUrl = await handleUploadFileToBlob(demoVideoFileInput, "");
    const demoVideoDetails = {
      agentId: agentId,
      demoVideo: demoVideoUrl,
      type: "update"
    }
    fetch(`${API}/agent/update-demo-video`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(demoVideoDetails),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoadingAction("");
        if (response.success) {
          setVideoFileUploaded(false);
          showToast("success", "Video saved successfully");
          fetchAgentDetails();
        } else {
          showToast("error", `${response.error.msg}`);
        }
      })
      .catch((err) => {
        setLoadingAction("");
        showToast("error", "Error occurred please try again");
      });
  }

  const handleOpenDialog = (type) => {
    const content = type === "delete"
      ? {
        title: "Are you sure you want to delete ?",
        message: "This will delete the video."
      }
      : {
        title: "Are you sure you want to update ?",
        message: "This will replace the existing video or create a new one if no video is present."
      };

    setActionType(type);
    setConfirmDialogContent(content);
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setActionType(null);
  }

  const handleConfirmAction = () => {
    if (actionType === "delete") {
      handleDeleteVideo();
    } else if (actionType === "update") {
      handleUpdateVideo();
    }
    handleCloseDialog();
  }

  return (
    <Stack spacing={1}>
      <AddAiAgent Img={addIcon} createAgent={createAgent}
        showToast={showToast} setImageFile={setImageFile}
        uploadedImg={uploadedImg} setUploadedImg={setUploadedImg}
      />
      {errors?.agentName &&
        <ErrorMsg variant="p">{errors.agentName.message}</ErrorMsg>
      }

      <OutterContainer>
        <TitleContainer>Demo Video</TitleContainer>
        {videoFileUploaded && agentCreate ? (
          <FileUploadContainer>
            <FileUploadedCard
              fileUploaded={videoFileUploaded}
              resetFileUpload={resetFileUpload}
              fileDetails={fileDetails}
              imgSrc={videoImg}
            />
          </FileUploadContainer>
        ) : uploadedVideo && !agentCreate ? (
          <>
            <VideoContainer>
              <VideoPlayer controls>
                <source
                  src={uploadedVideo}
                  type={videoFileUploaded ? fileDetails.fileType : getVideoTypeFromUrl(uploadedVideo)}
                />
                Your browser does not support the video tag.
              </VideoPlayer>
            </VideoContainer>
            <ButtonContainer>
              <OutlinedButton
                btncolor="#EB0000"
                loading={loadingAction === "delete"}
                onClick={() => handleOpenDialog("delete")}
              >
                Delete
              </OutlinedButton>
              {videoFileUploaded &&
                <OutlinedButton
                  btncolor="#6249EF"
                  loading={loadingAction === "update"}
                  onClick={() => handleUpdateVideo()}
                >
                  Save
                </OutlinedButton>
              }
            </ButtonContainer>
          </>
        ) : (
          <FileInput handleFileChange={handleFileChange} maxFileSize="100MB" />
        )}
      </OutterContainer>

      {errors?.demoVideo &&
        <ErrorMsg variant="p">{errors.demoVideo.message}</ErrorMsg>
      }

      <Instruction createAgent={createAgent} />
      {errors?.instructions && (
        <ErrorMsg variant="p">{errors.instructions.message}</ErrorMsg>
      )}

      <UserInput createAgent={createAgent} setValue={setValue}
        watch={watch}
      />
      {errors?.userInputs &&
        <ErrorMsg variant="p">{errors.userInputs.message}</ErrorMsg>
      }

      <ConfirmModal
        open={openDialog}
        handleCancel={handleCloseDialog}
        handleConfirm={handleConfirmAction}
        title={confirmDialogContent.title}
        message={confirmDialogContent.message}
      />
    </Stack>
  );
};

export default CreateAgentLeft;
