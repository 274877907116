import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import PropTypes from "prop-types";

import {
  AppBar,
  Box,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Divider,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AddIcon from "@mui/icons-material/Add";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

import {
  StyledToolbar,
  StyledBox,
  StyledNavBox,
  StyledButton,
  ButtonIcon,
  ButtonIconContained,
  TextButton,
  DrawerHeader,
} from "./HeaderStyle";
import { BlueButton, GreyButton } from "../../Common/commonStyles";

import { backIcon, cloneIcon } from "../../Constants/Constant";

const Header = (props) => {
  Header.propTypes = {
    title: PropTypes.string,
    showBackBtn: PropTypes.string,
    showCenterBtn: PropTypes.string,
    showSettingsBtn: PropTypes.string,
    showCloneBtn: PropTypes.string,
    showCreateAiBtn: PropTypes.string,
    buttonTitle: PropTypes.string,
    userRole: PropTypes.string,
  };

  const {
    title,
    showBackBtn,
    showCenterBtn,
    showSettingsBtn,
    showCloneBtn,
    showCreateAiBtn,
    onBack,
    buttonTitle,
    onButtonAction,
    userRole,
    history,
    formSubmit,
    onCancel,
    loading,
    btnName,
    onSettings
  } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeButton, setActiveButton] = useState("new");
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();

  // for common use of the style
  const flexStart = "flex-start";

  useEffect(() => {
    if (history) {
      setActiveButton("history");
    }
    // eslint-disable-next-line
  }, []);

  const handleButtonClick = (button) => {
    setActiveButton(button);
    const agentName = localStorage.getItem("agentName");
    const agentId = localStorage.getItem("agentId");
    if (button === "history") {
      navigate(`/ig-ai/all-tasks/${agentId}`);
    } else {
      navigate(`/ig-ai/employ/${agentName}/${agentId}`);
    }
  };

  const onBackClick = () => {
    onBack();
  };

  const onButtonClick = () => {
    onButtonAction();
  };

  const onCancelClick = () => {
    onCancel();
  };

  const onSettingsClick = () => {
    onSettings();
  }

  const menuItem = [
    { text: "Dashboard", path: "/" },
    { text: "Quality Check Agent", path: "/ig-ai/onboard-ai-agent" },
    { text: "Workflow", path: "/" },

    { text: "Themes", path: "/" },
    { text: "Help", path: "/" },
    { text: "Settings", path: "/" },
    { text: "Profile", path: "/ig-ai/account-settings" },
  ];

  return (
    <AppBar position='static' elevation={0}>
      <StyledToolbar
        elevation={0}
        sx={{
          justifyContent: { xs: flexStart, sm: flexStart, md: "center" },
          alignItems: { xs: flexStart, sm: flexStart, md: "center" },
        }}
      >
        <Box
          sx={{
            display: { xs: "flex", sm: "flex", md: "none" },

            height: "0",
            padding: "5px",
          }}
        >
          <IconButton
            size='large'
            edge='start'
            aria-label='logo'
            onClick={() => setIsDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>

          <Drawer anchor='left' open={isDrawerOpen}>
            <DrawerHeader>
              <IconButton onClick={() => setIsDrawerOpen(false)}>
                <ChevronLeft />
              </IconButton>
            </DrawerHeader>
            <Divider />
            <Box p={2} width='200px' textAlign='center'>
              <List>
                {menuItem.map((item) => (
                  <ListItem key={item.text} disablePadding>
                    <ListItemButton component={Link} to={item.path}>
                      <ListItemText primary={item.text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Divider />
          </Drawer>
        </Box>
        <StyledNavBox
          sx={{
            position: "relative",
            flexDirection: { xs: "column", sm: "column", md: "row" },
            gap: { xs: "10px", sm: "10px" },
          }}
        >
          <StyledBox>
            {showBackBtn && (
              <IconButton onClick={onBackClick} p={0}>
                <img src={backIcon} alt='back' />
              </IconButton>
            )}

            <Typography variant='h6'>{title}</Typography>
          </StyledBox>

          {showCenterBtn === true && (
            <StyledBox
              sx={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                top: { xs: "30%", md: "15%" },
              }}
            >
              <StyledButton
                active={activeButton === "new"}
                onClick={() => handleButtonClick("new")}
              >
                New
              </StyledButton>
              <StyledButton
                active={activeButton === "history"}
                onClick={() => handleButtonClick("history")}
              >
                History
              </StyledButton>
            </StyledBox>
          )}

          <StyledBox sx={{ marginTop: { xs: "30px", md: "0px" } }}>
            {showSettingsBtn === true && (
              <ButtonIcon
                onClick={onSettingsClick}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <IconButton sx={{ padding: "0px", margin: "0 7px" }}>
                  <SettingsIcon sx={{ color: hover ? "#FFFFFF" : "#6249EF" }} />
                </IconButton>
                <TextButton
                  sx={{
                    textTransform: "capitalize",
                    color: hover ? "#ffff" : "#6249EF",
                  }}
                >
                  Settings
                </TextButton>
              </ButtonIcon>
            )}

            <>
              {showCloneBtn === true && (
                <>
                  {buttonTitle === "Create AI Agent" ? (
                    <ButtonIconContained onClick={onButtonClick}>
                      {userRole && userRole === "ig_ai_admin" && (
                        <>
                          <IconButton p={0}>
                            <AutoAwesomeIcon
                              sx={{ color: "#fff", fontSize: "16px" }}
                            />
                          </IconButton>

                          <TextButton
                            variant='p'
                            sx={{ color: "#fff", marginLeft: "10px" }}
                          >
                            {buttonTitle}
                          </TextButton>
                        </>
                      )}
                    </ButtonIconContained>
                  ) : (
                    <ButtonIconContained onClick={onButtonClick}>
                      <IconButton p={0}>
                        <img src={cloneIcon} alt='clone' />
                      </IconButton>

                      <TextButton
                        variant='p'
                        sx={{ color: "#fff", marginLeft: "10px" }}
                      >
                        {buttonTitle}
                      </TextButton>
                    </ButtonIconContained>
                  )}
                </>
              )}
            </>
          </StyledBox>

          {showCreateAiBtn === true && (
            <StyledBox>
              <ButtonIcon
                sx={{
                  border: "1px solid #00BB25",
                  "&:hover": {
                    backgroundColor: "#00BB25",
                    color: "white",
                  },
                }}
                variant='outlined'
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <IconButton p={0}>
                  <PlayArrowIcon
                    sx={{ color: hover ? "#FFFFFF" : "#00BB25" }}
                  />
                </IconButton>
                <TextButton
                  sx={{
                    textTransform: "capitalize",
                    color: hover ? "#ffff" : "#00BB25",
                    fontWeight: 400,
                  }}
                >
                  {" "}
                  Test Agent
                </TextButton>
              </ButtonIcon>

              <ButtonIconContained>
                <IconButton p={0}>
                  <AddIcon sx={{ color: "#FFFFFF" }} />
                </IconButton>
                <TextButton
                  sx={{ textTransform: "capitalize", fontWeight: 400 }}
                >
                  Create Agent
                </TextButton>
              </ButtonIconContained>
            </StyledBox>
          )}

          {formSubmit && (
            <StyledBox>
              <GreyButton onClick={onCancelClick}>
                Cancel
              </GreyButton>
              <BlueButton type="submit" loading={loading} disabled={loading}
              >
                {loading ? "Loading..." : btnName}
              </BlueButton>
            </StyledBox>
          )}
        </StyledNavBox>
      </StyledToolbar>
    </AppBar>
  );
};

export default Header;
