import React from "react";
import ReactDOM from "react-dom/client";

import { ThemeProvider } from "@emotion/react";
import { theme } from "./Reusable/Theme";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto";

import "react-toastify/dist/ReactToastify.css";

import "./index.css";
import Layout from "./components/Layout/Layout";
import DataQualityCheck from "./components/DataQualityCheckAgentChat/DataQualityCheck";
import ErrorPage from "./components/ErrorPage";
import OnboardAiAgents from "./components/OnbaordAiAgents/OnboardAiAgents";
import DataProcessing from "./components/DataQualityCheckAgentChat/DataProcessing";
import LandingPage from "./components/LandingPage/LandingPage";
import Login from "./components/Authentication/Login/Login";
import Register from "./components/Authentication/Register/Register";
import CreateAIAgent from "./components/CreateAIAgent/CreateAIAgent";
import AiAgentDetailedView from "./Reusable/AiAgentDetailedView/AiAgentDetailedView";
import SuccessPage from "./components/Authentication/Register/SuccessPage";
import AgentSettings from "./components/AgentSettings/AgentSettings";
import EmailVerification from "./components/Authentication/Register/EmailVerification";
import ProtectedRoute from "./components/Authentication/AuthContext/ProtectedRoute";
import { AuthProvider } from "./components/Authentication/AuthContext/AuthContext";
import ProfilePage from "./components/ProfilePage/ProfilePage";
import { Provider } from "./utils/Provider";
import AllTasks from "./components/AllTasks/AllTasks";
import UserAgentSettings from "./components/DataQualityCheckAgentChat/UserAgentSettings/UserAgentSettings";
import ForgotPassword from "./components/Authentication/ForgotPassword/ForgotPassword";

import PasswordReset from "./components/Authentication/PasswordReset/PasswordReset";
import { ToastProvider } from "./utils/ToastContext";
import CustomSnackbar from "./Reusable/CustomSnackbar/CustomSnackbar";
import NotFoundPage from "./components/NotFoundPage/NotFoundPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/ig-ai" element={<Layout />} errorElement={<ErrorPage />}>
        <Route
          path="employ/:agentName/:agentId"
          element={<ProtectedRoute element={<DataQualityCheck />} />}
        />
        <Route
          path="data-processing"
          element={<ProtectedRoute element={<DataProcessing />} />}
        />
        <Route
          path="onboard-ai-agent"
          element={<ProtectedRoute element={<OnboardAiAgents />} />}
        />
        <Route
          path="create-ai-agent"
          element={<ProtectedRoute element={<CreateAIAgent />} role="ig_ai_admin" />}
        />
        <Route
          path="agent-settings/:agentId"
          element={<ProtectedRoute element={<AgentSettings />} role="ig_ai_admin" />}
        />
        <Route
          path="account-settings"
          element={<ProtectedRoute element={<ProfilePage />} />}
        />
        <Route
          path="all-tasks/:agentId"
          element={<ProtectedRoute element={<AllTasks />} />}
        />
        <Route
          path="user-agent-settings"
          element={<ProtectedRoute element={<UserAgentSettings />} />}
        />
      </Route>
      <Route path="/success" element={<SuccessPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/sign-up" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:uuid/:token" element={<PasswordReset />} />
      <Route
        path="/verify-account/:uuid/:token"
        element={<EmailVerification />}
      />
      <Route path="/agent-details/:agentId" element={<AiAgentDetailedView />} />
      <Route path="/" element={<LandingPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <ToastProvider>
          <CssBaseline />
          <RouterProvider router={router} />
          <CustomSnackbar />
        </ToastProvider>
      </ThemeProvider>
    </AuthProvider>
  </Provider>
);
