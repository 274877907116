import { LoadingButton } from "@mui/lab";
import { styled, Box, Typography, Link } from "@mui/material";

const FormContainer = styled("form")(({ theme }) => ({
  width: "100%",
  padding: "20px",
  textAlign: "center",
  [theme.breakpoints.up("sm")]: {
    width: "400px",
  },
}));

const LogoImgContainer = styled(Box)(({ theme }) => ({
  textAlign: "center",
}));

const LogoImage = styled("img")(({ theme }) => ({
  width: "140px",
  height: "auto",
  marginBottom: "40px",
  objectFit: "cover",
  [theme.breakpoints.up("xl")]: {
    width: "200px",
  },
}));

const HeaderTypography = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "600",
  color: "#0D162F",
  marginBottom: "32px",
  textAlign: "center",
});

const FormFieldsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "10px",
}));

const CustomInput = styled("input")(({ theme }) => ({
  width: "100%",
  padding: "14px 12px",
  borderRadius: "4px",
  backgroundColor: "#E9EFF6",
  border: "none",
  fontSize: "1rem",
  color: "#0D162F",
  "&::placeholder": {
    color: "#868A97",
    fontSize: "0.875rem",
  },
  "&:focus": {
    outline: "none",
    backgroundColor: "#E9EFF6",
  },
  [theme.breakpoints.up("xl")]: {
    "&::placeholder": {
      fontSize: "1rem",
    },
  },
}));

const PasswordInputWrapper = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
}));

const ForgotPasswordLink = styled(Link)(({ theme }) => ({
  textAlign: "right",
  color: "#868A97",
  fontSize: "0.875rem",
  textDecoration: "none",
  cursor: "pointer",
  "&:hover": {
    color: "#6249EF",
  },
  [theme.breakpoints.up("xl")]: {
    fontSize: "1rem",
  },
}));

const LoginButton = styled(LoadingButton)(({ theme }) => ({
  width: "100%",
  textTransform: "capitalize",
  backgroundColor: theme.palette.bluebutton.main,
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: theme.palette.bluebutton.hover,
  },
  fontSize: "0.875rem",
  [theme.breakpoints.up("xl")]: {
    fontSize: "1rem",
  },
  "&:disabled": {
    backgroundColor: theme.palette.bluebutton.disable,
    color: "grey",
  },
}));

const AccountText = styled(Typography)(({ theme }) => ({
  marginTop: "1.25rem",
  fontSize: "0.875rem",
  fontWeight: "normal",
  color: "#868A97",
  textAlign: "center",
  [theme.breakpoints.up("xl")]: {
    fontSize: "1rem",
  },
}));

const SignupText = styled("span")(({ theme }) => ({
  cursor: "pointer",
  color: theme.palette.bluebutton.main,
}));

export {
  FormContainer,
  LogoImage,
  ForgotPasswordLink,
  LoginButton,
  SignupText,
  HeaderTypography,
  CustomInput,
  FormFieldsWrapper,
  AccountText,
  PasswordInputWrapper,
  LogoImgContainer,
};
