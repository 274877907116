import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import PropTypes from "prop-types";
import { Box, IconButton, Step, Typography, styled } from "@mui/material";
import { Check } from "@mui/icons-material";
import {
  MainBox,
  StyledBox,
  StyledInnerBox,
  HeadingBox,
  StyledTab,
  StyledStepper,
  CustomConnector,
  CustomStepLabel,
} from "./DataQualityCheckStyles";
import Header from "../../Reusable/CommonHeader/Header";
import ChatBox from "../../Reusable/ChatBox/ChatBox";
import {
  emptyBox,
  greenDownArrow,
  greyDownArrow,
  tickGreenIcon,
  tickGreyIcon,
} from "../../Constants/Constant";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CommonProgressBar from "../../Reusable/CommonProgressBar/CommonProgressBar";
import { getUserRole } from "../../utils/localStorageUtils";

const steps = [
  {
    label: "Campaign Setup",
    description: "description1",
    levels: 4,
  },
  {
    label: "Audience Segmentation",
    description: "description2",
    levels: 3,
  },
  {
    label: "Email Content",
    description: "description3",
    levels: 2,
  },
  {
    label: "Plan Selection",
    description: "description4",
    levels: 1,
  },
];

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const DataQualityCheck = React.memo(() => {
  const navigate = useNavigate();
  const [value, setValue] = useState("1");
  const [activeStep] = useState(0);
  const [completedSteps] = useState([]);
  const [expandedSteps] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { agentName, agentId } = useParams();

  useEffect(() => {
    localStorage.setItem("agentName", agentName);
    localStorage.setItem("agentId", agentId);

    //eslint-disable-next-line
  }, []);

  const onBack = () => {
    navigate("/ig-ai/onboard-ai-agent");
  };

  const onSettings = () => {
    navigate("/ig-ai/user-agent-settings");
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Header
        title={agentName}
        showBackBtn={true}
        showCenterBtn={true}
        showSettingsBtn={true}
        showCloneBtn={true}
        buttonTitle="Clone AI Agent"
        onBack={onBack}
        userRole={getUserRole()}
        onSettings={onSettings}
      />
      <MainBox
        sx={{
          flexDirection: { xs: "column", sm: "column", md: "row" },
          gap: { xs: "10px", sm: "10px", md: "0px" },
        }}
      >
        <StyledBox
          sx={{
            width: { xs: "100%", sm: "100%", md: "50%" },
            height: { xs: "100%", sm: "100%", md: "100%" },
          }}
          bgcolor="#fef9f9"
        >
          <ChatBox height={"80%"} test={"testWorkQuakity"} />
        </StyledBox>
        <StyledBox
          sx={{
            width: { xs: "100%", sm: "100%", md: "50%" },
            height: { xs: "100%", sm: "100%", md: "100%" },
            marginLeft: { sm: "0px", md: "15px" },
          }}
        >
          <HeadingBox>
            <Typography
              variant="div"
              sx={{ fontSize: "18px", fontWeight: "medium" }}
            >
              Artifacts
            </Typography>
          </HeadingBox>
          {agentName === "Piqual AI SDR Agent" ? (
            <Box sx={{ margin: "10px" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 2, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <StyledTab label="Planning" value="1" />
                    <StyledTab label="Process Flow" value="2" />
                    <StyledTab label="Preview" value="3" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Box
                    sx={{
                      height: "80%", // Adjust the height as per the parent container
                      maxHeight: "45vh", // Optional: limit the maximum height to 50% of the viewport height
                      overflowY: "auto",
                      paddingInline: "10px",
                    }}
                  >
                    <StyledStepper
                      activeStep={activeStep}
                      orientation="vertical"
                      connector={<CustomConnector />}
                    >
                      {steps.map((step, index) => (
                        <Step
                          key={index}
                          completed={completedSteps.includes(index)}
                        >
                          <CustomStepLabel
                            StepIconComponent={() => (
                              <IconButton
                                p={0}
                              // sx={{
                              //   border: loading ? "none" : "2px solid #FFAA00",
                              // }}
                              >
                                <img
                                  src={
                                    completedSteps.includes(index)
                                      ? tickGreenIcon
                                      : tickGreyIcon
                                  }
                                  alt={
                                    completedSteps.includes(index)
                                      ? "tickGreenIcon"
                                      : "tickGreyIcon"
                                  }
                                  width={12}
                                />
                              </IconButton>
                            )}
                          >
                            {step.label}
                            <Box
                              sx={{
                                display: "flex",
                                width: "70%",
                                alignItem: "center",
                                justifyContent: "flex-end",
                                gap: "10px",
                              }}
                            >
                              <Box sx={{ width: "50%" }}>
                                <CommonProgressBar steps={step.levels} />
                              </Box>
                              <Box>
                                <IconButton
                                  sx={{ padding: "0px" }}
                                  disabled={index === 0}
                                >
                                  <img
                                    src={
                                      completedSteps.includes(index)
                                        ? greenDownArrow
                                        : greyDownArrow
                                    }
                                    alt={
                                      completedSteps.includes(index)
                                        ? "greenDownArrow"
                                        : "greyDownArrow"
                                    }
                                    width={12}
                                  />
                                </IconButton>{" "}
                              </Box>
                            </Box>
                          </CustomStepLabel>
                          {expandedSteps.includes(index) && (
                            <Box>
                              <Typography>{step.description}</Typography>
                            </Box>
                          )}
                        </Step>
                      ))}
                    </StyledStepper>
                  </Box>
                </TabPanel>
                <TabPanel value="2">Item Two</TabPanel>
                <TabPanel value="3">Item Three</TabPanel>
              </TabContext>
            </Box>
          ) : (
            <StyledInnerBox>
              <IconButton>
                <img src={emptyBox} alt="empty-icon" />
              </IconButton>
              <Typography variant="div" p={2} sx={{ textAlign: "center" }}>
                The artifacts are empty, initiate a chat to employ the{" "}
                {agentName}
              </Typography>
            </StyledInnerBox>
          )}{" "}
        </StyledBox>
      </MainBox>
    </Box>
  );
});

export default DataQualityCheck;
